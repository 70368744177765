* {
  box-sizing: border-box;
  margin: 0;
  padding: 0%;
  border: 0;
}

/* fonts */
/* font-family: 'Archivo', sans-serif;
font-family: 'Mulish', sans-serif;
font-family: 'Unna', serif; */

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0px;
  overflow: hidden;
}

a:hover {
  text-decoration: none;
}

::placeholder {
  color: #C5C5C5;
}

@media (max-width:768px) {
  section {
    padding: 20px 0;
    margin: 0%;
    margin-left: auto;
    margin-right: auto;
  }
}

p {
  color: #515151;
  size: 16px;
}

h2 {
  font-size: 55px;
  color: #6d1d8e;
}

h6 {
  font-size: 20px;
}

.card {
  border: 0;
}

/* buttons */

button.btn-light {
  color: #6d1d8e;
  padding: 9px 27px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  float: left;
}

button.small-white-button {
  margin-top: 25px;
  color: #6d1d8e;
  background-color: #fff;
  padding: 12px 35px;
  border: 2px solid #6d1d8e;
  border-radius: 30px;
  font: 800 18px "Mulish";
}

.small-white-button.two {
  display: none;
}

@media (max-width:768px) {
  button.small-white-button {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 12px 54px;
    font-size: 12px;
  }

}

button.view-all-button-white {
  margin-top: 15px;
  color: #6d1d8e;
  background: #fff;
  width: 130px;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 18px;
  float: left;
  font-weight: 500;
}



/* carousel */

.home-carousel-item-body {
  margin-top: 74px;
  background-color: #F9F9F9;
}


.home-carousel-certificates {
  display: flex;
  width: 300px;
  justify-content: flex-start;
  flex-direction: row;
  margin: 10px 0;
}

.home-carousel-one-certificate {
  display: block;
}

.home-carousel-one-certificate::after {
  content: '|';
  margin: 0 5px;
}

.view-course {
  background-color: #6d1d8e;
  color: #fff;
  font: 800 18px "Mulish";
  border-radius: 30px;
  width: 170px;
  margin: 49px 0;
  padding: 14px 26px;
}

.home-carousel-header-image {
  width: 683px;
  height: auto;
}

.carousel-indicators {
  bottom: 60px;
  left: -965px;
}

.carousel-indicators li {
  height: 8px;
  margin: 3px;
  border-radius: 12px;
  background-clip: border-box;
  border-radius: 10px;
  width: 23px;
  border: 0;
  background: rgb(255, 255, 255);
  opacity: 0.8;
}

.carousel-indicators .active {
  background-color: #6d1d8e;
}

.home-registration-content-section {
  background-color: #f9f9f9;
}

.home-registration-contents {
  margin-bottom: 50px;
}

@media (max-width:768px) {
  .home-carousel-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    margin-top: 37px;
  }

  .home-carousel-subheading {
    font: 700 13px 'Mulish';
    margin-top: 5px;
  }

  .home-carousel-about {
    font: 400 12px "Mulish";
    display: block;
    width: 300px;
  }

  .home-carousel-header-image-section {
    padding: 0%;
  }

  .home-carousel-certificates {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .view-course {
    font: 800 12px "mulish";
    padding: 11px 38px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .home-carousel-header-image {
    width: 364px;
    height: auto;
    margin: 0%;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .home-registration-content-section {
    background-color: #fff;
    margin-top: 34px;
  }

  .home-registration-contents {
    width: 328px;
    height: 29px;
    display: block;
    margin-left: auto;
    margin-right: auto;

  }
}

/****************************************/
/* home carousel second */
/****************************************/


#home-technology-stack {
  background-color: #F9F9F9;
  padding-bottom: 80px;
}

.home-technology-stack-carousel-row {
  padding-bottom: 20px;
}

.home-carousel-two-image-section-one {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.home-carousel-two-image-section-two {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.home-carousel-two-image-one,
.home-carousel-two-image-two {
  width: 268px;
  height: auto;
  z-index: 3;
}

.home-carousel-two-image-section {
  position: relative;
  margin-top: 90px;
}

.home-carousel-two-white-box {
  position: absolute;
  background-color: #FFFFFF;
  width: 368px;
  height: 368px;
  border-radius: 10px;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 35px #0000001A;
}

.home-carousel-two-body-heading {
  font: 700 55px '#6D1D8E';
  color: #6D1D8E;
  margin-top: 92px;
}

.home-carousel-two-body-detials {
  font: 400 15px 'Mulish';
  color: #515151;
  max-width: 441px;
  margin-bottom: 60px;
}

.home-carousel-two-body-stacks {
  display: flex;
  margin-bottom: 30px;
}

.home-carousel-two-body-technology-stacks {
  width: 169px;
  height: 169px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 10px;
  margin-right: 30px;
}

.home-carousel-two-body-technology-stacks-stack-image {
  width: auto;
  height: auto;
}

/* home-technology-stack-carousel-indicators-two */

#home-technology-stack-carouselExampleIndicators li {
  background-color: #D4D4D4;
}

#home-technology-stack-carouselExampleIndicators li.active {
  background-color: #6D1D8E;
}

.home-technology-stack-carousel-indicators-two.carousel-indicators {
  right: -140px;
  bottom: -40px;
  display: flex;
  justify-content: end;
}

@media(max-width:768px) {
  .home-carousel-two-image-section {
    display: none;
  }

  .home-carousel-two-body-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    color: #6D1D8E;
    margin-top: 18px;
  }

  .home-carousel-two-body-detials {
    font: 400 12px 'Mulish';
    color: #515151;
    display: block;
    width: 306px;
    margin-bottom: 30px;
  }

  .home-carousel-two-body-stacks {
    display: flex;
    justify-content: center;
  }

  .home-carousel-two-body-technology-stacks {
    width: 70px;
    height: 70px;
  }

  .home-carousel-two-body-technology-stacks-stack-image.docker-image {
    width: 51px;
    height: 11.8px;
  }

  .home-carousel-two-body-technology-stacks-stack-image.python-image {
    width: 25px;
    height: 25px;
  }

  .home-carousel-two-body-technology-stacks-stack-image.devops-image {
    width: 41px;
    height: 20px;
  }

  .home-carousel-two-body-technology-stacks-stack-image.jquery-image {
    width: 50px;
    height: 12px;
  }

  .home-carousel-two-body-technology-stacks-stack-image.bootstrap-image {
    width: 24px;
    height: 24px;
  }

  .home-carousel-two-body-technology-stacks-stack-image.kubernets-image {
    width: 28px;
    height: 28px;
  }

  .home-technology-stack-carousel-indicators-two.carousel-indicators {
    margin-left: auto;
    margin-right: auto;
  }
}

/**************************************/
/* home section our mission */
/**************************************/

.home-mission-section {
  margin-top: 45px;
  margin-bottom: 45px;
  background-color: #fff;
}

.home-our-mission-section-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media(max-width:768px) {
  .home-mission-section {
    margin-top: 0%;
  }

  .home-our-mission-section-content {
    display: flex;
    flex-direction: column-reverse;
  }

}

.mission-image-overlay-parent {
  position: relative;
  z-index: 2;
  display: flex;
}

.mission-detials {
  color: #fff;
}

p.mission-image-name {
  display: block;
  font: 700 25px "Unna";
  color: #fff;
  line-height: 0.8;
}

.margin-image-role,
.mission-education-detials,
.mission-placement {
  display: block;
  font: 400 13px 'Mulish';
  color: #fff;
  opacity: 0.8;
}

span.mission-education-heading {
  font-family: "Mulish";
  font-weight: 700;
}

.margin-image-role {
  font-size: 13px;
  font-size: 700;
  opacity: 0.8;
}

.mission-image-overlay {
  display: none;
  top: 10px;
  border: 0px;
  position: absolute;
  width: 100%;
  height: 280px;
  background: #6d1d8ecc;
  z-index: 5;
  border-radius: 10px;
}

.mission-image-overlay-parent:hover.mission-image-overlay {
  display: block;
  padding-left: 25px;
  padding-top: 100px;
  transition: 10s !important;
}

@media (max-width:765px) {
  .small-square .column-margin {
    display: none;
  }

  .small-square .column-margin:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }

  .mission-image-overlay {
    display: flex;
    height: 316px;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    text-align: center;
    background-image: linear-gradient(to top, #6D1D8E, #6D1D8E00);
  }

  .mission-image-overlay-parent:hover .mission-image-overlay {
    display: none;
  }
}

.courses-python-parent:hover .courses-python-overlay {
  display: block;
}

.column-margin {
  margin: 10px;
}

.image-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.small-square {
  position: relative;
}

@media(max-width:768px) {
  .image-margin {
    margin-left: auto;
    margin-right: auto;
  }

  .small-square>img {
    display: none;
  }
}

.square-one {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #6d1d8ecc;
  border-radius: 10px;
  top: 38px;
  left: 212px;
}

@media(max-width:768px) {
  .square-one {
    display: none;
  }
}


.home-our-mission-section-text-section {
  text-align: left;
}

.home-our-mission-section-para {
  color: #6d1d8e;
  font-size: 25px;
  max-width: 400px;
  line-height: 1.2;
  font-family: "unna", sans-serif;
  font-weight: 400;
}

.home-our-mission-section-highlight-para {
  color: #6d1d8e;
  font-size: 55px;
  line-height: 1.1;
  font-family: "unna", sans-serif;
  font-weight: 400;
}

.home-our-mission-right-text-normal-para {
  width: 100%;
  max-width: 430px;
  font-size: 14px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
}

@media(max-width:768px) {
  .home-our-mission-section-text-section {
    padding: 0;
  }

  .home-our-mission-section-highlight-para {
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .home-our-mission-right-text-normal-para {
    width: 328px;
  }
}


/* job-oriented */

.job-orianted-text-section {
  margin-top: 105px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

h2.job-oriented-heading {
  font-size: 55px;
  color: #fff;
  font-family: "Kaisei HarunoUmi";
}

p.job-oriented-subheading {
  font-size: 25px;
  color: #fff;
}

p.job-oriented-normal-para {
  color: #fff;
  font-size: 15px;
  width: 100%;
  max-width: 380px;
  margin-top: 20px;
}

/* job orianted cards */

#job-orianted-section {
  background: #6d1d8e;
  position: relative;
  padding-bottom: 60px;
}

.job-oriented-container {
  height: 900px;
}

.job-oriented-card {
  width: 360px;
  background-color: #d8c2e1;
  border-radius: 10px;

}

.circle {
  background: #f3d6ff;
  opacity: 70%;
  height: 98px;
  width: 98px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
}

.job-oriented-container .card .card-detials {
  color: #515151;
  font-size: 15px;
  width: 100%;
  max-width: 200px;
}

.job-oriented-container .card .card-link {
  color: #6d1d8e;
  font-weight: 500;
  padding-bottom: 2px;
  border-bottom: 2px solid;
}

.card-detials.mearn-stack {
  width: 100%;
  max-width: 200px;
}

/* card position */

.job-oriented-card.data-science {
  position: absolute;
  top: 18px;
  right: 0px;
}

.job-oriented-card.python {
  position: absolute;
  right: 8px;
  top: 22px;
}

.job-oriented-card.mearn {
  position: absolute;
  top: 20px;
  right: 0px;
}

/* card content */

.card-content .python-django {
  width: 100%;
  max-width: 270px;
  padding-top: 15px;
  font-size: 20px;
  color: #6d1d8e;
}

.card-content .data-science {
  width: 100%;
  font-size: 20px;
  max-width: 215px;
  padding-top: 15px;
  color: #6d1d8e;
}

.card-detials.data-science {
  width: 100%;
  max-width: 250px;
}

.card-content .mearn-stack {
  width: 100%;
  max-width: 256px;
  padding-top: 15px;
  color: #6d1d8e;
  font-size: 20px;
}

.card-content.card-detials {
  font-family: "Mulish";
}

.card-body.width {
  width: 100%;
  padding-bottom: 30px;
  padding-left: 30px;
}

.card-detials-bold {
  max-width: 200px;
  font-weight: 700;
  font-family: "mulish";
  font-size: 15px;
}

.job-oriented.card-link {
  color: #6d1d8e;
  color: #6d1d8e;
  padding-bottom: 2px;
  border-bottom: 2px solid #6d1d8e;
}

/* icons to display */
.icon-1 {
  position: absolute;
  top: 0%;
  left: 160px;
}

.icon-2 {
  position: absolute;
  left: 70px;
  bottom: 200px;
}

.icon-3 {
  position: absolute;
  left: 205px;
  bottom: 0px;
}

.job-oriented-card:hover {
  background-color: #fff;
  box-shadow: 0px 3px 30px #170120a1;

}

.course-mobilebanner {
  display: none;
}

@media(max-width:767px) {

  .icon-1,
  .icon-2,
  .icon-3 {
    /* hides icons on small screens */
    display: none;
  }

  .job-oriented-heading {
    font: 700 13px 'Mulish';
  }

  .job-oriented-normal-para {
    font: 400 12px 'Mulish';
  }

  .course-webbanner {
    display: none;
  }

  .course-mobilebanner {
    display: block;
  }
}


/************************************/
/* instagram feeds section */
/************************************/

#home-instagram-feeds {
  background-color: #F9F9F9;
  padding-bottom: 90px;
}

.home-instagram-feed-heading-section {
  margin-top: 30px;
  margin-bottom: 40px;
}

.home-instagram-images {
  border-radius: 10px;
  width: 387px;
  height: 387px;
  object-fit: cover;
  position: relative;
}

.home-insta-multiple-image {
  position: absolute;
  top: 27px;
  right: 23px;
}

.home-instagram-feed-heading {
  font: 700 55px 'Kaisei HarunoUmi';
  color: #6D1D8E;
}

.home-instagram-image-wrapper {
  padding: 0%;
  margin: 0%;
}

.home-instagram-image-galary {
  display: flex;
}

@media(max-width:768px) {
  .home-instagram-feed-heading {
    font: 700 25px 'Kaisei HarunoUmi';
    margin-bottom: 10px;
  }

  .home-instagram-feed-heading-section {
    margin: 20px 0;
  }
}

@media (max-width:576px) {
  #home-instagram-feeds {
    padding-bottom: 74px;
  }

  .home-instagram-images {
    width: 156px;
    height: 156px;
    margin: 15px;
    flex: 1;
  }

  .home-instagram-image-wrapper {
    display: flex;
  }
}



/***********************************/
/* card section */
/* dot image */
/***********************************/

.home-card-logos {
  margin-bottom: 74px;
  margin-top: 14px;
}

.card-section-wrapper {
  margin-top: 30px;
}

.image-dot {
  position: absolute;
  right: 195px;
  height: 180px;
  width: 572px;
}

.heading-about {
  color: #6d1d8e;
  display: block;
  font: 700 55px 'Mulish';
}

.about-para {
  color: #6d1d8e;
  z-index: 2;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 120px;
  font: 700 25px 'Mulish';
  line-height: 1.3;
}

@media(max-width:768px) {
  .home-card-logos {
    margin: 0%;
  }

  .heading-about {
    font: 700 30px 'Kaisei HarunoUmi';
    display: block;
    margin-bottom: 10px;
  }

  .about-para {
    font: 700 13px 'Mulish';
    margin-bottom: 50px;
  }
}


/* card teach your education */

.card-title.home-luminar-lab-assurance-card {
  color: #515151;
  font: 700 21px "Mulish";
}

.card-text.discription.home-luminar-lab-assurance-card {
  padding-top: 0px;
  font: 400 15px 'Mulish';
  color: #515151;
}

.home-card-section-icon {
  padding-top: 15px;
  padding-bottom: 47px;
}

.about-card.home-luminar-lab-assurance-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 16px;
}

.about-card.home-luminar-lab-assurance-card:hover {
  background-color: #fff;
  box-shadow: 0px 6px 20px #6d1d8e59;
}

.card-text-discription.card-text-discription {
  text-align: center;
  padding: 30px;
}

.about-card.home-luminar-lab-assurance-card {
  background-color: #f9f9f9;
}


@media(max-width:768px) {
  .card-title.home-luminar-lab-assurance-card {
    font: 700 15px 'Mulish';
    /* padding-bottom: 11px; */
    /* padding-top: 22px; */
  }

  .card-text-discription.home-luminar-lab-assurance-card {
    font: 400 12px 'Mulish';
    padding: 0%;
    /* padding-left: 11px;
    padding-right: 11px; */
  }

  .about-card.home-luminar-lab-assurance-card {
    width: 76%;
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

  }

  .home-card-section-icon {
    padding-top: 11px;
    padding-bottom: 22px;
  }

  /* .home-card-section-icon.icon-one{
    width: 31px;
    height: 43px;
  } */
}



/* people say about luminar */

.people-say-about-section {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 0%;
  background-color: #f9f9f9;
}

.people-say-about-wrapper {
  height: 600px;
}

.people-say-about-row {
  position: relative;
  margin-top: 100px;
}

.card.people-say-about-card {
  background-color: #fff;
  color: #515151;
  box-shadow: 0px 0px 8px #00000029;
  width: 539px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  border-radius: 10px;
  z-index: 1;
}

.home-say-about-luminar {
  margin-top: 115px;
  color: #6d1d8e;
  font: 700 25px 'Kaisei HarunoUmi';
  text-align: left;
}

.home-say-about-luminar-heading {
  font: 700 55px 'Kaisei HarunoUmi';
  line-height: 0.9;
}

.home-people-say-about-luminar {
  color: #A4A4A4;
  font: 400 15px "Mulish";
}

.people-say.fas.fa-pause {
  position: absolute;
  top: 20px;
  left: 30px;
  color: #6d1d8e;
  font-size: 30px;
}

.card-body.people-say-about {
  margin: 10px;
  padding-top: 141px;
}

.people-say-name {
  color: #6d1d8e;
  font-weight: 800;
}

.people-say-designation {
  color: #a4a4a4;
  min-width: 220px;
}

.profile-image-circle {
  width: 82px;
  height: auto;
  border-radius: 50%;
  margin: 15px 0;
  display: none;
}

.people-say-subject-rating-wrapper {
  display: flex;
}

.people-say-star {
  flex: 1 1;
  min-width: 150px;
  text-align: right;
  color: #ed8a19;
  font-size: 20px;
}

#fa-star-one {
  color: #d8d8d8;
}

.fa-star-half-alt {
  transform: scaleX(-1);
}

/* arrow right icon */

.people-say .fas.fa-chevron-circle-left {
  position: absolute;
  right: 135px;
  bottom: -95px;
  color: #d8d8d8;
  font-size: 50px;
  margin: 2px;
}

.people-say .fas.fa-chevron-circle-right {
  position: absolute;
  right: 65px;
  bottom: -95px;
  color: #6d1d8e;
  font-size: 50px;
  margin: 2px;
}




@media (max-width:768px) {
  .people-say-about-wrapper {
    height: 500px;
  }

  .people-say.fas.fa-pause {
    font-size: 16px;
  }

  .home-say-about-luminar-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    color: #6D1D8E;
  }

  .home-people-say-about-luminar {
    font: 400 15px 'Mulish';
  }

  .people-say-card-image,
  .people-say-card-secondary-image,
  .fas.fa-chevron-circle-left,
  .fas.fa-chevron-circle-right {
    display: none;
  }

  .card.people-say-about-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    box-shadow: 0px 6px 15px #00000029;

  }

  .card-body.people-say-about {
    padding-top: 69px;
  }

  .people-say-subject-rating-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .people-say-about-row {
    margin-top: 25px;
  }

  .people-say-star {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 10px 0px;
  }

  .profile-image-circle {
    display: block;
  }

  .home-say-about-luminar {
    margin-top: 41px;
  }
}


/************************************/
/*technologys  stacks */
/************************************/

.stack-image-1 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  margin: 10px;
  max-width: 500px;
}

.stack-image-2 {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px;
  max-width: 500px;
}

.stack-square {
  height: 200px;
  width: 200px;
  background: rgb(130, 132, 133);
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;
}

.stack-heading {
  color: #6d1d8e;
  font-size: 55px;
}

.stack-para {
  text-align: left;
}

.technology-stack-wrapper {
  display: flex;
  flex-direction: row;
}



/* courses vedio */

#course-vedio-section {
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 130px;
}

.python-course-image {
  position: relative;
}

.courses-python-parent {
  position: relative;
}

.courses-python-overlay {
  top: 0px;
  border: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #6d1d8ecc;
  z-index: 2;
}

.vedio-icon-white {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.courses-content-right {
  padding-left: 55px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-end;
}

.python-vedio-play-image {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 82px;
  height: auto;
}

.courses-paragraph-date {
  color: #6d1d8e;
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  line-height: 0.5;
  margin-top: 15px;
}

.courses-paragraph {
  color: #6d1d8e;
  font-family: "Archivo", sans-serif;
  font-weight: 400;
}

.courses-course-name {
  font-size: 55px;
  font-family: "Unna", sans-serif;
  font-weight: 400;
  color: #2b2b2b;
  margin-top: 10px;
  margin-bottom: 25px;
}

.course-description {
  font-size: 15px;
  color: #464545;
  width: 100%;
  max-width: 385px;
  font-weight: 400;
  font-family: "Mulish", sans-serif;
}

@media (max-width:768px) {
  .vedio-icon-white {
    width: 52px;
    height: auto;
  }

  #course-vedio-section {
    padding-top: 0% !important;
  }
}



/* join now section */

#home-join-now-section,
#course-join-now-section,
#contact-join-now-section {
  padding-top: 0%;
}

.home-join-now-heading {
  padding-top: 120px;
  font-size: 45px;
  width: 98%;
  line-height: 1.4;
  color: #F5F5F5;
  font: 400 45px 'Kaisei HarunoUmi';
}

.join-now-callus {
  padding-top: 10px;
  font-size: 15px;
  color: #bb9867;
  font-family: "Mulish";
  font-weight: 400;
  text-align: center;
}

.join-now-button {
  color: #6d1d8e;
  padding: 10px 24px;
  width: 162px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 800;
  font-family: "Mulish";
  margin-top: 55px;
}

.join-now-background {
  height: 572px;
  width: 100%;
  background: url(../Assets/images/pexels-kindel-media-7054785.png) center center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
  background-position: bottom;
}

@media(max-width:768px) {
  .home-join-now-heading {
    text-align: start;
    font: 400 30px 'Kaisei HarunoUmi';
    padding-top: 63px;
    margin-bottom: 0;
  }

  .join-now-callus {
    text-align: start;
    font: 700 13px 'Mulish';
    width: 225px;
    padding-top: 21px;
    margin: 0%;
  }

  .join-now-button {
    font: 800 12px 'Mulish';
    padding: 12px 47px;
    margin-top: 50px;
    margin-bottom: 60px;
  }

  .join-now-background {
    background-image: none;
    background-color: #161513;
    height: auto;
  }
}

/********************************************/
/* footer */
/********************************************/

footer {
  margin-top: 15px;
}

.footer-paragraph {
  color: #6d1d8e;
  font: 400 16px "Mulish";
  margin-bottom: 0;
  display: inline-block;
}

.course {
  font-weight: bold;
  color: #6d1d8e;
  font-size: 16px;
  margin-top: 15px;
  padding-left: 20px;
}

hr {
  background-color: #6d1d8e;
}

.home-footer-address {
  margin: 16px 0;
  color: #7e7e7e;
  max-width: 260px;
  font: 700 15px "Mulish";
  line-height: 1.6;
}

.home-footer-phone {
  color: #6D1D8E;
}

.footer-certificate-logo {
  margin-top: 50px;
  /* height: 67px; */
}

/* accordion section */
.accordion.large-screen {
  display: none;
}

@media (max-width:769px) {
  .accordion.large-screen {
    display: block;
    margin-top: 44px;
  }
}

.footer-accordion a {
  display: block;
  color: #7e7e7e;
  display: block;
  margin: 5px;
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 1px solid #cfcfcf;
  font: 400 15px "Mulish";
}

.footer-accordion a:hover {
  color: #6D1D8E;
  border-bottom: 1px solid #6D1D8E;
}

.footer-courses a {
  color: #7e7e7e;
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #cfcfcf;
  font: 400 15px "Mulish";
}

.footer-courses a:hover {
  color: #6D1D8E;
  border-bottom: 1px solid #6D1D8E;
}

.about-our-services {
  display: flex;
  flex: 1;
  margin-top: 30px;

}

.about-our-services a {
  color: #5B5B5B;
  font: 700 18px 'Kaisei HarunoUmi';
  margin-bottom: 20px;
}

.about-our-services a:hover {
  color: #6d1d8e;
  border-bottom: 1px solid #6d1d8e;
}

.before-email-symbol::before {
  content: '|';
  color: #E8E8E8;
  font-weight: 100;
  font-size: 36px;
  position: absolute;
  top: -16px;
  opacity: .7;
}

hr.before-email {
  background-color: #E8E8E8;
  margin-top: 34px;
  font-size: 30px;
  font-weight: 100;
  color: #7e7e7e;
  opacity: 0.4;
}

.social-media-icons {
  width: 43px;
  height: 43px;
}

.footer-wrapper {
  background-color: #F9F9F9;
}

a.terms-and-use {
  color: #6d1d8e;
}

.footer-copyrights {
  font: 400 15px 'Mulish';
}

@media(max-width:992px) {

  .before-email-symbol {
    display: none;
  }

  .footer-certificate-logo {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .footer-certificate-logo-image-one {
    width: 93px;
    height: 41px;
  }

  .footer-certificate-logo-image-two {
    width: 38px;
    height: 38px;
  }

  .footer-certificate-logo-image-one,
  .footer-certificate-logo-image-two {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media(max-width:768px) {
  .footer-paragraph {
    font: 700 12px 'Mulish';
  }

  .social-media-icons-wrapper {
    display: flex;
  }

  .social-media-icons {
    width: 43px;
    height: 43px;
    flex: 1;
  }

  .footer-brand-image {
    padding-top: 28px;
    width: 243px;
  }

  .home-footer-address {
    margin: 0px;
    margin-top: 7px;
    font: 700 12px 'Mulish';
    max-width: 210px;
  }

  .footer-hr,
  .course,
  .footer-courses a {
    display: none;
  }

  .about-our-services {
    margin: 0px;
    margin-top: 22px;
  }

  .about-our-services a {
    padding-bottom: 18px;
  }

  .about-our-services a:hover {
    color: #6D1D8E;
    border-bottom: 2px solid #6D1D8E;
  }

  /* accordion section */
  .accordion-footer-heading {
    font: 700 18px 'Kaisei HarunoUmi';
    color: #6D1D8E;
    padding-bottom: 10px;
    border-bottom: 2px solid #6D1D8E;
    text-align: left;
    background-color: #fff;
  }

  .footer-accordion a {
    font: 400 12px "Mulish";
    color: #7E7E7E;
    padding: 12px 0;
    margin: 0%;
  }

  .about-our-services a {
    font: 700 18px 'Kaisei HarunoUmi';
    color: #5B5B5B;
    display: block;
    margin: 0px;
    margin-top: 22px;
  }

  .footer-copyrights {
    font: 400 12px 'Mulish';
    display: block;
    width: 252px;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-wrapper {
    margin-top: 29px;
  }
}


/**********************************/
/*course page */
/* coureses section
**********************************/

#courses {
  margin-top: 74px;
  padding-top: 0%;
  padding-bottom: 45px;
  height: auto;
  max-width: 100%;
  background: #DECCFF;
}

.courses-header-wrapper {
  padding-top: 105px;
  width: 100%;
}

.courses-header-image-wrapper {
  margin-top: 37px;
  display: flex;
}

.courses-heading {
  color: #6d1d8e;
  font: 700 65px 'Kaisei HarunoUmi';
}

.courses-sub-heading {
  color: #6d1d8e;
  font: 700 25px 'Mulish';
  margin-bottom: 6px;
}

.courses-heading-paragraph {
  color: #515151;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 56px;
  max-width: 450px;
  font: 400 15px 'Mulish';
}

.header-image-certification {
  width: auto;
  height: auto;
}

.courses-paragraph-button-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: center;
}

.courses-paragraph-button {
  background-color: #6d1d8e;
  color: #ffffff;
  width: 170px;
  height: 55px;
  margin: 7px 0;
  margin-right: 18px;
  border-radius: 30px;
  font: 800 17px 'Mulish';
}

/********************************
 /* coureses section media queries
**********************************/

@media(max-width:576px) {
  .courses-header-wrapper {
    padding-top: 37px;
  }

  .courses-heading {
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .courses-sub-heading {
    font: 700 13px 'Mulish';
  }

  .courses-heading-paragraph {
    font: 400 12px 'Mulish';
    padding-right: 0%;
    max-width: 315px;
    margin: 30px 0;
  }

  .courses-paragraph-button {
    display: inline-block;
    margin: 10px;
    width: 156px;
    height: 46px;
    font: 800 15px 'Mulish';
  }

  .header-image-certification {
    width: 100%;
    height: auto;
  }
}

/****************************************/
/*course html training program section */
/****************************************/

#training-programs {
  background: #ffffff;
}

.training-program-wrapper {
  width: 100%;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.training-program-heading {
  color: #6d1d8e;
  font: 700 55px 'Kaisei HarunoUmi';
  line-height: 1.3;
}

.training-program-paragraph {
  color: #515151;
  padding-top: 25px;
  font: 400 15px 'Mulish';
}

@media(max-width:576px) {
  .training-program-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    margin-bottom: 6px;
  }

  .training-program-paragraph {
    font: 400 12px 'Mulish';
  }

  .training-program-wrapper {
    margin-top: 23px;
  }
}


/********************************************/
/* course html */
/* course search  input bar */
/********************************************/

.courses-input-column {
  padding: 40px;
  margin-bottom: 25px;
}

.search-course {
  position: relative;
  display: block;
  padding: 6px 12px;
  width: 100%;
  line-height: 2.5;
  border: none;
  outline: none;
  border-bottom: 2px solid #c5c5c5;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 25px;
}

.search-icon {
  position: absolute;
  right: 50px;
  top: 120px;
}

@media (max-width:768px) {
  .courses-input-column {
    padding: 0%;
    padding-top: 55px;
    margin: 0% auto;
  }

  ::placeholder {
    font: 100 12px 'Mulish';
  }

  .search-course {
    line-height: 1;
    width: 90%;
    font: 100 12px 'Mulish';
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 46px;
  }

  .search-icon {
    width: 22px;
    height: auto;
    right: 25px;
    top: 105px;
  }
}

/********************************************/
/* course html */
/* training-program-cards */
/********************************************/



.training-program-row {
  margin: 10px;

}

.training-program-column {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.training-program-card {
  background-color: #f8f8f8;
  border-radius: 15px;
  /* min-width: 350px; */
}

.training-program-card:hover {
  background-color: #fff;
  box-shadow: 0px 3px 30px #00000033;
}


.training-program-card:hover .training-program-card-button {
  background-color: #460b5e;
  color: #fff;
}


/* card one */

.training-program-card-one-heading,
.training-program-card-one-text,
.training-program-card-detials,
.training-program-card-button {
  margin-top: 22px;
  margin-bottom: 22px;
}

.training-program-card-one-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 201px;
  padding-left: 8px;
}

.training-program-card-one-text {
  text-align: left;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 285px;
}

.training-program-card-one-detials {
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
  width: 100%;
}

.training-program-card-title {
  color: #6d1d8e;
  font: 700 15px "Mulish";
  display: block;
}

.training-program-card-button {
  background-color: #fff;
  color: #6d1d8e;
  width: 150px;
  height: 45px;
  padding: 0px;
  margin-left: 10px;
  border-radius: 30px;
  border: 2px solid #6d1d8e;
  font-size: 18px;
  font-family: "Mulish";
  font-weight: 800;
}


/* card two */

.training-program-card-two-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 258px;
  padding-left: 8px;
}

.training-program-card-two-text {
  text-align: left;
  margin-top: 20px;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 301px;
}

.training-program-card-two-detials {
  margin-top: 45px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
}

/* card three */

.training-program-card-three-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 260px;
  padding-left: 8px;
}

.training-program-card-three-text {
  text-align: left;
  margin-top: 20px;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 300px;
}

.training-program-card-three-detials {
  margin-top: 66px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
}

/* card four */

.training-program-card-four-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 155px;
  padding-left: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.training-program-card-four-text {
  text-align: left;
  margin-top: 20px;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 300px;
}

.training-program-card-four-detials {
  margin-top: 30px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
}

/* card five */

.training-program-card-five-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 190px;
  padding-left: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.training-program-card-five-text {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 23px;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 300px;
}

.training-program-card-five-detials {
  margin-top: 31px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
}

.training-program-card-five-duration {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
}

/* card six */

.training-program-card-six-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 245px;
  padding-left: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.training-program-card-six-text {
  text-align: left;
  margin-top: 20px;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 305px;
}

.training-program-card-six-detials {
  margin-top: 30px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
}

/* card seven */

.training-program-card-seven-heading {
  color: #6d1d8e;
  font-size: 20px;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 150px;
  padding-left: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.training-program-card-seven-text {
  text-align: left;
  margin-top: 25px;
  padding: 0px;
  padding-left: 8px;
  color: #515151;
  font-size: 15px;
  font-family: "Mulish";
  width: 100%;
  max-width: 305px;
}

.training-program-card-seven-detials {
  margin-top: 45px;
  font-size: 15px;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 8px;
}

.training-program-view-all-cards-button {
  display: none;
}

@media (max-width:768px) {

  .training-program-card-one-heading,
  .training-program-card-two-heading,
  .training-program-card-three-heading,
  .training-program-card-four-heading,
  .training-program-card-five-heading,
  .training-program-card-six-heading,
  .training-program-card-seven-heading {
    font: 700 18px 'Mulish';
  }

  .training-program-card-one-text,
  .training-program-card-two-text,
  .training-program-card-three-text,
  .training-program-card-four-text,
  .training-program-card-five-text,
  .training-program-card-six-text,
  .training-program-card-seven-text,
  .training-program-card-five-duration {
    font: 400 12px 'Mulish';
  }

  .training-program-card-title {
    font: 700 12px 'Mulish';
  }

  .training-program-card-one-detials,
  .training-program-card-two-detials,
  .training-program-card-three-detials,
  .training-program-card-four-detials,
  .training-program-card-five-detials,
  .training-program-card-six-detials,
  .training-program-card-seven-detials {
    font: 400 12px 'Mulish';
  }

  .training-program-card {
    margin: 10px auto;
  }

  /*the following code hide in small screen */

  .training-program-row.two,
  .training-program-row.three {
    display: none;
  }

  /* button display only small screen */
  .training-program-view-all-cards-button {
    display: inline-block;
    background-color: #fff;
    color: #6D1D8E;
    border: 2px solid #6D1D8E;
    border-radius: 30px;
    width: 156px;
    height: 38px;
    font: 800 12px 'Mulish';
    margin: 56px auto;
  }
}


/*********************************************/
/* courses-why-luminar technolad
------------------------------------- */

#courses-why-luminar {
  background-color: #f9f9f9;
  padding-bottom: 0%;
  padding-top: 0%;
}

.courses-why-luminar {
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 951px; */
}

.why-luminar-image-left.img-fluid {
  height: 900px;
  /* height: 95vh; */
  object-fit: cover;
  /* width: 590px; */

}

.why-luminar-text-section {
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.why-luminar-heading {
  font-size: 55px;
  font-family: "Barlow";
  font-weight: 700;
  color: #6d1d8e;
}

.why-luminar-paragraph {
  font-size: 15px;
  color: #515151;
  text-align: left;
  width: 100%;
  max-width: 550px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.why-luminar-list {
  list-style-type: none;
  font-size: 16px;
  color: #515151;
  font-family: "Mulish";
  font-weight: 700;
  text-align: left;
  line-height: 2;
}

/* ul icons */
ul.why-luminar-list li {
  background-image: url(../Assets/images/courses.html/Group45.png);
  background-repeat: no-repeat;
  margin-top: 5px;
  padding-left: 40px;
}

@media (max-width:576px) {
  .courses-why-luminar-image-section {
    display: none;
    padding: 0%;
  }

  .why-luminar-text-section {
    padding: 0% 15px;
  }

  .why-luminar-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    text-align: left;
    width: 210px;
    padding: 0%;
  }

  .why-luminar-paragraph {
    font: 400 12px 'Mulish';
  }

  ul.why-luminar-list li {
    font: 700 12px 'Mulish';
    line-height: 2.7;
    margin-top: 0%;
  }
}

/*******************************/
/* course join now section */
/*******************************/

.course-join-now-heading {
  color: #F5F5F5;
  font-family: 'Barlow';
  font-size: 45px;
  font-weight: 600;
  padding-top: 120px;
  width: 100%;
  overflow: hidden;
  line-height: 1.2;
}

/* join now for course,contact.html */
.course-join-now-callus,
.contact-join-now-callus {
  color: #BB9867;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Mulish';
  padding-top: 25px;

}





/*******************************/
/* contact us page*/
/*******************************/



@media (max-width:768px) {
  #contact-us {
    background-image: none;
    background-color: #F9F9F9;
  }
}

/************************************/
/* contact-us container */
/* contact-us-heading */
/************************************/
.contact-us-container {
  display: flex;
}

.contact-us-heading {
  font-family: 'Kaisei HarunoUmi';
  font-size: 55px;
  color: #5F078A;
  font-weight: 700;
  margin-bottom: 47px;
  padding-left: 10px;
}

.contact-us-circle {
  background: #F3F3F3;
  height: 83px;
  width: 83px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  margin-bottom: 28px;
}

.contact-us-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.contact-us-subheading {
  color: #6D1D8E;
  font-family: 'Mulish';
  font-weight: 700;
  font-size: 15px;
  padding-top: 3px;
}

.contact-us-paragraph {
  color: #505050;
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 15px;
}

.contact-us-text {
  flex: 1 1;
}


.contact-us-social-media {
  display: block;
  padding-left: 0%;
  position: relative;
  margin-top: 95px;
}

.social-icons {
  margin-left: -5px;

}

.social-media-icon {
  width: 47px;
}

.contact-us-connect {
  color: #363636;
  font-size: 25px;
  font-family: "Mulish";
  font-weight: 800;
}

.contact-us-submit-button {
  background-color: #fff;
  color: #5F078A;
  padding: 12px 46px;
  display: inline-block;
  border: 2px solid #5F078A;
  border-radius: 30px;
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 20px;
}

.contact-us-checkbox {
  background-color: #fff;
  color: #515151;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  width: 193px;
  border: 2px solid #5F078A;
  margin-right: 20px;
}

/* contact us right  */
.contact-us-column-right-heading {
  color: #363636;
  font-family: 'Kaisei HarunoUmi';
  font-weight: 700;
  font-size: 30px;
  margin: 0%;
  margin-bottom: 13px;
}

.contact-us-column-right-paragraph {
  color: #515151;
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 15px;
  max-width: 520px;
}

.form-control.contact-us-input-field {
  height: 60px;
}

/*******************************************/
/* contact contact-us-container media query section */


@media (max-width:768px) {
  #contact-us {
    padding-top: 38px;
    height: 100%;
  }

  .contact-us-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact-us-column-right-heading {
    font: 700 20px 'Kaisei HarunoUmi';
    padding: 0%;
    margin-bottom: 16px;
  }

  .contact-us-column-right-paragraph {
    font: 400 12px 'Mulish';
    color: #515151;
  }

  .form-control.contact-us-input-field {
    height: 47px;
    border: 1px solid #BBBBBB;
    border-radius: 7px;
    padding: 16px 18px;
  }

  textarea.form-control {
    height: 229px;
  }

  .contact-us-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    margin: 0%;
    margin-top: 43px;
    margin-bottom: 38px;
  }

  .contact-us-circle {
    width: 69px;
    height: 69px;
  }

  .contact-us-subheading {
    font: 700 15px 'Mulish';
  }

  .contact-us-paragraph {
    font: 400 12px "Mulish";
  }

  .contact-us-social-media {
    display: none;
  }

  .form-check-label {
    flex: 1;
  }

  .contact-us-checkbox {
    width: 156px;
    height: 46px;
    font-size: 10px;

  }

  .contact-us-submit-button {
    width: 156px;
    height: 46px;
    font: 800 15px 'Mulish';
  }

  .contact-us-button-section {
    display: flex;
  }


}


/******************************/
/* contact-join-now-section */
/******************************/

#contact-join-now-section {
  padding-top: 0px;
}

/* contact-join-now-heading */
.contact-join-now-heading {
  color: #F5F5F5;
  font-size: 40px;
  text-align: center;
  font-family: 'Kaisei HarunoUmi';
  font-weight: 400;
  padding-top: 120px;
  width: 100%;
  overflow: hidden;
  line-height: 1.2;
}



/*******************************/
/* courses detials page*/
/*******************************/


/*courses detials page header section
************************************/

#courses-detials {
  background: #FAFAFA;
  width: 100%;
  height: auto;
  margin-top: 74px;
  position: relative;
  padding: 0%;
}

.course-detials-header-text {
  margin-top: 120px;

}

.course-detials-heading {
  color: #6D1D8E;
  font-size: 65px;
  font-family: 'Kaisei HarunoUmi';
  font-weight: 700;
}

.course-detials-subheading {
  font-size: 25px;
  font-family: 'Mulish';
  font-weight: 700;
  color: #6D1D8E;
}

.course-detials-paragraph {
  margin-top: 45px;
  font-size: 15px;
  color: #515151;
  width: 100%;
  max-width: 470px;
}

.course-detials-header-button {
  background-color: #6D1D8E;
  color: #fff;
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 18px;
  padding: 10px 25px;
  border-radius: 50px;
  margin-right: 14px;
  margin-top: 45px;
}


/*
.course-detials-header- resposnsive section
***********************************/
@media (max-width:576px) {
  .course-detials-header-text {
    margin-top: 1px !important;
  }

  .course-detials-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    margin-bottom: 36px;
  }

  .course-detials-subheading {
    font: 700 13px 'Mulish';
    /* margin-bottom: 26px; */
  }

  .course-detials-paragraph {
    font: 400 12px 'Mulish';
    margin: 0;
    margin-bottom: 40px;
  }

  .course-detials-header-image {
    margin: 24px auto;
  }

  .course-detials-header-button-wrapper {
    display: flex;
  }

  .course-detials-header-button {
    width: 16px;
    height: 46px;
    font: 800 15px 'Mulish';
    margin: 0%;
    margin-right: 8px;
    padding: 0%;

  }

  .course-detials-header-button.two {
    margin-right: 0%;
  }

}

/********************************/
/* courses detials page */
/*course-detials-course-detials */
/********************************/
.course-detials-course-about-container {
  padding: 0%;
  text-align: center;
}

.course-detials-course-about-heading {
  margin-top: 17px;
  color: #6D1D8E;
  font: 700 55px 'Kaisei HarunoUmi';
}

.course-detials-course-about-subheading {
  color: #515151;
  font: 700 20px 'Mulish'
}

.course-detials-course-about-detials {
  margin-top: 30px;
  padding: 4px;
  padding-bottom: 50px;
  color: #515151;
  font: 400 15px 'Mulish';
}

.course-detials-course-certificate-background {
  background-image: url(../Assets/images/courses-detials/white_dots_lines_background_hd_white_background.png);
  width: 100%;
}

.course-detials-course-certificate-detials {
  margin-top: 86px;
  margin-bottom: 15px;
  padding-left: 6rem;
}

.course-detials-course-certificate-heading {
  font: 700 24px 'Mulish';
  color: #010100;
  padding-left: 6rem;
}

.course-detials-content h2 {
  font: 700 24px 'Kaisei HarunoUmi';
  color: #010100;
  max-width: 80rem;
  padding-left: 6rem;
}

.course-detials-content p {
  font: 400 15px 'Mulish';
  color: black;
  padding-left: 6rem;
}

.course-detials-content h1 {
  font: 700 24px 'Kaisei HarunoUmi';
  color: #010100;
  padding-left: 6rem;
}


.course-detials-course-certificate-image {
  width: 89rem;
  object-fit: cover;
  position: relative;
  left: 8rem;
  height: 53.4rem;
}

/*course-detials-course-about section 
media queries
*****************************************/

@media(max-width:768px) {
  .course-detials-course-about-row {
    padding: 0 15px;
    text-align: left !important;
  }

  .course-detials-course-about-container {
    text-align: left;
  }

  .course-detials-course-about-heading {
    font: 700 30px 'Kaisei HarunoUmi';
    text-align: left;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .course-detials-course-about-subheading {
    font: 700 13px 'Mulish';
  }

  .course-detials-course-about-detials {
    font: 400 12px 'Mulish';
  }
}

/********************************/
/* courses detials page */
/*course-detials-module-section */
/********************************/

.course-detials-container {
  /* height: 800px; */
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.course-detilas-module-table-heading {
  color: #6D1D8E;
  font: 700 55px 'Kaisei HarunoUmi';
}

.course-detilas-module-table-subheading {
  color: #515151;
  font: 700 20px 'Mulish';
  margin-bottom: 50px;
}

/*********************************************
        course-detials-module table
*********************************************/

.course-detials-contents-wrapper {
  display: flex;
}

.course-detials-modules,
.course-lessons {
  display: flex;
  flex-direction: column;
}

.course-detials-module {
  border: 1px solid #6D1D8E;
  width: 193px;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 400 25px 'Mulish';
  color: #989898;
}

.course-lessons {
  margin: 0 2px;
  width: 100%;
}

.course-lessons,
.course-module-heading {
  border: 1px solid #6D1D8E;
}

.course-detials-module.zero,
.course-module-heading {
  height: 150px;
}

.course-detials-module.one {
  background-color: #EEC5FF;
  font: 700 25px 'Mulish';
  color: #6D1D8E;
  background-image: url(../Assets/images/courses.html/Group45.png);
  background-repeat: no-repeat;
  background-position: 16px 19px;
  background-size: 32px 32px;



}

.course-detials-prefer-uss {
  text-align: center;
  color: white;
  margin: 3rem;
  font-family: "Mulish";
  margin-top: 6rem;
  font-size: 4rem;
}

.course-module-heading {
  font: 800 30px 'Mulish';
  color: #6D1D8E;
  height: auto;
}

.course-module-name {
  margin: 50px 0;
}

.course-module-name,
.course-module-lesson-name,
.course-module-lesson-content {
  display: block;
  margin-left: 42px;
  text-align: start;
}

.course-module-lesson-name {
  margin-bottom: 35px;
  font-weight: 700;
  color: #515151;
}

.course-module-lesson-name.one {
  margin-top: 25px;
}

.course-module-lesson-content {
  margin-bottom: 50px;
  font: 400 15px 'Mulish';
  color: #515151;
  max-width: 87%;
}

.course-module-lessons-wrapper {
  height: 100%;
}

.course-module-lessons-content {
  padding: .5em;
  height: 100%;
  overflow-y: scroll;
}


/*course-detials-module-section 
media querie section
******************************************/
@media(max-width:768px) {
  .course-detilas-module-table-heading {
    text-align: left;
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .course-detilas-module-table-subheading {
    text-align: left;
    font: 700 13px 'Mulish';
    margin-bottom: 0px;
  }

  .course-detials-contents-wrapper {
    display: none;
  }

  .accordion>.card>.card-header {
    margin-bottom: 0px;
  }

  .course-detilas-module-accordion {
    margin: 20px auto;
    width: 90%;
  }

  .course-detilas-module-accordion-heading {
    font: 800 15px 'Mulish';
    color: #6E6E6E;
    background-color: transparent;
  }

  .card-header.course-module-heading {
    background-color: #fff;
    border: none;
    border-bottom: 2px solid #DBDBDB;
  }

  .card-header.course-module-heading:hover .course-detilas-module-accordion-heading {
    color: #6D1D8E;
  }

  .card-header.course-module-heading:hover {
    border-bottom: 2px solid #6D1D8E;
  }

  .course-detilas-accordion-heading {
    font: 800 17px 'Mulish';
    color: #6D1D8E;
    margin: 21px auto;
  }

  .course-detilas-accordion-module-name {
    font: 800 15px 'Mulish';
    color: #515151;
    margin-bottom: 20px;
  }

  .course-detilas-accordion-module-content {
    font: 400 12px 'Mulish';
    color: #515151;
    margin-bottom: 20px;
  }

}

@media(min-width:768px) {
  .course-detilas-module-accordion {
    display: none;
  }
}





/*************************************************/
/* courses detials page */
/*course-detials-certified-courses */
/********************************************/

#course-detials-certified-courses {
  background-color: #6D1D8E;
  color: #fff;
  position: relative;
}

.course-detials-certificate-logo {
  margin-top: 60px;
}

.course-detials-certifiecate-caption {
  color: #fff;
  font: 400 20px "mulish";
}

.course-detials-certifiecate-course-heading-one,
.course-detials-certifiecate-course-heading-two {
  color: #fff;
  font: 400 55px 'Kaisei HarunoUmi';
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
}

.course-detials-certifiecate-course-paragraph {
  color: #fff;
  font: 400 15px 'Mulish';
  max-width: 500px;
  margin-top: 21px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  text-align: center;
}

.course-detials-certifiecate-circle {
  background: #F1F1F1;
  height: 320px;
  width: 220px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.course-detials-certifiecate-card {
  margin-left: auto;
  margin-right: auto;
  width: 565px;
  border-radius: 20px;
}

.course-detials-batch-detials {
  color: #303030;
  font: 800 30px 'Mulish';
}

/* .course-detials-title{
  color: #6D1D8E;
  font:700 16px 'Mulish' ;
} */

.course-detials-card-content-section {
  width: 85%;
  margin: 60px auto;
}

.course-detials-card-content-wrapper {
  display: flex;
  flex-direction: row;
}

.course-detials-card-criteria {
  font: 700 16px 'Mulish';
  color: #6D1D8E;
  display: flex;
  justify-content: start;
}

.course-detials-card-criteria-next-batch {
  margin-right: 10px;
}

.course-detials-card-criteria-duration {
  margin-right: 28px;
}

.course-detials-card-criteria-course-fees {
  margin-right: 0px;
}

.course-detials-card-certification {
  line-height: 2;
  margin-left: 5px;
}

.course-detials-card-info {
  flex: 2;
  display: flex;
  justify-content: start;
  margin: 0;
  margin-left: 25px;
}

.course-detials-card-info.card-fees {
  margin-left: 0px;
}

.course-detials-card-data {
  font: 400 16px 'Mulish';
  color: #515151;
}

.course-detials-card-fees {
  font-weight: 700;
}


.course-detials-for-new-admission {
  display: block;
  color: #6D1D8E;
  font: 700 15px 'Mulish';
}

.btn.card-course-enroll {
  background-color: #6D1D8E;
  color: #FFFFFF;
  font: 800 25px 'Mulish';
  border-radius: 50px;
  padding: 15px 110px;
  margin-bottom: 45px;
  margin-top: 19px;
}

.course-detials-certified-courses-background-image-left {
  position: absolute;
  bottom: 130px;
  left: 0px;
  width: 100%;
  max-width: 500px
}

.course-detials-certified-courses-background-image-right {
  position: absolute;
  bottom: 130px;
  right: 0px;
  width: 100%;
  max-width: 500px
}

.course-detials-certifiecate-course-text-bottom-wrapper {
  margin-top: 61px;
  width: 100%;
  max-width: 670px;
}

.course-detials-certifiecate-course-text-bottom {
  color: #D05EFF;
  font: 400 italic 15px 'Mulish';
}

/* course-detials-certified-courses media queries
************************************************/

@media(max-width:576px) {
  .course-detials-certificate-logo {
    margin-top: 50px;
  }

  .course-detials-certificate-image {
    width: 70px;
    height: auto;
  }

  .course-detials-certifiecate-caption {
    font: 400 8px 'Mulish';
  }

  .course-detials-certifiecate-course-heading-one,
  .course-detials-certifiecate-course-heading-two {
    display: inline;
  }

  .course-detials-course-info-heading {
    position: relative;
    top: 1rem;
  }

  .course-detials-course-info-row-two-wrapper {
    position: relative;
    top: 48rem;
  }

  .course-detials-course-info-wrapper h1 {
    position: relative;
    top: -40rem;
  }

  .course-detials-course-info-row-two-wrapper h2 {
    position: relative;
    top: -70rem;
  }

  .course-detials-content h2 {
    font: 700 16px "Kaisei HarunoUmi";
    color: #010100;
    max-width: 38rem;
    padding-left: 0rem;
    text-align: center;

  }

  .course-detials-course-info-paragraph-subject-image-one {
    position: relative;
    top: 22rem;
  }

  .course-detials-course-info-wrapper p {
    position: relative;
    top: -40rem;
  }

  .course-detials-course-info h2 {
    font: 700 20px "Mulish";
    position: relative;
    top: -40rem;

  }

  .course-detials-course-content-wrapper p {
    position: relative;
    top: -22rem;
  }

  .course-detials-course-info-paragraph-subject-image-two {
    /* position: relative;
  top: 6rem; */
    margin-top: 15rem;
  }
}

@media(max-width:768px) {

  .course-detials-certified-courses-background-image-left,
  .course-detials-certified-courses-background-image-right {
    display: none;
  }

  .course-detials-certifiecate-course-heading-one,
  .course-detials-certifiecate-course-heading-two {
    font: 400 30px 'Kaisei HarunoUmi';
  }

  .course-detials-certifiecate-caption {
    margin-bottom: 50px;
  }

  .course-detials-certifiecate-course-paragraph {
    margin-bottom: 24px;
  }

  .course-detials-certifiecate-course-heading-wrapper {
    margin: auto 14px;
  }

  /* card section */
  .course-detials-certifiecate-circle {
    width: 130px;
    height: 130px;
    margin-top: 37px;
    margin-bottom: 20px;
  }

  .course-detials-certifiecate-card-logo {
    width: 67px;
    height: 62px;
  }

  .course-detials-batch-detials {
    font: 800 15px 'Mulish';
    margin-bottom: 30px;
  }

  .course-detials-card-criteria {
    font-size: 12px;
  }

  .course-detials-card-data {
    font-size: 12px;
    max-width: 165px;
    margin-bottom: 12px;
  }

  .course-detials-card-fees {
    font: 700 12px 'Mulish';
  }

  .course-detials-certifiecate-card {
    width: auto;
  }

  .course-detials-card-content-section {
    margin: 0% auto;
    text-align: left;
  }

  .course-detials-card-info {
    margin-left: 6px;
  }

  .course-detials-card-criteria-certification {
    margin-top: 2px;
  }

  .course-detials-card-certification {
    line-height: 1.7;
    margin-bottom: 30px;
  }

  .course-detials-for-new-admission {
    font: 700 13px 'Mulish';
    margin-bottom: 11px;
  }

  .btn.card-course-enroll {
    width: 156px;
    height: 44px;
    font: 800 14px 'Mulish';
    padding: 0;
    margin-top: 0%;
    margin-bottom: 21px;
  }

  .course-detials-certifiecate-course-text-bottom {
    font: italic 12px 'Mulish';
    margin-bottom: 34px;
  }

  .course-detials-certifiecate-course-text-bottom-wrapper {
    margin-top: 43px;
  }
}



/***********************************/
/* courses detials page */
/*  course info full */
/***********************************/

#card-detials-course-info {
  background-color: #FFFFFF;
}

.course-detials-course-info-wrapper {
  /* width: 100%; */
  max-width: 532px;
}

.course-detials-course-info-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-detials-course-info-image-section {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
}

.course-detials-course-info-image-two-section {
  display: flex;
  justify-content: space-between;
}

.course-detials-course-info h2 {
  color: #6D1D8E;
  font: 700 35px 'Mulish';
  margin-top: 20px
}

.course-detials-course-info-subheading {
  color: #515151;
  font: 700 20px 'Mulish';
}

.course-detials-course-info-paragraph,
.course-detials-course-info-paragraph-two {
  color: #515151;
  font: 400 15px "Mulish";
}

.course-detials-course-info-paragraph-two {
  margin-top: 20px;
}

.course-detials-course-content-wrapper {
  padding: 60px 0px;
  padding-bottom: 0%;
}

.course-detials-course-info-paragraph-subject {
  padding-top: 20px;
  display: block;
}

.course-detials-course-info-paragraph-subject.thick {
  font-weight: 700;
}

/* .course-detials-course-info-paragraph-subject-image-one{
 height:696px ; 
  height: auto;
  width: 464px; 
}
.course-detials-course-info-paragraph-subject-image-two{
  /* height: 703px; 
  height: auto;
  width: 100%;
}*/

/*
  courses detials page  media queries
******************************************/
@media (max-width:768px) {
  .course-detials-course-info-heading {
    font: 700 20px 'Kaisei HarunoUmi';
  }

  .course-detials-course-info-subheading {
    font: 700 13px 'Mulish';
  }

  .course-detials-course-info-paragraph,
  .course-detials-course-info-paragraph-two {
    font: 400 12px 'Mulish';
  }

  .course-detials-course-info-row-two-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .course-detials-course-info-paragraph-subject {
    font: 400 15px 'Mulish';
    padding-top: 0%;
    margin-bottom: 20px;
  }

  .course-detials-course-info-paragraph-subject.thick {
    font: 700 15px 'Mulish';
  }

  .course-detials-course-content-wrapper {
    width: 90%;
    padding-top: 20px;
  }
}


/********************************************/
/* courses detials page */
/* course Admission Process */
/********************************************/

#course-detials-admission-section {
  background-color: #FBFBFB;
}

.course-detials-admission-heading {
  color: #6D1D8E;
  font: 700 55px 'Kaisei HarunoUmi';
  margin-top: 47px;
}

.course-detials-admission-subheading {
  color: #515151;
  font: 700 25px 'Mulish';
}

/* cards */
.course-detials-admission-card-section {
  margin-top: 70px;
  margin-bottom: 70px;
}

.card.course-detials-admission {
  border: 1px solid #6D1D8E;
  border-radius: 12px;
  height: 300px;
}

.training-program-card {
  height: 600px;
}

.card-title.course-detials-admission {
  color: #6D1D8E;
  font: 700 65px "Kaisei HarunoUmi";
  padding: 12px 0px;
  margin: 0px;
}

.card-subtitle.course-detials-admission-title-one,
.card-subtitle.course-detials-admission-title-two,
.card-subtitle.course-detials-admission-title-three {
  color: #515151;
  font: 800 25px "Mulish";
  padding-bottom: 12px;
}

.card-subtitle.course-detials-admission-title-three {
  width: 100%;
  max-width: 200px;
}

.card-text.course-detials-admission-detials-one,
.card-text.course-detials-admission-detials-two,
.card-text.course-detials-admission-detials-three {
  color: #515151;
  font: 400 15px 'Mulish';
  max-width: 305px;
  margin-bottom: 10px;
}


.card-subtitle.course-detials-admission-title-one {
  display: block;
  width: 100%;
  max-width: 220px;
}

@media(max-width:767px) {
  .card.course-detials-admission {
    margin: 30px 0px !important;
    width: 215px !important;
  }

  .course-detials-admission-card-section {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .course-detials-admission-heading,
  .course-detials-admission-subheading {
    text-align: left;
  }

  .course-detials-admission-heading {
    margin-top: 40px;
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .course-detials-admission-subheading {
    font: 700 13px 'Mulish';
  }

  .course-detials-admission-card-section {
    margin: 28px auto;
    overflow-y: hidden;
  }

  .card-title.course-detials-admission {
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .card-subtitle.course-detials-admission-title-one,
  .card-subtitle.course-detials-admission-title-two,
  .card-subtitle.course-detials-admission-title-three {
    font: 800 15px 'Mulish';
  }

  .card-text.course-detials-admission-detials-one,
  .card-text.course-detials-admission-detials-two,
  .card-text.course-detials-admission-detials-three {
    font: 400 12px 'Mulish';
  }

}


/*********************************************/
/* courses detials page */
/* course-detials-why-you-prefer-us */
/*********************************************/

#course-detials-prefer-us {
  background-image: linear-gradient(#46135BD4, #46135BD4), url(../Assets/images/courses-detials/pexels-fauxels-3182768.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 0%;
  padding-bottom: 55px;
  margin: 0%;
}

.course-detials-prefer-courses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.course-detials-prefer-us {
  font: 400 50px 'Kaisei HarunoUmi';
  color: #FFFFFF;
  margin-top: 91px;
  margin-bottom: 84px;
  text-align: center;
}

.course-detials-prefer-name {
  color: #fff;
}

.course-detials-prefer-name {
  font: 400 20px 'Mulish';
}

.course-detials-prefer-count-one,
.course-detials-prefer-count-two,
.course-detials-prefer-count-three,
.course-detials-prefer-count-four {
  margin-top: 12px;
  color: #fff;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 40px;
}

.course-detials-prefer-count-two {
  margin-top: 36px;
}

.course-detials-prefer-count-one {
  margin-top: 44px;
}

.course-detials-prefer-us-images {
  max-width: 100%;
  height: auto;
}

@media(max-width:767px) {
  .course-detials-prefer-us {
    font: 400 30px 'Kaisei HarunoUmi';
    width: 245px;
    text-align: left;
  }

  #course-detials-prefer-us {
    background-image: none;
    background-color: #46135BD4;
  }

  .course-detials-prefer-us {
    margin-left: auto;
    margin-right: auto;
  }

  .course-detials-prefer-courses {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 10px;
    margin: 7px;
  }

  .course-detials-prefer-courses-card-section {
    padding: 0%;
  }

  .course-detials-prefer-us-images.one {
    width: 70px;
    height: 58px;
    margin-top: 32px;
  }

  .course-detials-prefer-us-images.two {
    width: 70px;
    height: 69px;
    margin-top: 30px;
  }

  .course-detials-prefer-us-images.three {
    width: 58px;
    height: 80px;
    margin-top: 23px;
  }

  .course-detials-prefer-us-images.four {
    width: 78px;
    height: 81px;
    margin-top: 25px;
  }

  .course-detials-prefer-count-one {
    margin-top: 18px;
    font: 800 39px 'Mulish';
  }

  .course-detials-prefer-count-two {
    margin-top: 12px;
    font: 800 37px 'Mulish';
  }

  .course-detials-prefer-count-three {
    margin-top: 8px;
    font: 800 37px 'Mulish';
  }

  .course-detials-prefer-count-four {
    margin-top: 7px;
    font: 800 36px 'Mulish';
  }

  .course-detials-prefer-name {
    font: 400 13px 'Mulish';
  }
}


/************************************************/
/* courses detials page */
/* placements section */
/************************************************/
#course-detials-placement {
  padding-bottom: 83px;
}

.course-detials-placement-heading {
  color: #6D1D8E;
  font: 700 55px 'Mulish';
  margin-top: 45px;
}

.course-detials-placement-subheading {
  color: #515151;
  font: 700 25px 'Mulish';
}

.course-detials-placements-profile-name {

  color: #515151;
  font: 700 15px 'Mulish';
  margin-top: 35px;
}

.course-detials-placements-profile-qualification {
  font-weight: 400;
  display: block;
}

.course-detials-placement-profile {
  margin-top: 67px;
}


/****************************************/
/* courses detials page */
/* course detials join now section */
/****************************************/

.course-detials-join-now-heading {
  margin-top: 134px;
  font: 400 40px 'Kaisei HarunoUmi';
  color: #F5F5F5;
}

/****************************************/
/* courses detials page */
/* footer */
/****************************************/
/* courses */

.course-detials-course {
  font: 700 18px 'Kaisei HarunoUmi';
  color: #6D1D8E;
}

.course-detials.about-our-services {
  font: 700 18px 'Kaisei HarunoUmi';
}

.course-detials.about-our-services a {
  margin-bottom: 20px;
}

/****************************************/
/* placement page */
/* header */
/****************************************/

#placement-header {
  margin-top: 74px;
  background-color: #F9F9F9;
}

.placement-body {
  margin-left: auto;
  margin-right: auto;
}

/* color-section  color:#6D1D8E ; */
.placement-header-heading,
.placement-header-subheading,
.placement-for-students-heading,
.placement-for-students-our-student-working,
.placement-for-student-student-name {
  color: #6D1D8E;
}

/* color-section  color:#515151 ; */
.placement-header-paragraph,
.placement-for-students-paragraph,
.placement-for-student-student-company-name {
  color: #515151;
}

/*  font: 400 15px 'Mulish'; */
.placement-header-paragraph,
.placement-for-students-paragraph {
  display: block;
  font: 400 15px 'Mulish';
}

/* display block align center */
.placement-for-students-subheading,
.placement-for-students-placement-rate-heading,
.placement-for-students-placement-rate-button,
.placement-for-students-our-student-working-paragraph {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* same font 700 55px 'Kaisei HarunoUmi'; */
.placement-for-students-heading,
.placement-for-students-our-student-working {
  font: 700 55px 'Kaisei HarunoUmi';
}

/* font: 700 20px 'Mulish'; */
.placement-for-students-subheading,
.placement-for-students-our-student-working-paragraph {
  font: 700 20px 'Mulish';
}

.placement-header-heading {
  margin-top: 155px;
  font: 700 65px 'Kaisei HarunoUmi';
}

.placement-header-subheading {
  font: 700 25px 'Mulish';
}

.placement-header-paragraph {
  margin-top: 45px;
  max-width: 455px;
}

.placement-header-image {
  width: 601px;
  height: auto;
}

.placement-for-students-subheading {
  max-width: 582px;
  margin-top: 13px;
}

.placement-for-students-paragraph {
  margin: 0;
  margin-top: 60px;
}

#placement-for-students-card-section {
  padding-top: 0%;
}

.placement-for-students-placement-rate-heading {
  font: 800 20px 'Mulish';
  margin-bottom: 60px;
}

.placement-for-students-placement-rate-button {
  display: inline-block;
  width: 194px;
  height: 58px;
  border-radius: 50px;
  background-color: #fff;
  color: #6D1D8E;
  border: 2px solid #6D1D8E;
  margin: 85px auto;
}

.placement-for-students-our-student-working-paragraph {
  max-width: 582px;
  margin-top: 13px;
}

/*****placement page*****/
/*********header and body*********/
/* media queries */
@media(max-width:768px) {

  /* #placement-for-students{ */
  /* margin: 0% 2px; */
  /* } */
  .placement-header-image {
    width: 100%;
    height: auto;
  }

  .placement-header-heading {
    margin-top: 46px;
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .placement-header-subheading {
    font: 700 13px 'Mulish';
    margin-top: 4px;
  }

  .placement-header-paragraph {
    font: 400 12px 'Mulish';
    margin-top: 25px;
    max-width: 300px;
  }

  .placement-for-students-heading {
    margin-top: 13px;
    font: 700 30px 'Kaisei HarunoUmi';
  }

  .placement-for-students-subheading {
    margin: 16px 11px;
    margin-bottom: 0%;
    font: 700 13px 'Mulish';
  }

  .placement-for-students-paragraph {
    margin: 0% 18px;
    margin-top: 21px;
    font: 400 12px 'Mulish';
  }

  .placement-for-students-placement-rate-heading {
    font: 800 13px 'Mulish';
    line-height: 2;
  }
}


/* placement-for-students */
/*******card section********/

.placement-for-student-card-text-section {
  flex: 1;
}

.placement-for-student-card-section {
  padding: 0%;
  margin: 0%;
  /* margin: 21px; */
}

.placement-for-student-card {
  background-color: #EEEEEE;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 182px;
  margin: 20px;

}

.placement-for-student-card-image {
  width: 181px;
  height: auto;
}

.placement-for-student-card:hover {
  background-color: #6D1D8E;
  border: none;
}

.placement-for-student-card:hover .placement-for-student-student-name {
  color: #FFFFFF;
}

.placement-for-student-card:hover .placement-for-student-student-job-field {
  color: #FFFFFF
}

.placement-for-student-card:hover .placement-for-student-student-company-name {
  color: #FFFFFF
}

.placement-for-student-student-name {
  font: 700 23px 'Barlow';
}

.placement-for-student-student-job-field {
  font: 400 15px "Mulish";
  color: #727272;
}

.placement-for-student-student-company-name {
  font: 400 19px 'Mulish';
}

.placement-for-student-card-text-wrapper {
  margin: 20px 0;
  margin-left: 47px;
}

.placement-for-student-student-job-field {
  margin-top: 8px;
}

.placement-for-student-student-company-name {
  margin-top: 37px;
}



/*****placement page*****/
/*********card section*********/
/* media queries */
@media(max-width:769px) {
  .placement-for-student-card {
    width: 100wh;
    height: auto;
  }

  .placement-for-student-card-image {
    width: 104px;
    height: 100%;
  }

  .placement-for-student-student-name,
  .placement-for-student-student-job-field,
  .placement-for-student-student-company-name {
    margin: 0;
  }

  .placement-for-student-student-name {
    font: 700 15px 'Barlow';
    line-height: 1.5;
  }

  .placement-for-student-student-job-field,
  .placement-for-student-student-company-name {
    font: 400 12px 'Mulish';
  }

  .placement-for-student-student-company-name {
    margin-top: 16px;
  }

  .placement-for-students-placement-rate-button {
    width: 159px;
    height: 46px;
    margin-top: 50px;
    margin-bottom: 0%;
  }

  .placement-for-students-our-student-working {
    font: 700 30px 'Kaisei HarunoUmi';
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
  }

  .placement-for-students-our-student-working-paragraph {
    font: 700 13px 'Mulish';
  }

  .placement-for-student-card-text-wrapper {
    margin: 0%;
    margin-left: 26px;
    margin-top: 14px;
  }

  /* .placement-for-student-card-text-section{ */
  /* display: flex; */
  /* } */
}

/* placement-for-students-our-student-working-on */

.placement-for-students-our-student-working-on {
  margin-top: 66px;

}

.placement-student-working-on {
  margin: 96px auto;
}

@media(max-width:769px) {
  .placement-for-students-our-student-working-on {
    margin-top: 63px;
  }

  .placement-for-students-our-student-working-paragraph {
    margin-top: 21px;
    margin-bottom: 35px;
    max-width: 270px;
  }

  .placement-student-working-on {
    width: 100%;
    height: auto;
    margin-top: 40px;
    margin-bottom: auto;
  }
}

/********************************************/
/*placement for student  modal section*/
/********************************************/
/* modal text need to be edit */

.modal {
  background-color: rgb(109 29 142 / 80%);

}

.modal-content {
  width: 762px;
  box-shadow: 0px 12px 35px #00000073;
  border-radius: 20px;
}

.modal-brand-image-logo {
  width: 269px;
  height: auto;
}

.modal-greeting-heading {
  font-size: 70px;
  color: #6D1D8E;
}

.modal-student-name {
  font: 800 30px 'Mulish';
  color: #505050;
}

.modal-brand-image-logo,
.modal-profile-image {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.modal-greeting-heading,
.modal-student-name {
  text-align: center;
}

.modal-placed-paragraph {
  color: #9C9C9C;
  font: 400 20px 'Mulish';
}

.modal-placed-in {
  color: #6D1D8E;
}

.modal-placed-as {
  color: #9C9C9C;
}

.modal-placement-student-detials {
  background-color: #F9F9F9;
  margin-left: 62px;
  margin-right: 62px;
}

.modal-student-data {
  display: block;
}

.modal-student-criteria {
  padding-left: 35px;
}

/* for adding padding to top */
.modal-student-criteria.one {
  padding-top: 35px;
}

.modal-student-data.last {
  padding-bottom: 40px;
}

.modal-student-footer {
  color: #5F078A;
  font: 800 20px 'Mulish';
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 762px;
  }

}

/* nodal media quries */
@media (max-width:576px) {
  .modal-content {
    margin: auto 16px;
  }

  .course-detials-course-about-certificate-subheading-wrapper {
    position: relative;
    top: 44rem !important;
  }

  .course-detials-admission-subheading {
    font: 700 17px "Mulish" !important;
  }

  .course-detilas-module-table-heading {
    text-align: left;
    font: 700 30px "Kaisei HarunoUmi" !important;
  }

  .course-detilas-module-table-subheading {
    text-align: left;
    font: 700 16px "Mulish";
    margin-top: -2rem !important;
  }

  .course-detials-prefer-uss {
    text-align: left;
    color: white;
    margin: 0rem;
    font-family: 'Mulish';
    margin-top: 5rem;
    font-size: 3rem;
    max-width: 30rem;
  }

  .placement-indicator {
    display: flex !important;
    bottom: -4rem !important;
    left: -0.5rem !important;

  }

  .placement-indicator li {
    height: 0.4rem !important;
  }

  .course-detials-prefer-us-images-three {
    max-width: 100%;
    height: auto;
    width: 25%;
    padding-top: 1rem;
  }

  .modal-body {
    padding: auto 0%;
  }

  .modal-brand-image-logo {
    width: 116px;
    height: 28px;
  }

  .modal-greeting-heading {
    font-size: 29px;
  }

  .modal-student-name {
    font: 800 15px 'Mulish';
  }

  .modal-profile-image {
    width: 155px;
    height: auto;
  }

  .modal-placed-paragraph {
    font: 800 12px 'Mulish';
    color: #515151;
  }

  .modal-placed-in {
    font: 700 15px 'Mulish';
    color: #6D1D8E;
  }

  .modal-placed-as {
    font: 400 12px 'Mulish';
    color: #9C9C9C;
  }

  .modal-placement-student-detials {
    margin: 0 33px;
    margin-top: 11px;
  }

  .modal-student-criteria {
    padding-left: 21px;
  }

  .modal-student-criteria.one {
    padding-top: 20px;
  }

  .modal-student-data.last {
    padding-bottom: 27px;
  }

  .modal-student-criteria {
    font: 800 12px 'Mulish';
  }

  .modal-student-data {
    color: #717171;
    font: 400 12px 'Mulish';
  }

  .modal-student-footer {
    font: 800 12px "Mulish";
  }
}

/* width */
.course-module-lessons-content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(176, 175, 175);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6D1D8E;
  border-radius: 10px;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6D1D8E;
}

.x {
  font-size: 34px;
  margin: 10px;

}

@media (max-width:768px) {


  #main {
    margin: 50px 0;
    display: block;
  }

  #main #faq .card {
    margin-bottom: 30px;
    border: 0;
  }

  #main #faq .card .card-header {
    border: 0;
    -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    border-radius: 2px;
    padding: 0;

  }



  #main #faq .card .card-header .btn-header-link {
    color: #fff;
    display: block;
    text-align: left;
    background: white;
    color: #6D1D8E;
    padding: 20px;
    font: 700 20px 'Mulish';
    border-bottom: 3px solid #6D1D8E;


  }

  #main #faq .card .card-header .btn-header-link:after {
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: right;
  }

  #main #faq .card .card-header .btn-header-link.collapsed {
    background: white;
    color: #6D1D8E;
    font: 700 20px 'Mulish';
    border-bottom: 3px solid rgb(205, 204, 204);
  }

  #main #faq .card .card-header .btn-header-link.collapsed:after {
    content: "\f106";
    color: rgb(205, 204, 204);
  }

  #main #faq .card .collapsing {
    background: white;
    line-height: 16px;
    text-align: justify;
  }

  #main #faq .card .collapse {
    border: 0;
  }

  #main #faq .card .collapse.show {
    background: white;
    line-height: 16px;
    color: black;
    text-align: justify;


  }

  .course-module-name-sm {
    color: #6D1D8E;
    font-size: 23px;
    font-family: 'Mulish';
    font-weight: bold;
  }

  .course-module-one-para {
    font-size: 15px;
    font-family: 'Mulish';
  }

  .course-module-one-sm {

    font-size: 15px;
    font-family: 'Mulish';
    font-weight: bold;
  }
}

@media (min-width:768px) {


  #main {

    display: none;
  }
}

@media (min-width:767px) {
  .carousel-slider-coursedetailspage {

    display: none;
  }
}

.card-01 {
  border: 1px solid #6D1D8E;
  border-radius: 12px;
  height: 230px;
  width: 230px;
  margin-left: auto;
  margin-right: auto;



}

.card-text.course-detials-admission-detials-para {
  color: #515151;
  font: 400 12px "Mulish";
  max-width: 304px;
  margin-top: 20px;
}

@media (max-width:992px) {
  .card.course-detials-admission {
    border: 1px solid #6d1d8e;
    border-radius: 8px;
    height: 267px !important;
  }

  .course-detials-admission-card-section {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .course-detials-admission-heading {
    margin-top: 40px;
    font: 700 30px "Mulish";
  }

  .course-detials-admission-subheading {
    font: 600 17px "Mulish";
  }


  .course-detials-course-certificate-detials {
    margin-bottom: 20px;
  }

  .course-badge {
    height: 13rem !important;
  }

  .course-detials-course-certificate-detials {
    margin-top: 0px !important;
  }

  .course-detials-header-text {
    margin-top: 0rem !important;
  }

  .course-detials-header-button-wrapper {
    display: flex;
  }

  .course-detials-course-about-heading {
    margin-top: 17px;
    color: #6d1d8e;
    font: 500 55px;
    max-width: 62rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-header {
  border-bottom: 0px !important;
}

.modal-footer {
  border-top: 0px !important;
}

.course-detials-course-info-paragraph-subject-image-one,
.course-detials-course-info-paragraph-subject-image-two {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-detials-course-info-image-one {
  width: 70rem;
  height: 40rem;
}

.course-detials-course-info-image {
  width: 70rem;
  height: 43rem;
}

.course-badge {
  width: 100px;
  height: 148px;

}

.placement-image {
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  width: 164px;
  object-position: top;
}

.placement-image-section {
  width: 169px;
  height: 169px;
  padding-top: 1rem;
  padding-right: 17rem;
}

/* module accord  */


.accordion-item {
  border: 0;
  box-shadow: 0 0 20px 0 rgb(213 213 213 / 50%);
  border-radius: 2px;
  padding: 0;
}

.accordion-collapse.collapse {
  color: black;
  text-align: justify;
}

.accordion-body {
  color: black;
  text-align: justify;
}

.accordion-button {
  background: none !important;
  font-size: 15px;
  display: block;
  text-align: left;
  background: white;
  color: #6D1D8E;
  padding: 20px;
  font: 700 20px 'Mulish';
  border-bottom: 3px solid #6D1D8E;
  width: 100%;
}

.accordion-button.collapsed {
  border-bottom: 3px solid #9d9c9d;
}

.accordion-body {
  padding: 1.25rem;
}

.accordion-button.collapsed::after {
  content: "\f107";
  color: #333;
  float: right;
  font-family: "FontAwesome"
}

.accordion-button[aria-expanded="true"]::after {
  content: "\f106";
  color: #6D1D8E;
  font-family: "FontAwesome";
  float: right;
}

.extra-content p {
  color: black;
  font: 400 15px "Mulish";
  margin-top: 3rem;

}

@media (max-width:576px) {
  .large-device-course-details-content {
    display: none;


  }

  .small-device-course-details-content {
    margin-top: 4rem;
  }
}

@media (min-width:576px) {

  .large-device-course-details-content {
    display: block;
  }

  .small-device-course-details-content {
    display: none;

  }
}

.course-detials-content-two h2 {
  color: #6D1D8E;
  font: 700 35px 'Kaisei HarunoUmi';
  margin-top: 20px;
}

.course-detials-content-one h2 {
  color: #6D1D8E;
  font: 700 35px 'Kaisei HarunoUmi';
  margin-top: 20px;
}

.course-detials-content-one,
.course-detials-content-two h3 {
  color: #6D1D8E;
  font: 700 20px 'Mulish';
}

.course-detials-content-one p {
  color: black;
  font: 400 15px "Mulish";

}

.course-detials-content-two p {
  color: black;
  font: 400 15px "Mulish";
}

.course-detials-image-two-section {
  width: 70rem;
  height: 42rem;

}

.course-detials-image-one-section {
  width: 70rem;
  height: 38rem;
}

.course-detials-image-two,
.course-detials-image-one {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-detials-section-two {
  margin-top: 3rem;
}

@media (max-width:576px) {
  .course-detials-section-one {
    margin-top: 3rem;
  }

  .course-detials-content-one h2 {
    position: relative;
    top: -42rem;
    font: 700 2rem "Kaisei HarunoUmi";
  }

  .course-detials-image-one {
    margin-top: 5rem;
  }

  .course-detials-content-two h2 {
    font: 700 2rem "Kaisei HarunoUmi";
  }

  .course-detials-header-text h2 {
    font-size: 1.3rem !important;
    margin-top: -2rem !important;

  }
}

@media all and (device-width: 1366px) and (device-height: 768px) {
  .course-detials-certified-courses-background-image-left {
    position: absolute;
    bottom: 138px;
    left: 7px !important;
    width: 100%;
    max-width: 476px !important;

  }

  .course-detials-certified-courses-background-image-right {
    position: absolute;
    bottom: 133px;
    right: 12px !important;
    width: 100%;
    max-width: 491px !important;
  }
}

.nextBatch {
  flex-direction: column;
  display: flex;
}

.sub-date {
  color: #460b5e;
  font-size: 10px;
  font-weight: 600;
}

.home-sub-date {
  color: #515151;
  font-size: 12px;
  font-weight: 600;
}