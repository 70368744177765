.privacypolicy{
    margin: 1.5rem;
}
.privacypolicy h4{
    color: #6d1d8e;
    font-size: 18px;
}
.privacypolicy p{
    font: 500 15px 'Mulish';
    text-align: justify;
}
.privacypolicy a{
    color:#6d1d8e ;
}
