.scroll-btn{
    position: fixed; 
   width: 56px;
   right: 38px;
   bottom: 100px;
   height: 56px;
   font-size: 3rem;
   z-index: 999;
   cursor: pointer;
   color: white;
   background-color: #3c1062;;
   border-radius: 50%;

}