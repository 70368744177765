   
*,
*::after,
*::before {
  margin: 0;
  padding: 0%;
  border: 0;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 10px;
}
.modal-open {
  overflow: hidden;
  padding: 0 !important;
}
body {
  overflow-x: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

section {
  padding-top: 6rem;
  padding-bottom: 6rem;
  margin: 0;
  overflow: hidden;
}
a:hover {
  text-decoration: none;
}

::placeholder {
  color: #c5c5c5 ;
}

@media (max-width: 768px) {
  section {
    padding: 2rem 0;
    margin: 0%;
    margin-left: auto;
    margin-right: auto;
  }
}
a:hover {
  color: #6d1d8e;
}

p {
  color: #515151;
  size: 1.6rem;
}
h2 {
  font-size: 5.5rem;
  color: #6d1d8e;
}
h6 {
  font-size: 2rem;
}

.card {
  border: 0;
}
.color-blue{
  color:#6d1d8e !important;
}
.color-white{
  color:#d8d8d8 !important;
}

/* buttons */

button.btn-light {
  color: #6d1d8e;
  padding: 0.9 2.7rem;
  border-radius: 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  float: left;
}

button.small-white-button {
  margin-top: 2.5rem;
  color: #6d1d8e;
  background-color: #fff;
  padding: 0.5rem 2rem;
  border: 0.2rem solid #6d1d8e;
  border-radius: 30px;
  font: 800 1.8rem "Mulish";
}
.small-white-button.two {
  display: none;
}

@media (max-width: 768px) {
  button.small-white-button {
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 1.2rem 5.4rem;
    font-size: 1.8rem;
    margin-top: 3.5rem;
    top: 36rem;
  }
  .mission-image-overlay-parent {
    position: relative;
    z-index: 2;
    display: flex;
    top: -14rem;
    margin-top: 6rem !important;
  }
}

button.view-all-button-white {
  margin-top: 1.5rem;
  color: #6d1d8e;
  background: #fff;
  width: 14rem;
  padding: 0.8rem 2rem;
  border-radius: 3rem;
  font-size: 1.8rem;
  float: left;
  font-weight: bold;
  font-family: 'Mulish';
}

/* nav */


/* carousel */
.home-carousel-header-image-section{
  width:68.3rem;
  height: 53.3rem;
}
.home-carousel-item-body {
  margin-top: 0.4rem;
  background-color: #f9f9f9;
}
.home-carousel-component-info {
  padding-left: 0%;
  padding-right: 0%;
  margin-top: -5rem;
}


.home-corousel-content h1{
  text-align: left;
  white-space: nowrap;
  color: #6d1d8e;
  margin-top: 11.2rem;
  font: 700 6.5rem 'Mulish';
}

.home-corousel-content h3 {
  text-align: left;
  font: 700 2.5rem "Mulish";
  margin-top: -3rem;
  color: #6d1d8e;
}
.home-corousel-content h2 {
  text-align: left;
  font: 700 1.5rem "Mulish";
  margin-top: -1rem;
  color: #6d1d8e;
}
.home-carousel-header-image-small {
  display: none;
}

.home-corousel-content p {
  color: black;

  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;  text-align: left;
  margin: 3.5rem 0;
  display: block;
  max-width: 45.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.home-carousel-certificates {
  display: flex;
  max-width: 30rem;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0rem 0;
}

.home-carousel-one-certificate {
  display: block;
  width: 151px;
  height: 67px;
}
.vertical-dash {
  margin: 0% 2.2rem;
  font-size: 4rem;
  color: #6d1d8ecc;
}

.view-course {
  background-color: #6d1d8e;
  color: #fff;
  font: 800 1.5rem "Mulish";
  border-radius: 3rem;
  width: 15rem;
  margin: 4.9rem 0;
  padding: 1.2rem 1rem;
}

.home-carousel-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-indicators {
  bottom: 6rem;
  left: -101.5rem;
}
.carousel-indicators li {
  height: 0.8rem;
  margin: 0.3rem;
  /* border-radius: 12px; */
  background-clip: border-box;
  border-radius: 1rem;
  width: 2.3rem;
  border: 0;
  background: rgb(135, 127, 127);
  opacity: 0.8;
}

.carousel-indicators .active {
  background-color: #6d1d8e;
}

.home-registration-content-section {
  background-color: #f9f9f9;
}
.home-registration-contents {
  margin-bottom: 5rem;
  height: 5rem;
  width: 700px;
  border-radius: 41px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}


@media (min-width: 768px) and (max-width: 1024px) {
  .home-carousel-header-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
}

@media (max-width:768px) {
  .carousel-indicators {
    bottom: 2rem;
    left: 0rem;
    display: none;
  }
  .home-corousel-content h2 {
    text-align: left;
    font: 700 2.5rem "Mulish";
    margin-top: -2.5rem;
    color: #6d1d8e;
  }
}
@media (min-width:769px) {
  .carousel-indicators {
    bottom: 0rem;
    left: -55rem;
  }
}
@media (min-width:1200px) {
  .carousel-indicators {
    bottom: 2rem;
    right: 47rem;
  }
}
@media (min-width:992px) and (max-width:1199px) {
  .carousel-indicators {
    bottom: 0rem;
    right: 25rem;
  }
}
@media (max-width: 767px) {
  .home-carousel-heading,
  .home-carousel-subheading,
  .home-carousel-about,
  .home-carousel-certificates,
  .view-course {
    margin-left: 0;
  }
  .home-carousel-component-info {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .home-corousel-content h1{
    font: 700 3rem "Kaisei HarunoUmi";
    margin-top: 8.2rem;
  }
  
.home-corousel-content h3 {
  text-align: left;
  font: 700 1.5rem "Mulish";
  margin-top: -1.5rem;
  color: #6d1d8e;
}
  .home-carousel-about {
    font: 400 1.2rem "Mulish";
    display: block;
    max-width: 30rem;
    margin: 0%;
    order: 1;
    margin-bottom: 2.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .home-carousel-header-image-section {
    display: none;
  }
  .home-carousel-header-image-small {
    display: flex;
    margin-bottom: 1.3rem;
    position:relative;
    top: 18rem;
  }
  .home-carousel-certificates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    order: 2;
    margin: 0%;
    margin-left: 3rem;
  }
  .view-course {
    font: 800 1.2rem "mulish";
    padding: 1.1rem 3.8rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
    order: 3;
    margin-top: 4rem;
    margin-bottom: 5rem;
    width: 16rem;
  }
  /* .home-carousel-header-image {
    width: 36.4rem;
    height: auto;
    margin: 0%;
    display: block;
    margin-right: auto;
    margin-left: auto;
  } */
  .home-registration-content-section {
    background-color: #fff;
    margin-top: 3.4rem;
  }

  .home-carousel-one-certificate.image-one {
    width: 9.8rem;
    height: 4.4rem;
  }
  .home-carousel-one-certificate.image-two {
    width: 3.9rem;
    height: 3.9rem;
  }
  .vertical-dash {
    margin: 0% 0.8rem;
    font-size: 3rem;
  }
  .home-registration-contents {
    margin-bottom: 5rem;
    height: 29px;
    width: 328px;
    margin-top: -2rem;
    position: absolute;
  }
  .register-with {
    position: relative;
    left: 7rem;
    top: -5.5rem;
    font-size: 1rem;
    color: white;
    opacity: 0.5;
    white-space: nowrap;
}
.register-cnu {
  position: relative;
  right: -21rem;
  top: -5.5rem;
  font-size: 0.8rem;
  color: white;
  opacity: 0.5;
  white-space: nowrap;
}
}

@media   (max-width: 767px) {
  .home-carousel-component-info {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .home-carousel-header-image-section {
    display: block;
  }
}
/* @media (max-width: 768px){
.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-md-6 {
  width: 100% !important;
} */
/* } */

/****************************************/
/* home carousel second */
/****************************************/

#home-technology-stack {
  background-color: #f9f9f9;
  padding-bottom: 8rem;
}
.home-technology-stack-carousel-row {
  padding-bottom: 2rem;
}
.home-carousel-two-image-section-one {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
  margin-left: 4rem;
  margin-top: 2rem;
}

.home-carousel-two-image-section-two {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 4rem;
  margin-top: -3rem;
}

.home-carousel-two-image-one,
.home-carousel-two-image-two {
  width: 23.8rem;
  height: auto;
  z-index: 3;
}

.home-carousel-two-image-section {
  position: relative;
  margin-top: 83px;
  margin-right: -6rem;
}

.home-carousel-two-white-box {
  position: absolute;
  background-color: #ffffff;
  width: 30.8rem;
  height: 30.8rem;
  border-radius: 1rem;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0rem 0.3rem 3.5rem #0000001a;
}

.home-carousel-two-body-heading {
  font: 700 5.5rem "Kaisei HarunoUmi";
  color: #6d1d8e;
  margin-top: 9.2rem;
}
.home-carousel-two-body-detials {
  font: 400 1.5rem "Mulish";
  color: #515151;
  max-width: 44.1rem;
  margin-bottom: 6rem;
  margin-top: -3rem;
}

.home-carousel-two-body-stacks {
  display: flex;
  margin-bottom: 3rem;
}

.home-carousel-two-body-technology-stacks {
  width: 12.9rem;
  height: 12.9rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  box-shadow: 0rem 0.3rem 1rem #00000029;
  border-radius: 1rem;
  margin-right: 3rem;
}

.home-carousel-two-body-technology-stacks-stack-image {
  width: 11rem;
  height: 6rem;
}

/* home-technology-stack-carousel-indicators-two */

#home-technology-stack-carouselExampleIndicators li {
  background-color: #d4d4d4;
}

#home-technology-stack-carouselExampleIndicators li.active {
  background-color: #6d1d8e;
}

.home-technology-stack-carousel-indicators-two.carousel-indicators {
  right: 1rem;
  bottom: -2rem;
  display: flex;
  justify-content: end;
}
.home-carousel-two-text-section {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .home-carousel-two-body-technology-stacks.row-one-last,
  .home-carousel-two-body-technology-stacks.row-two-last {
    display: none;
  }
}

@media (max-width: 768px) {
  .home-carousel-two-image-section {
    display: none;
  }
  .home-carousel-two-body-heading {
    font: 700 3rem 'Mulish';
    color: #6d1d8e;
    margin-top: 2.8rem;
    margin-bottom: 4.1rem;
  }
  .home-carousel-two-body-detials {
    font: 400 1.2rem "Mulish";
    color: #515151;
    display: block;
    width: 30.6rem;
    margin-bottom: 3rem;
  }

  .home-carousel-two-body-technology-stacks {
    width: 7rem;
    height: 7rem;
  }
  .home-carousel-two-body-technology-stacks-stack-image.docker-image {
    width: 6.1rem;
    height: 2.18rem;

  }
  .home-carousel-two-body-technology-stacks-stack-image.github-image {
    width: 8.1rem;
    height: 5.18rem;
  }
  .home-carousel-two-body-technology-stacks-stack-image.python-image {
    width: 6.5rem;
    height: 3.5rem;
  }
  .home-carousel-two-body-technology-stacks-stack-image.devops-image {
    width: 4.1rem;
    height: 2rem;
  }
  .home-carousel-two-body-technology-stacks-stack-image.jquery-image {
    width: 6rem;
    height: 4.2rem;
  }
  .home-carousel-two-body-technology-stacks-stack-image.bootstrap-image {
    width: 5.8rem;
    height: 4.8rem;

  }
  .home-carousel-two-body-technology-stacks-stack-image.kubernets-image {
    width: 5.8rem;
    height: 4.8rem;

  }

  #home-technology-stack-carouselExampleIndicators li {
    width: 2.6rem;
  }
  #home-technology-stack {
    padding-bottom: 4rem;
    
  }
  .home-technology-stack-carousel-indicators-two.carousel-indicators {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 0;
    display: flex;
    justify-content: center;
    bottom: -16px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .home-carousel-two-body-heading {
    text-align: center;
  }
  .home-carousel-two-body-detials {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .home-carousel-two-body-stacks {
    display: flex;
    justify-content: center;
  }
  .home-carousel-two-body-technology-stacks:nth-child(1) {
    margin-left: 3rem;
  }
}

@media  (max-width: 767px) {
  .home-carousel-two-text-section {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
  }
  .home-carousel-two-body-heading,
  .home-carousel-two-body-detials {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .home-carousel-two-body-stacks {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
  }
  .home-carousel-two-body-technology-stacks {
    margin-right: 0;
  }
}

/**************************************/
/* home section our mission */
/**************************************/

.home-mission-section {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
  background-color: #fff;
}
.home-our-mission-section-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.mission-image-overlay {
  display: none;
  top: 1rem;
  border: 0;
  position: absolute;
  width: 100%;
  height: 258px;
  background: #6d1d8ecc;
  z-index: 5;
  border-radius: 1rem;
  padding-left: 2.5rem;
  padding-top: 10rem;
}

.mission-image-overlay-parent:hover .mission-image-overlay {
  display: flex;
  flex-direction: column;
}

.mission-image-overlay-parent {
  position: relative;
  z-index: 2;
  display: flex;
  height: 258px;
  width: 27rem;
  margin-top: 2rem;

}
.mission-profile-small-device {
  background-image: linear-gradient(180deg, #6d1d8e00, #6d1d8e),
    url(../Assets/images/owjjujsf.png);
  height: 33.9rem;
  width: 24.1rem;
  background-size: cover;
  background-position: center;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}
.mission-detials {
  color: #fff;
}
p.mission-image-name {
  display: block;
  font: 700 2.5rem "Mulish";
  color: #fff;
  margin-top: -6rem;
}

.mission-image-name,
.mission-education-detials {
  margin-bottom: 1.5rem;
}
.mission-placement,
.mission-education-detials {
  line-height: 1.3;
}

.margin-image-role,
.mission-education-detials,
.mission-placement {
  display: block;
  font: 400 1.3rem "Mulish";
  color: #fff;
  opacity: 0.8;
}
span.mission-education-heading {
  font-family: "Mulish";
  font-weight: 700;
}
.margin-image-role {
  font-size: 1.1rem;
  font-size: 700;
  opacity: 0.8;
}
.column-margin {
  margin: 1rem;
}
.image-margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
  object-position: top;
}
.small-square {
  position: relative;
}
.home-our-mission-section-text-section {
  text-align:left;
}
.home-our-mission-section-para {
  color: black;
  /* max-width: 40rem; */
  line-height: 1.2;
  font: 400 2.5rem "Mulish";
}
.home-our-mission-section-highlight-para {
  color: black;
  font-size: 40px;
  line-height: 1.1;
  font-family: "Kaisei HarunoUmi";
  font-weight: bold;
}
.home-our-mission-right-text-normal-para {
  width: 100%;
  max-width: 43rem;
  font-size: 1.5rem;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
}
.mission-image-small-screen {
  display: none;
}
.square-one {
  position: absolute;
  width: 10rem;
  height: 10rem;
  background-color: #6d1d8ecc;
  border-radius: 1rem;
  top: 3.8rem;
  left: 19rem;
  z-index: 1;
}

/* media query section */
@media (max-width: 1200px) {
  .square-one {
    display: none;
  }
}

@media (min-width: 765px) and (max-width: 1024px) {
  .mission-image-overlay-parent:hover .mission-image-overlay {
    padding-left: 0.3rem;
  }

  .mission-image-overlay {
    display: none;
    flex-direction: column;
    justify-content: center;
    height: 16.1rem;
    padding: 0%;
    align-items: flex-start;
  }
  .mission-image-name,
  .margin-image-role,
  .mission-education-detials,
  .mission-placement {
    padding-left: 1rem;
  }
  .margin-image-role {
    padding: 0;
  }
}

@media (max-width: 765px) {
  .home-mission-section {
    margin-top: 0%;
  }
  .home-our-mission-section-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .column-margin {
    display: none;
  }
  .mission-image-small-screen {
    display: flex;
    justify-content: center;
  }
  .mission-image-profile-image-small-screen {
    width: 24.1rem;
    height: 33.9rem;
  }
  .margin-image-role,
  .mission-education-detials,
  .mission-placement {
    text-align: center;
  }
  .mission-image-overlay {
    background: none;
    background-image: linear-gradient(180deg, #6d1d8e00, #6d1d8ecc);
  }

  .mission-image-overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 33.9rem;
    width: 27rem;
    padding: 0%;
  }
  .image-margin {
    margin-left: auto;
    margin-right: auto;
    height: 33.9rem;
    width: 31.5rem;
    
  }
  .small-square > img {
    display: none;
  }
  .home-our-mission-section-text-section {
    padding: 0;
    
  }
  .home-our-mission-section-highlight-para {
    font: 700 3rem "Kaisei HarunoUmi";
    max-width: 26rem;
  }
  .home-our-mission-right-text-normal-para {
    max-width: 33rem;
    margin-bottom: 3rem;

  }

}

@media (max-width: 576px) {
  .home-our-mission-section-para {
    font: 700 1.3rem "Mulish";
  }
  .home-mission-section {
    margin-bottom: 3.1rem;
  }
}

/* job-oriented */

.job-orianted-text-section {
  margin-top: 10.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.job-oriented-heading {
  color: #ffffff;
  font: 700 5.5rem "Mulish";
  margin-top: 8.5rem;
  line-height: 1.7;
}

.job-oriented-subheading {
  display: block;
  font-size: 2.5rem;
  color: #fff;
  font: 400 2.5rem "Mulish";
  margin-bottom: 4rem;
}
.job-oriented-discription-para {
  font: 400 1.5rem "Mulish";
  max-width: 38rem;
  color: #ffffff;
  margin-bottom: 0%;
}

/* job orianted cards */

#job-orianted-section {
  background: #6d1d8e;
  position: relative;
  padding-bottom: 10rem;
}
.job-orianted-section-container {
  padding: 0;
}

.job-oriented-card {
  width: 43rem;
  background: #fff;
  opacity: .7;
  border-radius: 1rem;
  padding: 3.3rem;
  box-shadow: 0 0.3rem 0.6rem #00000040;
  z-index: 1;
}

.heading-row {
  margin-bottom: 2.4rem;
}
.job-orianted-section-column {
  padding: 0%;
}

.card-wrapper.data-science,
.card-wrapper.mearn-stack,
.card-wrapper.python-django {
  float: right;
}
.card-wrapper.data-science {
  padding-left: 1.5rem;
}
.card-wrapper.python-django {
  margin-left: 2.3rem;
}

.job-oriented-card-body {
  max-width: 20.8rem;
  height: 408px;
}

/* .job-oriented-container {
  height: 900px;
} */

/* .job-oriented-card {
  width: 360px;
  background-color: #d8c2e1;
  border-radius: 10px; 
} */

.circle {
  background: #f3d6ff;
  height: 9.8rem;
  width: 9.8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 4.7rem;
}

.job-oriented-card-title {
  font: 700 2rem "Mulish";
  color: #6d1d8e;
  margin-bottom: 2.4rem;
  min-height: 7rem;
}

.job-oriented-card-title.data-science {
  max-width: 21.6rem;
}

.job-oriented-card-text {
  font: 400 1.5rem "Mulish";
  color: #515151;
  margin-bottom: 1rem;
}

.job-oriented-card-text-bold {
  font: 700 1.5rem "Mulish";
  color: #515151;
  white-space: nowrap;  
}
.now-cochine{
  white-space: nowrap;
}


.job-oriented-card-link {
  display: inline-block;
  font: 800 1.8rem "Mulish";
  color: #6d1d8e;
  border-bottom: 0.1rem solid #6d1d8e;
  margin-bottom: 0.9rem;
}

.job-oriented-card-title.python-django {
  max-width: 25.5rem;
}
.job-oriented-card-title.mearn-stack {
  max-width: 25.5rem;
}

/*.job-oriented-container .card .card-detials {
 
/* icons to display */
.icon-1 {
  position: absolute;
  top: -1rem;
  left: 12rem;
  /* z-index: 5; */
  opacity: 0.5;
}

.icon-2 {
  position: absolute;
  left: 7rem;
  bottom: 20rem;
  /* z-index: 5; */
  opacity: 0.5;
}

.icon-3 {
  position: absolute;
  left: 20rem;
  bottom: 0rem;
  /* z-index: 5; */
  opacity: 0.5;
  width: 20%;
}

.job-oriented-card:hover {
  background-color: #fff;
  box-shadow: 0rem 0.3rem 3rem #170120a1;
  opacity: 1;
  transition: 0.5s;
}

/* home card 1  */
 .job-oriented-card:hover .circle-icon {
  filter: brightness(20);
} 
.circle-icon.data-science.hover {
  display: none;
}

.job-oriented-card:hover .circle-icon.data-science {
  display: none;

}

.job-oriented-card:hover .circle-icon.data-science.hover {
  display: flex;
}

/*home  card 2 */

.circle-icon.python-django.hover {
  display: none;
  
}

.job-oriented-card:hover .circle-icon.python-django {
  display: none;
  
}

.job-oriented-card:hover .circle-icon.python-django.hover {
  display: flex;
}

/*home  card 3 */

.circle-icon.mearn-stack.hover {
  display: none;
}

.job-oriented-card:hover .circle-icon.mearn-stack {
  display: none;
 
}

.job-oriented-card:hover .circle-icon.mearn-stack.hover {
  display: flex;
}

.job-oriented-card:hover .circle {
  background-color: #6d1d8e;
} 

/* .job-oriented-card:hover .circle-icon {
  color: #fff;
} */

.job-oriented-card-button-small-device {
  display: none;
}



@media (min-width: 992px) and (max-width: 1200px) {
  .card-wrapper.python-django {
    margin-right: 2.4rem;
  }
}

@media (max-width: 1199px) {
  .icon-1,
  .icon-2,
  .icon-3 {
    /* hides icons on small screens */
    display: none;
  }
}
@media (min-width:1200px)and (max-width:1260px) {
  /* icons to display */
.icon-1 {
  position: absolute;
  top: -2rem;
  left: 12rem;
  /* z-index: 5; */
  opacity: 0.5;
}

.icon-2 {
  position: absolute;
  left: 7rem;
  bottom: 20rem;
  /* z-index: 5; */
  opacity: 0.5;
}

.icon-3 {
  position: absolute;
  left: 18rem !important;
  bottom: 0rem;
  /* z-index: 5; */
  opacity: 0.5;
  width: 20%;
}
}
@media (min-width:2000px){
  /* icons to display */
.icon-1 {
  position: absolute;
  top: -2rem;
  left: 40rem;
  /* z-index: 5; */
  opacity: 0.5;
}

.icon-2 {
  position: absolute;
  left: 29rem;
  bottom: 25rem;
  /* z-index: 5; */
  opacity: 0.5;
  width: 11%;
}


.icon-3 {
  position: absolute;
    left: 41rem !important;
    bottom: 0rem;
    /* z-index: 5; */
    opacity: 0.5;
    width: 19%;
}
}
@media (min-width: 768px) {
  .job-oriented-card-container-small-device {
    display: none !important;
  }
}
@media (max-width: 768px) {
  #job-orianted-section {
    padding-bottom: 0;
  }
  h2.job-oriented-heading {
    font-size: 35px;
    color: #fff;
    margin-top: 3rem;
}
  .job-oriented-subheading {
    font: 700 1.3rem "Mulish";
    margin-bottom: 2.5rem;
    margin-top: 0.5rem;
  
  }
  .job-oriented-discription-para {
    font: 400 1.2rem "Mulish";
    margin-bottom: 3.2rem;
  }
  .job-orianted-section-container {
    padding: 0 1.5rem;
  }
  .job-orianted-section-column {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-wrapper,
  .card-wrapper.python-django,
  .card-wrapper.data-science,
  .card-wrapper.mearn-stack {
    margin: 0% auto;
    display: flex;
    justify-content: center;
    height: 38.3rem;
  }
  .now-cochine{
    white-space: normal;
  }

  .job-oriented-card {
    max-width: 36.6rem;
    margin: 1.5rem;
    padding: 2.4rem;
    width: 24.2rem;

  }
  .circle {
    max-width: 5.5rem;
    height: 5.5rem;
    margin-bottom: 15px;
  }
  .job-oriented-card-title {
    font: 800 1.4rem "Mulish";
    min-height: 5rem;
  }
  .job-oriented-card-text {
    font: 400 1.2rem "Mulish";
  }
  .job-oriented-card-text-bold {
    font: 700 1.2rem "Mulish";
  }
  .job-oriented-card-link {
    font: 800 1.4rem "Mulish";
  }
  .card-wrapper.data-science {
    padding: 0%;
  }
  .job-oriented-card-title.data-science {
    max-width: 15.5rem;
  }
  button.view-all-button-white {
    font: 800 1.2rem "Mulish";
    height: 3.8rem;
    width: 15.6rem !important;
    float: inherit;
    margin-left: auto;
    margin-right: auto;
  
    
  }
  .job-orianted-section-column.large-device {
    display: none;
  }
  .job-oriented-card-container-small-device {
    display: flex;
    margin-bottom: 4rem;
  }
  .job-oriented-card-button-small-device {
    display: flex;
    justify-content: center !;
  }
  .job-oriented-card-container-small-device.button {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .job-oriented-card-title.data-science {
    margin-bottom: 4rem;
  }

  @media (min-width: 360px) and (max-width: 360px) {
    .job-oriented-card-title.mearn-stack {
      margin-bottom: 3.7rem;
    }
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .card-wrapper.mearn-stack {
    margin-top: 2.4rem;
  }
}

/************************************/
/* instagram feeds section */
/************************************/

#home-instagram-feeds {
  background-color: #f9f9f9;
  padding-bottom: 9rem;
}
.home-instagram-feed-heading-section {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.home-instagram-feed-logo {
  max-width: 4.7rem;
    height: 4.7rem;
    margin-right: 2rem;

}

.home-instagram-images {
  border-radius: 1rem;
  width: 38.7rem;
  height: 38.7rem;
  object-fit: cover;
  position: relative;
}
.home-insta-multiple-image {
  position: absolute;
  top: 2.7rem;
  right: 2.3rem;
}
.home-instagram-feed-heading {
  font: 700 4.5rem "Kaisei HarunoUmi";
  color: #6d1d8e;
}
.home-instagram-image-wrapper {
  padding: 0%;
  margin: 0%;
}

.home-instagram-image-galary {
  display: flex;
}

@media (max-width: 768px) {
  .home-instagram-feed-heading {
    font: 700 2.5rem 'Kaisei HarunoUmi';
    margin-bottom: 1rem;
  }

  .home-instagram-feed-heading-section {
    margin: 2rem 0;
  }
}

@media (max-width: 576px) {
  #home-instagram-feeds {
    padding-bottom: 7.4rem;
  }
  .home-instagram-feed-logo {
    max-width: 3.1rem;
    height: 2.5rem;
    margin-right: 1rem;
    margin-top: -5px;
  }

  .home-instagram-images {
    width: 15rem;
    height: 15rem;
    margin: 0.5rem;
    flex: 1;
  }
  .home-instagram-image-wrapper {
    display: flex;
  }
  .home-insta-multiple-image {
    width: 2rem;
    height: auto;
    top: 1.3rem;
    right: 1.5rem;
  }
}

/* logoipusum-image
******************************************/
.logoipusum-image {
  height: 7.8rem;
  width: 22rem;
  filter: grayscale(100%);
  object-fit: contain;
  object-position: top;
}
.logoipusum-image:hover{
  filter: none;
}
@media (max-width: 576px) {
  .logoipusum-image {
    max-width: 9rem;
    height: 5rem;
    padding: 1rem
  }
  .logoipusum-image-col {
    padding-left: 0;
  }
  .placement-slick-head {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    color: #fff;
}
.placement-slick-head img{
  max-width: 80%;
}
.placement-slick-head h1{
  font-size: 2.5rem;
}
}
.placement-slick-head{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6d1d8e;
    height: 15rem;
    color:white;
}
.placement-logos{
  padding: 0;
}
/***********************************/
/* card section */
/* dot image */
/***********************************/

.home-card-logos {
  margin-bottom: 7.4rem;
  margin-top: 1.4rem;
}

.card-section-wrapper {
  margin-top: 3rem;
}
.image-dot-parent {
  position: relative;
}
.image-dot {
  position: absolute;
  right: -9.5rem;
  top: -1.6rem;
  height: 18rem;
  width: 52rem;
}
.heading-about {
  color: #6d1d8e;
  display: block;
  font: 700 5.5rem "Kaisei HarunoUmi";
}

.about-para {
  color: #6d1d8e;
  z-index: 2;
  text-align: left;
  margin-top: 1.5rem;
  margin-bottom: 12rem;
  font: 700 2.5rem "Mulish";
  line-height: 1.3;
}

@media (max-width: 768px) {
  .home-card-logos {
    margin: 0;
  }
  .heading-about {
    font: 700 3rem "Kaisei HarunoUmi";
    display: block;
    margin-bottom: 1rem;
  }
  .about-para {
    font: 700 1.3rem "Mulish";
    margin-bottom: 5rem;
  }
}
@media (max-width: 576px) {
  .image-dot {
    top: 2.2rem;
    width: 25rem;
    height: 9.4rem;
    left: 11rem;
  }
}

/* card teach your education */

.card-title.home-luminar-lab-assurance-card {
  color: #515151;
  font: 700 2.1rem "Mulish";
}
.card-text.discription.home-luminar-lab-assurance-card {
  padding-top: 0;
  font: 400 1.5rem "Mulish";
  color: #515151;
}

.home-card-section-icon {
  padding-top: 3.4rem;
  padding-bottom: 4.7rem;
}

.about-card.home-luminar-lab-assurance-card {
  background-color: #f9f9f9;
  border-radius: 1rem;
  padding: 1.6rem;
}
.about-card.home-luminar-lab-assurance-card:hover {
  background-color: #fff;
  box-shadow: 0rem 0.6rem 2rem #6d1d8e59;
  transition: 1s;
}

.about-card.home-luminar-lab-assurance-card:hover
  .card-title.home-luminar-lab-assurance-card {
  color: #6d1d8e;
}

.card-text-discription.card-text-discription {
  text-align: center;
  padding: 3rem 2rem;
  font: 400 1.5rem "Mulish";
}

.about-card.home-luminar-lab-assurance-card {
  background-color: #f9f9f9;
  overflow: hidden;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .card-text-discription.card-text-discription.three {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .card-title.home-luminar-lab-assurance-card {
    font: 700 1.5rem "Mulish";
    /* padding-bottom: 11px; */
    /* padding-top: 22px; */
  }
  .card-text-discription.home-luminar-lab-assurance-card {
    font: 400 1.2rem "Mulish";
    padding: 1.5rem;
    /* padding-left: 11px;
    padding-right: 11px; */
  }
  .about-card.home-luminar-lab-assurance-card {
    width: 76%;
    padding: 1.6rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  .home-card-section-icn {
    padding-top: 1.1rem;
    padding-bottom: 2.2rem;
  }
  .home-card-section-icon {
    padding-top: 0.3rem;
    padding-bottom: 2.2rem;
  }
}

@media (max-width: 576px) {
  .home-card-section-icon.icon-one {
    width: 3.1rem;
    height: auto;
  }
  .home-card-section-icon.icon-two {
    width: 3.7rem;
    height: auto;
  }
  .home-card-section-icon.icon-three {
    width: 4rem;
    height: auto;
  }
}

/* people say about luminar */

.people-say-about-section {
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding-bottom: 0%;
  background-color: #f9f9f9;
}
.people-say-about-wrapper {
  height: 60rem;
}

.people-say-about-row {
  position: relative;
  margin-top: 10rem;
}
.card.people-say-about-card {
  background-color: #fff;
  color: #515151;
  box-shadow: 0rem 0.6rem 1.5rem #00000029;
  width: 53.9rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  border-radius: 1rem;
  z-index: 1;
}
.home-say-about-luminar {
  margin-top: 2.5rem;
  color: #6d1d8e;
  font: 700 2.5rem "Mulish";
  text-align: left;
}
.home-say-about-luminar-heading {
  font: 700 5.5rem "Kaisei HarunoUmi";
  line-height: 0.9;
 
}

.home-people-say-about-luminar {
  color: #a4a4a4;
  font: 400 1.5rem "Mulish";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */ 
  -webkit-box-orient: vertical;
  min-height: 7.5rem;
}

.people-say.fas.fa-quote-left {
  position: absolute;
  top: 2rem;
  left: 3rem;
  color: #6d1d8e;
  font-size: 3rem;
}

.card-body.people-say-about {
  margin: 1rem;
  padding-top: 14.1rem;
}

.people-say-name {
  color: #6d1d8e;
  font-weight: 800;
}

.people-say-designation {
  color: #a4a4a4;
  min-width: 22rem;
}

.profile-image-circle {
  width: 8.2rem;
  height: auto;
  border-radius: 50%;
  margin: 1.5rem 0;
  display: none;
}

.people-say-subject-rating-wrapper {
  display: flex;
}

.people-say-star {
  flex: 1 1;
  min-width: 15rem;
  text-align: right;
  color: #ed8a19;
  font-size: 2rem;
}

#fa-star-one {
  color: #d8d8d8;
}

.fa-star-half-alt {
  transform: none;
}

/* arrow right icon */

.people-say .fas.fa-chevron-circle-left {
  position: absolute;
  right: 13.5rem;
  bottom: -9.5rem;
  color: #d8d8d8;
  font-size: 5rem;
  margin: 0.2rem;
}
.people-say .fas.fa-chevron-circle-left1 {
  position: absolute;
  right: 13.5rem;
  bottom: -9.5rem;
  color: red;
  font-size: 5rem;
  margin: 0.2rem;
}
.people-say .fas.fa-chevron-circle-right {
  position: absolute;
  right: 6.5rem;
  bottom: -9.5rem;
  color: #6d1d8e;
  font-size: 5rem;
  margin: 0.2rem;
}

.people-say-card-image-div {
  position: absolute;
  right: -32.5rem;
  top: -4.5rem;
  width: 36.7rem;
  height: 46.5rem;
  /* z-index: -1; */
}
.people-say-card-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.people-say-card-secondary-image {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 5px;
}
.people-say-card-secondary-image-div{
  position: absolute;
  bottom: -10.6rem;
  right: -61.5rem;
  width: 268px;
  height: 339px;
}
@media (max-width: 768px) {
  .people-say-about-section {
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .people-say-about-wrapper {
    height: 44rem;
    margin-bottom: 4.2rem;
  }
  .people-say.fas.fa-quote-left {
    font-size: 2.6rem;
  }
  .home-say-about-luminar-heading {
    font: 700 3rem "Kaisei HarunoUmi";
    color: #6d1d8e;
  }
  .home-people-say-about-luminar {
    font: 400 1.2rem "Mulish";
    color: #515151;
    margin-bottom: 2.2rem;
  }
  .people-say-card-image,
  .people-say-card-secondary-image,
  .fas.fa-chevron-circle-left,
  .fas.fa-chevron-circle-right {
    display: none;
  }
  .card.people-say-about-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    transform: translateX(0) !important;
    
  }
  .card-body.people-say-about {
    padding-top: 6.9rem;
  }
  .people-say-subject-rating-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .people-say-about-row {
    margin-top: 2.5rem;
  }
  .people-say-star {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem 0;
  }
  .profile-image-circle {
    display: block;
  }
  .home-say-about-luminar {
    margin-top: 4.1rem;
  }
}

@media (max-width: 576px) {
  .home-say-about-luminar {
    font: 700 1.3rem "Mulish";
  }
  .fa-star-half-alt {
    transform: scaleX(1);
  }
  .large-device-banner{
    display: none;
  }
  .small-device-banner{
    display: block !important;
  }
}

/************************************/
/*technologys  stacks */
/************************************/
.small-device-banner{
  display: none;
}
.stack-image-1 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  margin: 1rem;
  max-width: 50rem;
}

.stack-image-2 {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;
  margin: 1rem;
  max-width: 50rem;
}

.stack-square {
  height: 20rem;
  width: 20rem;
  background: rgb(130, 132, 133);
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-end;
}

.stack-heading {
  color: #6d1d8e;
  font-size: 5.5rem;
}
.stack-para {
  text-align: left;
}

.technology-stack-wrapper {
  display: flex;
  flex-direction: row;
}

/* courses vedio */

#course-vedio-section {
  background-color: #fff;
  padding-top: 7rem;
  padding-bottom: 13rem;
}

.python-course-image {
  position: relative;
}

.courses-python-parent {

  margin-left:14rem; 
   width: 60rem;
  height: 40rem;
}




@media (max-width:576px) {
  .courses-python-parent {
    position: relative;
    margin-left:0rem;
    width: auto;
    height: 36rem;
  
  }
}

.vedio-icon-white {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.courses-content-right {
  
  padding-left: 8.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
}

.python-vedio-play-image {
  margin-top: 1.5;
  margin-bottom: 1.5rem;
  width: 8.2rem;
  height: auto;
}

.courses-paragraph-date {
  color: #6d1d8e;
  font: 700 1.4rem "Archivo";
  line-height: 0.5;
  margin-top: 1.5rem;
}

.courses-paragraph {
  color: #6d1d8e;
  font: 400 1.4rem "Archivo";
  font-family: "Archivo", sans-serif;
  font-weight: 400;
}
.courses-course-name {
  font: 700 5.5rem "Mulish";
  color: #2b2b2b;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.course-description {
  font-size: 1.5rem;
  color: #464545;
  width: 100%;
  max-width: 38.5rem;
  font-weight: 400;
  font-family: "Mulish", sans-serif;
}

@media  (min-width:992px) and (max-width:1200px){
 .courses-python-parent{
  position: relative;
  width: 590px;
  height: 360px;
  margin-left: -8px;
 }
 .course-description{
   font-size: 14px;
 }
 .courses-content-right{
   padding-left: 16.5rem;
   margin-top: -32px;

 }
 .courses-course-name{
  font: 700 3.5rem "Mulish";


 }
 .python-vedio-play-image {
   width: 6.2rem;
 }
}
@media  (min-width: 1201px) {
  .courses-content-right{
    margin-top: -3rem;
  }
 #course-vedio-section .row{
   margin-right: -201px !important;
   margin-left: -101px !important;
   }

   .python-vedio-play-image {
     margin-top: 1.5;
     margin-bottom: 1.5rem;
     width: 6.2rem;
     height: auto;
   }
   .courses-paragraph-date {
     color: #6d1d8e;
     font: 700 1.4rem "Archivo";
     line-height: 0.5;
     margin-top: 1.5rem;
   }
   
   .courses-paragraph {
     color: #6d1d8e;
     font: 400 1.4rem "Archivo";
     font-family: "Archivo", sans-serif;
     font-weight: 400;
   }
   .courses-course-name {
     font: 600 3.5rem "Mulish";
     color: #2b2b2b;
     margin-top: 1rem;
     margin-bottom: 2.5rem;
   }
   
   .course-description {
     font-size: 1.rem;
     color: #464545;
     width: 95%;
     max-width: 38.5rem;
     font-weight: 400;
     font-family: "Mulish", sans-serif;
   }
   
}


@media  (min-width: 1300px) {
  .courses-content-right{
    margin-top: -3rem;
  }
 #course-vedio-section .row{
   margin-right: -201px !important;
   margin-left: -101px !important;
   }

   .python-vedio-play-image {
     margin-top: 1.5;
     margin-bottom: 1.5rem;
     width: 82px;
     height: 59px;
   }
   .courses-paragraph-date {
     color: #6d1d8e;
     font: 700 1.4rem "Archivo";
     line-height: 0.5;
     margin-top: 1.5rem;
   }
   
   .courses-paragraph {
     color: #6d1d8e;
     font: 400 1.4rem "Archivo";
     font-family: "Archivo", sans-serif;
     font-weight: 400;
   }
   .courses-course-name {
     font: 600 55px "Kaisei HarunoUmi";
     color: #2b2b2b;
     margin-top: 1rem;
     margin-bottom: 2.5rem;
   }
   
   .course-description {
     font-size: 1.5rem;
     color: #464545;
     width: 100%;
     max-width: 38.5rem;
     font-weight: 400;
     font-family: "Mulish", sans-serif;
   }
   
}

/* join now section */

#join-now-section,
#course-join-now-section,
#contact-join-now-section {
  padding-top: 0%;
}
.join-now-heading {
  padding-top: 12rem;
  font-size: 4.5rem;
  width: 100%;
  line-height: 1.4;
  color: #f5f5f5;
  font: 400 4rem "Kaisei HarunoUmi";
  max-width: 100rem;
}

.join-now-callus {
  padding-top: 1.5rem;
  font-size: 1.5rem;
  color: #bb9867;
  font-family: "Mulish";
  font-weight: 400;
  text-align: center;
}

.join-now-button {
  color: #6d1d8e;
  padding: 1rem 2.4rem;
  width: 16.2rem;
  border-radius: 3rem;
  font-size: 1.8rem;
  font-weight: 800;
  font-family: "Mulish";
  margin-top: 5.5rem;
}

.join-now-background {
  height: 57.2rem;
  width: 100%;
  background: url(../Assets/images/pexels-kindel-media-7054785.png) center center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
  background-position: bottom;
}

@media (max-width: 768px) {
  .join-now-heading {
    text-align: start;
    font: 400 3rem "Kaisei HarunoUmi";
    padding-top: 6.3rem;
    margin-bottom: 0;
  }
  .join-now-callus {
    text-align: start;
    font: 700 1.3rem "Mulish";
    width: 22.5rem;
    padding-top: 2.1rem;
    margin: 0%;
  }
  .join-now-button {
    font: 800 1.2rem "Mulish";
    padding: 1.2rem 4.7rem;
    margin-top: 5rem;
    margin-bottom: 6rem;
  }
  .join-now-background {
    background-image: none;
    background-color: #161513;
    height: auto;
  }
}

/********************************************/
/* footer */
/********************************************/

footer {
  margin-top: 1.5rem;
}

.footer-paragraph {
  color: #6d1d8e;
  font: 400 1.4rem "Mulish";
  margin-bottom: 0;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
}

.course {
  font-weight: bold;
  color: #6d1d8e;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  padding-left: 2rem;
  font-family: 'Mulish';
}

hr {
  background-color: #6d1d8e;
}

.footer-address {
  margin: 0;
  color: #7e7e7e;
  max-width: 26rem;
  font: 500 1.5rem "Mulish";
  line-height: 1.6;
}

.footer-phone {
  color: #6d1d8e;
  font-weight: 700;
}

.footer-certificate-logo {
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
  /* height: 67px; */
}

/* accordion section */
.accordion.large-screen {
  display: none;
}

@media (max-width: 769px) {
  .accordion.large-screen {
    display: block;
    margin-top: 4.4rem;
    
  }
  .footer-container {
    margin-left: 2rem;
    margin-right: 2rem;
}
.footer-web-contact-address{
  display:none
}
}

.footer-accordion a {
  display: block;
  color: #7e7e7e;
  display: block;
  margin: 0.5rem;
  text-decoration: none;
  padding-bottom: 0.3rem;
  border-bottom: 0.1rem solid #cfcfcf;
  font: 400 1.5rem "Mulish";
}
.footer-accordion a:hover {
  color: #6d1d8e;
  border-bottom: 0.1rem solid #6d1d8e;
}

.footer-courses a {
  color: #7e7e7e;
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #ffffff;
  font: 400 1.4rem "Mulish";
  width: 80%;
  transition: 1s;
  
}
.footer-courses a:hover {
  color: #6d1d8e;
  border-bottom: 0.1rem solid #6d1d8e;
  transition: 1s;
}
.about-our-services {
  display: flex;
  flex: 1;
  margin-top: 1rem;
}
.about-our-services a {
  color: #5b5b5b;
  /* font: 700 1.6rem "Kaisei HarunoUmi"; */
  margin-bottom: 1rem;
}
.about-our-services a:hover {
  color: #6d1d8e;
  border-bottom: 0.1rem solid #6d1d8e;
}
.before-email-symbol {
  margin-left: 1rem;
  margin-right: 3rem;
}
.before-email-symbol::before {
  content: "|";
  color: #e8e8e8;
  font-weight: 400;
  font-size: 3.6rem;
  position: absolute;
  top: -1.6rem;
  opacity: 0.7;
}

hr.before-email {
  background-color: #e8e8e8;
  margin-top: 3.4rem;
  font-size: 3rem;
  font-weight: 100;
  color: #7e7e7e;
  opacity: 0.4;
}
.social-media-icons {
  width: 4.3rem;
  height: 4.3rem;
}
.social-media-iconsss{
  margin-right: -7px;
  width: 5rem;
  height: 5rem;
  margin-top: 0.2rem;
}
.social-media-iconss {
  margin-top: -2px;
  margin-right: 0px;
  width: 5rem;
  height: 5rem;
}
.footer-courses-our-facility {
  padding-right: 0%;
}

.footer-wrapper {
  background-color: #f9f9f9;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
a.terms-and-use {
  color: #6d1d8e;
  font-weight: 600;
}
.terms-and-use a{
  color: #6d1d8e;
  font-weight: 600;
}
.footer-copyrights {
  font: 400 1.5rem "Mulish";
}

@media (max-width: 992px) {
  .before-email-symbol {
    display: none;
  }
  .footer-certificate-logo {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 4rem;
  }
  .footer-certificate-logo-image-one {
    width: 9.3rem;
    height: 4.1rem;
  }
  .footer-certificate-logo-image-two {
    width: 3.8rem;
    height: 3.8rem;
  }
}

@media (min-width: 768px) {
  .social-media-icons-wrapper {
    display: flex;
    justify-content: end;
  }
}

@media (max-width: 768px) {
  .footer-paragraph {
    font: 700 1.2rem "Mulish";
  }
  .social-media-icons-wrapper {
    display: flex;
    justify-content: center;
  }
  .social-media-icons {
    width: 8.3rem;
    height: 6.3rem;
    flex: 1;
  }
  .social-media-iconss {
    width: 8.3rem;
    height: 70px;
    flex: 1 1;
    margin-top: -2px;
  }

  .col-container-two {
    padding-left: 0rem;
    margin-top: 3rem;
}
.row-container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    margin-bottom:2rem;
    align-items: center
}
  .footer-brand-image {
    padding-top:0rem;
    width: 24.3rem;
    height: 5.8rem;
    object-fit: contain;
    margin-left: -1.5rem;
  }
  .footer-address {
    margin: 0px;
    margin-top: 0.7rem;
    font: 500 1.2rem "Mulish";
    max-width: 21rem;
    line-height: 1.5;
  }
  .footer-hr,
  .course,
  .footer-courses a {
    display: none;
  }
  .about-our-services {
    margin: 0;
    margin-top: 2.2rem;
  }
  .about-our-services a {
    padding-bottom: 1.8rem;
  }
  .about-our-services a:hover {
    color: #6d1d8e;
    border-bottom: 0.2rem solid #6d1d8e;
  }

  /* accordion section */
  .accordion-footer-heading {
    font: 700 1.8rem "Mulish";
    color: #6d1d8e;
    padding-bottom: 1rem;
    border-bottom: 0.2rem solid #6d1d8e;
    text-align: left;
    background-color: #fff;
  }
  .accordion-footer-heading .fas {
    float: right;
    font-size: 1.8rem;
  }
  /* .footer-certificate-logo-small-device-left {
    display: flex;
    justify-content: end;
    margin-right: -8rem;
  }
  .footer-certificate-logo-small-device-right {
    display: flex;
    justify-content: start;
    margin-left: 1.2rem;
  } */

  .footer-accordion a {
    font: 400 1.2rem "Mulish";
    color: #7e7e7e;
    padding: 1.2rem 0;
    margin: 0%;
  }

  .about-our-services a {
    font: 700 1.4rem "Kaisei HarunoUmi";
    color: #5b5b5b;
    display: block;
    margin: 0px;
    margin-top: 2.2rem;
  }

  .footer-courses-our-facility {
    padding: 0%;
  }

  .footer-copyrights {
    font: 400 1.2rem "Mulish";
    display: block;
    width: 25.2rem;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-wrapper {
    margin-top: 2.9rem;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .about-our-services a {
    height: 5.9rem;
  }
  .our-facility {
    display: block;
  }
}

@media (max-width: 576px) {
  .social-media-icons-wrapper {
    justify-content: center;
  }
}

/**********************************/
/*course page */
/* coureses section
**********************************/

#courses {
  margin-top: 0.4rem;
  padding-top: 0%;
  padding-bottom: 4.5rem;
  height: auto;
  max-width: 100%;
  background: #deccff;
}

.courses-header-wrapper {
  padding-top: 5.5rem;
  width: 100%;
}
.courses-header-image-wrapper {
  margin-top: 1.7rem;
  display: flex;
  width: 78.7rem;
  height: auto;
}

.courses-heading {
  color: #6d1d8e;
  font: 700 4rem "Kaisei HarunoUmi";
}

.courses-sub-heading {
  color: #6d1d8e;
  font: 700 2.5rem "Mulish";
  margin-top: -1rem;
}
.courses-heading-small-device-image-wrapper {
  display: none;
}

.courses-heading-paragraph {
  color: black;
  text-align: left;
  margin-top: 3.2rem;
  max-width: 45rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.header-image-certification {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.courses-paragraph-button-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: center;
  margin-top: -2rem;
}

.courses-paragraph-button {
  background-color: #6d1d8e;
  color: #ffffff;
  width: 15rem;
    height: 4.5rem;
  margin: 0.7px 0;
  margin-right: 1.8rem;
  border-radius: 3rem;
  font: 800 1.5rem "Mulish";
}

/********************************
 /* coureses section media queries
**********************************/
@media (max-width: 767px){
  .courses-header-wrapper {
    padding-top: 3.7rem;
  }
  #courses {
    margin-top: 0.4rem;
    padding-top: 0%;
    padding-bottom: 4.5rem;
    height: auto !important;
    max-width: 100%;
    background: #deccff;
}
.home-carousel-header-smalldevice-image{
  width: 360px;
  height: 364px;
}
  .courses-heading {
    font: 700 3rem "Kaisei HarunoUmi";
  }
  
  .courses-sub-heading {
    font: 700 1.3rem "Mulish";
  }
  .courses-paragraph-button-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    POSITION: RELATIVE;
    TOP: 28REM;
  }
  .courses-heading-small-device-image-wrapper {
    display: flex;
  }
  .courses-header-image-wrapper {
    display: block;
    position: relative;
    top: -16rem;
  }
  .courses-heading-paragraph {
    /* font: 400 1.2rem "Mulish"; */
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    padding-right: 0%;
    max-width: 31.5rem;
    margin: 3rem 0;
    position: relative;
    top: 35rem;
  }
  .courses-paragraph-button {
    display: inline-block;
   
    width: 16.6rem;
    height: 4.6rem;
   
    position: relative;
    top: 1rem;
    
  }
  .header-image-certification {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 992px)and (min-width:768px) {
  .job-oriented-card-title {
    font: 800 1.4rem "Mulish";
    min-height: 5rem;
  }
  .job-oriented-card-text {
    font: 400 1.2rem "Mulish";
  }
  .job-oriented-card-text-bold {
    font: 700 1.2rem "Mulish";
  }
  .job-oriented-card-link {
    font: 800 1.4rem "Mulish";
  }
  .job-oriented-card {
    max-width: 36.6rem;
    margin: 1.5rem;
    padding: 2.4rem;
    width: 24.2rem;

  }
  .courses-header-wrapper {
    padding-top: 3.7rem;
  }
  .courses-heading {
    font: 700 3rem "Kaisei HarunoUmi";
  }
  
  .courses-sub-heading {
    font: 700 1.3rem "Mulish";
  }
  .courses-paragraph-button-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    align-content: center;
    width: 126%;
    margin: -3rem;
    
  }
  .courses-heading-small-device-image-wrapper {
    display: flex;
  }
  .courses-header-image-wrapper {
    display: block;
    position: relative;
 
  }
  .courses-heading-paragraph {
    font: 400 1.2rem "Mulish";
    padding-right: 0%;
    max-width: 31.5rem;
    margin: 3rem 0;
  }
  
  .header-image-certification {
    width: 100%;
    height: auto;
  }
}



/****************************************/
/*course html training program section */
/****************************************/

#training-programs {
  background: #ffffff;
}

.training-program-wrapper {
  width: 100%;
  max-width: 63rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.training-program-heading {
  color: #6d1d8e;
  font: 700 4.5rem "Kaisei HarunoUmi";
  line-height: 1.3;
}

.training-program-paragraph {
  color: #515151;
  padding-top: 2.5rem;
  font: 400 1.5rem "Mulish";
}

@media (max-width: 576px) {
  .training-program-heading {
    font: 700 3rem "'Kaisei HarunoUmi";
    margin-bottom: 0.6rem;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
  .training-program-paragraph {
    font: 400 12px "Mulish";
    padding-left: 15px;
    padding-right: 15px;
    max-width: 36rem;
  }
  .training-program-wrapper {
    margin-top: 2.3rem;
  }
}

/********************************************/
/* course html */
/* course search  input bar */
/********************************************/

.courses-input-column {
  padding: 4rem;
  margin-bottom: 2.5rem;
  margin-top: -7rem;
}

.search-course {
  position: relative;
  display: block;
  padding: 0.6rem 1.2rem;
  width: 100%;
  line-height: 2.5;
  border: none;
  outline: none;
  border-bottom: 0.2rem solid #c5c5c5;
  margin-top: 5rem;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}
.search-icon {
  position: absolute;
  right: 4rem;
  top: 13rem;
  width: 27px;

}

@media (max-width: 768px) {
  .courses-input-column {
    padding: 9%;
    /* padding-top: 5.5rem; */
    margin: 0% auto;
  }

  ::placeholder {
    font: 100 15px "Mulish";
  }
  .search-course {
    line-height: 1;
    width: 106%;
    font: 100 2rem "Mulish";
    margin-left: -11px;
    margin-bottom: 4.6rem;
  }
  .search-icon {
    width: 2.2rem;
    height: auto;
    right: 10.4rem;
    top: 10.1rem;
  }
  .training-program-card-button {
    font: 800 1.6rem "Mulish";
  }
}

@media (max-width: 576px) {
  .search-course {
    margin-top: -3rem;
    margin-bottom: 0rem;
  }
  .search-icon {
    right: 2.8rem;
    top: 1.3rem;
  }
  .training-program-row {
    margin: 0rem;
  }
  .training-program-column {
    padding: 0rem !important;
    padding-top: 0rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0rem !important;
  }
.courseList {
    height: 602px;
}
}
.form-control{
  font-size: 1.6rem;
}
/********************************************/
/* course html */
/* training-program-cards */
/********************************************/

.training-program-card-circle {
  height: 150px;
  width: 18rem;
  background: #ececec;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.training-program-row {
  margin: 1rem;
}
.training-program-column {
  padding: 2rem;
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.training-program-card {
  background-color: #f8f8f8;
  border-radius: 1rem;
  /* min-width: 350px; */
}
.training-program-card-logo.hover {
  display: none;
}
.training-program-card:hover .training-program-card-logo {
  display: none;
}
.training-program-card:hover .training-program-card-logo.hover {
  display: flex;
}

.training-program-card:hover {
  background-color: #fff;
  box-shadow: 0rem 0.3rem 3rem #00000033;
  transition: 0.5s;
}

.training-program-card:hover .training-program-card-circle {
  background-color: #460b5e;
} 

/* not working */
.training-program-card:hover .training-program-card-circle img {
  filter: brightness(100);

}

.training-program-card:hover .training-program-card-button {
  background-color: #460b5e;
  color: #fff;
}

/* card one */

.training-program-card-one-heading,
.training-program-card-one-text,
.training-program-card-detials,
.training-program-card-button {
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
}

.training-program-card-one-heading {
  color: #6d1d8e;
  font: 700 2rem "Barlow";
  width: 100%;
  max-width: 31.1rem;
  padding-left: 0;
  min-height: 4rem;
}

.training-program-card-one-text {
  text-align: left;
  padding: 0;
  padding-left: 0.8rem;
  color: #515151;
  font-size: 1.4rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30.5rem;
}

.training-program-card-one-detials {
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}

.training-program-card-title {
  color: #6d1d8e;
  min-width:29rem;
  font: 700 1.5rem "Mulish";
}

.training-program-card-button {
  background-color: #f8f8f8;
  color: #6d1d8e;
  width: 15rem;
  height: 4.5rem;
  padding: 0;
  margin-left: 1rem;
  border-radius: 3rem;
  border: 0.2rem solid #6d1d8e;
  font-size: 1.8rem;
  font-family: "Mulish";
  font-weight: 800;
}

/* card two */

.training-program-card-two-heading {
  color: #6d1d8e;
  font-size: 2rem;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 25.8rem;
  padding-left: 0.8rem;
}

.training-program-card-two-text {
  text-align: left;
  margin-top: 2rem;
  padding: 0;
  padding-left: 0.8rem;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30.1rem;
}

.training-program-card-two-detials {
  margin-top: 4.5rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}

/* card three */

.training-program-card-three-heading {
  color: #6d1d8e;
  font-size: 2rem;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 26rem;
  padding-left: 0.8rem;
}

.training-program-card-three-text {
  text-align: left;
  margin-top: 2rem;
  padding: 0;
  padding-left: 0.8rem;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30rem;
}

.training-program-card-three-detials {
  margin-top: 6.6rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}

/* card four */

.training-program-card-four-heading {
  color: #6d1d8e;
  font-size: 2rem;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 15.5rem;
  padding-left: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.training-program-card-four-text {
  text-align: left;
  margin-top: 2rem;
  padding: 0;
  padding-left: 0.8rem;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30rem;
}

.training-program-card-four-detials {
  margin-top: 3rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}

/* card five */

.training-program-card-five-heading {
  color: #6d1d8e;
  font-size: 2rem;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 19rem;
  padding-left: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.training-program-card-five-text {
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 2.3rem;
  padding: 0;
  padding-left: 0.8;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30rem;
}

.training-program-card-five-detials {
  margin-top: 3.1rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}

.training-program-card-five-duration {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 8px;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
}

/* card six */

.training-program-card-six-heading {
  color: #6d1d8e;
  font-size: 2rem;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 24.5rem;
  padding-left: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.training-program-card-six-text {
  text-align: left;
  margin-top: 2rem;
  padding: 0;
  padding-left: 0.8rem;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30.5rem;
}

.training-program-card-six-detials {
  margin-top: 3rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}

/* card seven */

.training-program-card-seven-heading {
  color: #6d1d8e;
  font-size: 2rem;
  font-family: "Barlow";
  font-weight: 700;
  width: 100%;
  max-width: 15rem;
  padding-left: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.training-program-card-seven-text {
  text-align: left;
  margin-top: 2.5rem;
  padding: 0;
  padding-left: 0.8rem;
  color: #515151;
  font-size: 1.5rem;
  font-family: "Mulish";
  width: 100%;
  max-width: 30.5rem;
}

.training-program-card-seven-detials {
  margin-top: 4.5rem;
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 400;
  color: #515151;
  padding-left: 0.8rem;
}
.training-program-view-all-cards-button {
  display: none;
}

@media (max-width: 768px) {
  .training-program-card-one-heading,
  .training-program-card-two-heading,
  .training-program-card-three-heading,
  .training-program-card-four-heading,
  .training-program-card-five-heading,
  .training-program-card-six-heading,
  .training-program-card-seven-heading {
    font: 700 1.8rem "Mulish";
  }
  .training-program-card-one-text,
  .training-program-card-two-text,
  .training-program-card-three-text,
  .training-program-card-four-text,
  .training-program-card-five-text,
  .training-program-card-six-text,
  .training-program-card-seven-text,
  .training-program-card-five-duration {
    font: 400 1.5rem "Mulish"
  }
  .training-program-card-title {
    font: 700 1.2rem "Mulish";
    min-width: 32rem;
  }
  .training-program-card-one-text {
    padding: 0 !important;

  }
  /* .training-program-card-one-detials, */
  .training-program-card-two-detials,
  .training-program-card-three-detials,
  .training-program-card-four-detials,
  .training-program-card-five-detials,
  .training-program-card-six-detials,
  .training-program-card-seven-detials {
    font: 400 1.2rem "Mulish";
  }
  .training-program-card {
    margin: 1rem auto;
  }

  /*the following code hide in small screen */

  .training-program-row.two,
  .training-program-row.three {
    display: none;
  }

  /* button display only small screen */
  .training-program-view-all-cards-button {
    display: none;
    background-color: #fff;
    color: #6d1d8e;
    border: 0.2rem solid #6d1d8e;
    border-radius: 3rem;
    width: 15.6rem;
    height: 3.8rem;
    font: 800 1.2rem "Mulish";
    margin: 2rem auto;
    margin-bottom: 5.6rem;
  }
}

@media (max-width: 578px) {
  .training-program-card-circle {
    max-width: 16rem;
    height: 14.7rem;
  }
}

/*********************************************/
/* courses-why-luminar technolad
------------------------------------- */

#courses-why-luminar {
  background-color: #f9f9f9;
  padding-bottom: 0%;
  padding-top: 0%;
}

.courses-why-luminar {
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 951px; */
}
.courses-why-luminar-image-section {
  padding-left: 0;
}

.why-luminar-image-left.img-fluid {
  height: 900px;
  /* height: 95vh; */
  object-fit: cover;
  /* width: 590px; */
}

.why-luminar-text-section {
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.why-luminar-heading {
  font: 700 55px "Barlow";
  color: #6d1d8e;
  margin-top: 52px;
}

.why-luminar-paragraph {
  font-size: 15px;
  color: #515151;
  text-align: left;
  width: 100%;
  max-width: 550px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.why-luminar-list-wrapper {
  display: flex;
  margin-bottom: 0.3rem;
}
.why-luminar-list-bullet-text {
  margin: 0;
  height: 3.7rem;
  display: flex;
  align-items: center;
  font: 700 1.6rem "Mulish";
}
@media (max-width: 768px) {
  .courses-why-luminar {
    margin: 0%;
    margin-bottom: 5rem;
  }
}

@media (max-width: 576px) {
  .courses-why-luminar-image-section {
    display: none;
    padding: 0%;
  }
  .why-luminar-text-section {
    padding: 0%;
  }

  .why-luminar-heading {
    font: 700 30px "Kaisei HarunoUmi";
    text-align: left;
    width: 210px;
    padding: 0%;
  }
  .why-luminar-paragraph {
    font: 400 12px "Mulish";
    padding-top: 13px;
  }

  .why-luminar-list-bullet-text {
    font: 700 12px "Mulish";
    margin-top: 0%;
  }
}

/*******************************/
/* course join now section */
/*******************************/

.course-join-now-heading {
  color: #f5f5f5;
  font-family: "Barlow";
  font-size: 45px;
  font-weight: 600;
  padding-top: 120px;
  width: 100%;
  overflow: hidden;
  line-height: 1.2;
}

/* join now for course,contact.html */
.course-join-now-callus,
.contact-join-now-callus {
  color: #bb9867;
  font-size: 15px;
  font-weight: 400;
  font-family: "Mulish";
  padding-top: 25px;
}

/*******************************/
/* contact us page*/
/*******************************/
.mobile-logos{
  display: none;
}
.web-logos{
  display: block;
}
@media (max-width: 768px) {
  #contact-us {
    background-image: none;
    background-color: #f9f9f9;
  }
  .mobile-logos{
    display: block;
  }
  .web-logos{
    display: none;
  }
}

/************************************/
/* contact-us container */
/* contact-us-heading */
/************************************/
.contact-us-container {
  display: flex;
}
.contact-us-heading {
  font-family: "Kaisei HarunoUmi";
  font-size: 5.5rem;
  color: #5f078a;
  font-weight: 700;
  margin-bottom: 4.7rem;
  padding-left: 1rem;
}

.contact-us-circle {
  background: #f3f3f3;
  height: 8.3rem;
  width: 8.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
  margin-bottom: 2.8rem;
}

.contact-us-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.contact-us-subheading {
  color: #6d1d8e;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 15px;
  padding-top: 0.3rem;
}

.contact-us-paragraph {
  color: #505050;
  font-family: "Mulish";
  font-weight: 400;
  font-size: 1.5rem;
}

.contact-us-text {
  flex: 1 1;
}

.contact-us-social-media {
  display: block;
  padding-left: 0%;
  position: relative;
  margin-top: 9.5rem;
}

.social-icons {
  margin-left: -0.5rem;
}
.social-media-icon {
  width: 4.7rem;
}

.contact-us-connect {
  color: #363636;
  font-size: 2.5rem;
  font-family: "Mulish";
  font-weight: 800;
}

.contact-us-submit-button {
  background-color: #fff;
  color: #5f078a;
  padding: 1.2rem 4.6rem;
  display: inline-block;
  border: 0.2rem solid #5f078a;
  border-radius: 3rem;
  font-family: "Mulish";
  font-weight: 800;
  font-size: 2rem;
}
.contact-us-checkbox {
  background-color: #fff;
  color: #515151;
  padding: 1.2rem 4rem;
  display: flex;
  align-items: center;
  width: 19.3rem;
  border: 0.2rem solid #5f078a;
  margin-right: 2rem;
}

/* contact us right  */
.contact-us-column-right-heading {
  color: #363636;
  font-family: "Kaisei HarunoUmi";
  font-weight: 700;
  font-size: 3rem;
  margin: 0%;
  margin-bottom: 1.3rem;
}
.contact-us-column-right-paragraph {
  color: #515151;
  font-family: "Mulish";
  font-weight: 400;
  font-size: 1.5rem;
  max-width: 52rem;
}

.form-control.contact-us-input-field {
  height: 6rem !important;
  padding: 1rem;
  width: 90%;
}
.select-filed{
  font-size: 1.4rem;
  color: #5b5b5b;
}
/*******************************************/
/* contact contact-us-container media query section */

@media (max-width: 768px) {
  #contact-us {
    padding-top: 3.8rem;
    height: 100%;
  }
  .contact-us-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact-us-column-right-heading {
    font: 700 2rem "Kaisei HarunoUmi";
    padding: 0%;
    margin-bottom: 1.6rem;
  }
  .contact-us-column-right-paragraph {
    font: 400 1.2rem "Mulish";
    color: #515151;
  }
  .form-control.contact-us-input-field {
    height: 4.7rem;
    border: 1px solid #bbbbbb;
    border-radius: 0.7rem;
    padding: 1.6rem 1.8rem;
  }
  textarea.form-control {
    height: 22.9rem;
  }
  .contact-us-heading {
    font: 700 3rem "Kaisei HarunoUmi";
    margin: 0%;
    margin-top: 4.3rem;
    margin-bottom: 3.8rem;
  }

  .contact-us-circle {
    width: 6.9rem;
    height: 6.9rem;
  }
  .contact-us-subheading {
    font: 700 1.5rem "Mulish";
  }
  .contact-us-paragraph {
    font: 400 1.2rem "Mulish";
  }
  .contact-us-social-media {
    display: none;
  }
  .form-check-label {
    flex: 1;
  }
  .contact-us-checkbox {
    width: 15.6rem;
    height: 4.6rem;
    font-size: 1rem;
  }
  .contact-us-submit-button {
    width: 15.6rem;
    height: 4.6rem;
    font: 800 1.5rem "Mulish";
  }
  .contact-us-button-section {
    display: flex;
  }
}

/******************************/
/* contact-join-now-section */
/******************************/

#contact-join-now-section {
  padding-top: 0;
}

/* contact-join-now-heading */
.contact-join-now-heading {
  color: #f5f5f5;
  font-size: 4rem;
  text-align: center;
  font-family: "Kaisei HarunoUmi";
  font-weight: 400;
  padding-top: 12rem;
  width: 100%;
  overflow: hidden;
  line-height: 1.2;
}

/*******************************/
/* courses detials page*/
/*******************************/

/*courses detials page header section
************************************/

#courses-detials {
  background: #fafafa;
  width: 100%;
  height: auto;
  margin-top: 7.4rem;
  position: relative;
  padding: 0%;
}

.course-detials-header-text {
  margin-top: 12rem;
}
.course-detials-header-text p{
  min-height: 3rem;
  font: 400 15px 'Mulish';
  color: #656565;
  margin-top: 4rem;
}
    
.course-detials-header-text h1{
  color: #6d1d8e;
  font-size: 3.5rem;
  font-weight: 700;
  font-family: "Kaisei HarunoUmi";
}

.course-detials-header-text h2 {
  font-size: 2.5rem;
  font-family: "Mulish";
  font-weight: 700;
  color: #6d1d8e;
  text-align: start !important;
  
}
.course-detials-header-image-small-device {
  display: none;
}

.course-detials-paragraph {
  margin: 4.5rem 0;
  font-size: 1.5rem;
  color: #515151;
  width: 100%;
  max-width: 47rem;
}

.course-detials-header-button {
  background-color: #6d1d8e;
  color: #fff;
  font-family: "Mulish";
  font-weight: 800;
  font-size: 1.5rem;
  padding: 0 !important;
  border-radius: 5rem;
  margin-right: 1.4rem;
  margin-top: 1rem;
  height: 4.5rem;
  width: 15rem;
}

/*
.course-detials-header- resposnsive section
***********************************/
@media (max-width: 767px) {
  .course-detials-header-image-small-device {
    display: flex;
    margin: 26px 0;
  }
  .course-detials-header-text {
    margin-top: 2rem;
   }
  .course-detials-header-button-wrapper {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .course-detials-paragraph {
    margin: 4.5rem 0;
    font-size: 2rem;
    color: #515151;
    width: 100%;
    max-width: 47rem;
    top: 56rem;
    position: relative;
  }
}

@media (max-width: 576px) {
  .people-say-card-image-div{
    display: none;
  }
  .people-say-card-secondary-image-div{
    display: none ;
  }
  .course-detials-heading {
    font: 700 30px "Mulish";
    
  }
  #courses-detials {
    background: #fafafa;
    width: 100%;
    height: 74rem !important;
    margin-top: 7.4rem;
    position: relative;
    padding: 0%;
}
  .course-detials-container {
    height: auto !important;
  }
  .course-detials-course-certificate-image {
    height: 100%;
    object-fit: cover;
    left: 0 !important;
}

  .course-detials-course-certificate-detials {
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 35px !important; */
   padding: 0;
  }


.courses-paragraph-button {
  display: inline-block;
  width: 13.6rem;
  height: 4.6rem;
  position: relative;
  top: 0rem !important;

}
  .course-detials-paragraph {
    font: 400 17px "Mulish";
    margin: 0;
    position: relative;
    top: 0rem;
  }

  .course-detials-header-image {
    margin:0;
  }
  .btn.card-course-enroll {
    width: 190px !important;
    height: 40px !important;
    font: 800 14px "Mulish";
    padding: 0;
    margin-top: 0%;
    margin-bottom: 21px;
}
  .course-detials-certifiecate-card {
    margin-left: auto;
    margin-right: auto;
    width: auto !important;
    height: auto !important;
    border-radius: 20px;
}
  .course-detials-header-button {
    width: 156px;
    height: 46px;
    font: 800 15px "Mulish";
    margin: 0%;
    margin-right: 8px;
    padding: 0%;
  }
  .course-detials-header-button.two {
    margin-right: 0%;
  }
}
/********************************/
/* courses detials page */
/*course-detials-course-detials */
/********************************/

.course-detials-course-about-container {
  padding: 0%;
  text-align: center;
}
.course-detials-course-about-text-wrapper {
  padding: 0%;
}

.course-detials-course-about-container h2 {
  margin-top: 17px;
  color: #6d1d8e;
  font: 500 55px ;
}

.course-detials-course-about-subheading {
  color: #515151;
  font: 700 20px "Mulish";
}

/* .course-detials-course-about-detials {
  margin-top: 30px;
  padding: 4px;
  padding-bottom: 50px;
  color: #515151;
  font: 400 13px "Mulish";
  max-width: 101rem;
} */
.course-detials-course-about-certificate-section {
  background-image: url(../Assets/images/courses-detials/white_dots_lines_background_hd_white_background.png);
  max-width: 100%;
  height: 100%;
}
.course-detials-course-about-certificate-image {
  margin-top: 110px;
}
.course-detials-course-about-certificate-text-section {
  padding: 0;
  margin-top: 187px;
  margin-bottom: 214px;
}
.course-detials-course-about-certificate-section-wrapper {
  text-align: left;
}
.course-detials-course-certificate-heading {
  font: 700 22px;
}
.course-detials-course-certificate-subheading {
  font: 400 12px "Mulish";
    color: #656565;
    max-width: 63rem;
    text-align: justify;
}

/* course detials about  media query section */
@media (max-width: 767px) {
  .course-detials-course-about-heading {
    font: 700 50px "Kaisei HarunoUmi";
    max-width: 51rem;
  }
  .course-detials-course-about-subheading {
    font: 700 16px "Mulish";
  }
  .course-detials-course-about-detials {
    font: 400 18px "Mulish";
  }
  .course-detials-course-about-certificate-section-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .course-detials-course-about-certificate-badge {
    width: 70px;
    height: 103px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  .course-detials-course-about-certificate-text-section {
    margin: 0%;
    padding: 0 15px;
  }

  .course-detials-content p{
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 3rem;
    padding: 0;
  }
  .course-detials-content h1{
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: -44rem;
    padding: 0;
  }
  .course-detials-course-about-certificate-subheading-wrapper {
    max-width: 695px;
    
}
  .course-detials-course-about-certificate-subheading-wrapper {
    position: relative;
    top: 69rem;
  } 
  .course-detials-course-about-certificate-image {
    margin: 30px auto;
  }

}



/********************************/
/* courses detials page */
/*course-detials-module-section */
/********************************/

.course-detials-container {
  /* height: 800px; */
  height: 550px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.course-detilas-module-table-heading {
  color: #6d1d8e;
  font: 500 55px;
}
.course-detilas-module-table-subheading {
  color: #515151;
  font: 700 20px "Mulish";
  margin-bottom: 50px;
}

/*********************************************
        course-detials-module table
*********************************************/

.course-detials-contents-wrapper {
  display: flex;
}
.course-detials-modules,
.course-lessons {
  display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-right: -7px;
}
.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  margin: -6px;
}
.course-detials-module {
  border: 1px solid #6d1d8e;
  width: 193px;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 400 20px "Mulish";
  color: #989898;
}
.course-detials-module.zero{

  color:#6d1d8e !important ;
  font-weight: bold;
}
.course-lessons {
  margin: 0 2px;
}

.course-lessons,
.course-module-heading {
  border: 1px solid #6d1d8e;
}

.course-detials-module.zero,
.course-module-heading {
  height: 133px;
}

.course-detials-module.one {
  background-color: #eec5ff;
  font: 700 20px "Mulish";
  color: #6d1d8e;
}

.course-module-heading {
  font: 800 13px "Mulish";
  color: #6d1d8e;
}
.course-module-name {
  margin: 50px 36px;
}


.course-module-lesson-name,
.course-module-lesson-content {
  display: block;
  text-align: start;
}
.course-module-lesson-name {
  margin-bottom: 35px;
  font-weight: 700;
  color: #515151;
}
.course-module-lesson-name.one {
  margin-top: 10px;
  font-size: 15px;
}
.course-module-lesson-content {
  margin-bottom: 50px;
  font: 400 14px "Mulish";
  color: black;
  max-width: 87%;
}

/* .course-module-lessons-content {
  margin-top:10px;
  margin-right: 15px;
  overflow-y: scroll;
} */

/*course-detials-module-section 
media querie section
******************************************/
@media (min-width: 769px) {
  .dropdown{
  display: none;
  
  }
} 
@media (max-width: 767px) {
  .course-detilas-module-table-heading {
    text-align: left;
    font: 700 40px "Kaisei HarunoUmi";
  }
  .course-detilas-module-table-subheading {
    text-align: left;
    font: 700 16px "Mulish";
    margin-bottom: 0px;
  }
  .course-detials-contents-wrapper {
    display: none;
  }
 
  .accordion > .card > .card-header {
    margin-bottom: 0px;
  }
  .course-detilas-module-accordion {
    margin: 20px auto;
    width: 90%;
  }
  .course-detilas-module-accordion-heading {
    font: 800 15px "Mulish";
    color: #6e6e6e;
    background-color: transparent;
  }

  .card-header.course-module-heading {
    background-color: #fff;
    border: none;
    border-bottom: 2px solid #dbdbdb;
    display: block;
    position: relative;
  }
  .course-detilas-module-accordion-heading .fas {
    position: absolute;
    right: 0;
  }

  /* .course-detilas-module-accordion-heading:after{
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 0;
  }
  .course-detilas-module-accordion-heading.collapsed:after{
    content: "\f106";
    position: absolute;
    right: 0;
  } */

  .card-header.course-module-heading:hover
    .course-detilas-module-accordion-heading {
    color: #6d1d8e;
  }
  .card-header.course-module-heading:hover {
    border-bottom: 2px solid #6d1d8e;
  }

  .course-detilas-accordion-heading {
    font: 800 17px "Mulish";
    color: #6d1d8e;
    margin: 21px auto;
  }
  .course-detilas-accordion-module-name {
    font: 800 15px "Mulish";
    color: #515151;
    margin-bottom: 20px;
  }
  .course-detilas-accordion-module-content {
    font: 400 12px "Mulish";
    color: #515151;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .course-detilas-module-accordion {
    display: none;
  }
}

/*************************************************/
/* courses detials page */
/*course-detials-certified-courses */
/********************************************/

#course-detials-certified-courses {
  background-color: #6d1d8e;
  color: #fff;
  position: relative;
  margin: 1rem;
}

.course-detials-certificate-logo {
  margin-top: 60px;
}

.course-detials-certifiecate-caption {
  color: #fff;
  font: 400 20px "mulish";
}

.course-detials-certifiecate-course-heading-one,
.course-detials-certifiecate-course-heading-two {
  color: #fff;
  font: 400 55px ;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
}

.course-detials-certifiecate-course-paragraph {
  color: #fff;
  font: 400 15px "Mulish";
  max-width: 500px;
  margin-top: 21px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  text-align: center;
}

.course-detials-certifiecate-circle {
  background: #f1f1f1;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 51px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}
.course-detials-certifiecate-card {
  margin-left: auto;
  margin-right: auto;
  width: 420px;
  height: 600px;
  border-radius: 20px;
}

.course-detials-batch-detials {
  color: #303030;
  font: 700 25px "Mulish";
}

/* .course-detials-title{
  color: #6D1D8E;
  font:700 16px 'Mulish' ;
} */

.course-detials-card-content-section {
  width: 85%;
  margin: 28px auto;
}
.course-detials-card-content-wrapper {
  display: flex;
  flex-direction: row;
}

.course-detials-card-criteria {
  font: 700 12px "Mulish";
  color: #6d1d8e;
  display: flex;
  justify-content: start;
}
.course-detials-card-criteria-next-batch {
  margin-right: 10px;
  color: #6d1d8e;
  white-space: nowrap;
}
.course-detials-card-criteria-duration {
  margin-right: 23px;
}
.course-detials-card-criteria-course-fees {
  margin-right: 0px;
}

.course-detials-card-certification {
  line-height: 2;
  margin-left: 5px;
}

.course-detials-card-info {
  flex: 2;
  display: flex;
  justify-content: start;
  margin: 0;
  margin-left:0px;
}
.course-detials-card-info.card-fees {
  margin-left: 0px;
}
.course-detials-card-data {
  font: 400 12px "Mulish";
  color: black;
}
.course-detials-card-fees {
  margin-left: 6px;
  font: 400 12px 'Mulish';
}

.course-detials-for-new-admission {
  display: block;
  color: #6d1d8e;
  font: 700 11px "Mulish";
}

.btn.card-course-enroll {
  background-color: #6d1d8e;
  color: #ffffff;
  font: 700 17px "Mulish";
  border-radius: 50px;
  padding: 16px 86px;
  margin-bottom: 45px;
  margin-top: 10px;
}

.course-detials-certified-courses-background-image-left {
  position: absolute;
  bottom: 138px;
  left: 43px;
  width: 100%;
  max-width: 483px;

}

.course-detials-certified-courses-background-image-right {
  position: absolute;
    bottom: 133px;
    right: 31px;
    width: 100%;
    max-width: 515px;
}
.course-detials-certifiecate-course-text-bottom-wrapper {
  margin-top: 61px;
  width: 100%;
  max-width: 670px;
}

.course-detials-certifiecate-course-text-bottom {
  color: #d05eff;
  font: 400 italic 12px "Mulish";
}
.course-detials-certifiecate-card-logo
{
  width: 51%;
  height: 50%;
  /* border-radius: 50%; */
  object-fit: contain;
}
/* course-detials-certified-courses media queries
************************************************/
@media (max-width: 1199px) {
  .course-detials-certified-courses-background-image-left,
  .course-detials-certified-courses-background-image-right {
     
    display: none;
  }
}
@media (max-width: 1300px) {
  .course-detials-certified-courses-background-image-left {
    position: absolute;
    bottom: 138px;
    left: 43px;
    width: 100%;
    max-width: 379px;
  
  }
  
  .course-detials-certified-courses-background-image-right {
    position: absolute;
      bottom: 133px;
      right: 31px;
      width: 100%;
      max-width: 406px;
  }
}
@media (min-width: 2000px) {
  .course-detials-certified-courses-background-image-left {
    position: absolute;
    bottom: 138px;
    left: 325px;
    width: 100%;
    max-width: 483px;
  
  }
  
  .course-detials-certified-courses-background-image-right {
    position: absolute;
      bottom: 133px;
      right: 314px;
      width: 100%;
      max-width: 515px;
  }
}
@media (min-width: 2500px) {
  .course-detials-certified-courses-background-image-left {
    position: absolute;
    bottom: 138px;
    left: 575px;
    width: 100%;
    max-width: 483px;
  
  }
  
  .course-detials-certified-courses-background-image-right {
    position: absolute;
      bottom: 133px;
      right: 555px;
      width: 100%;
      max-width: 515px;
  }
}
@media (max-width: 768px) {
  .course-detials-certified-courses-background-image-left,
  .course-detials-certified-courses-background-image-right {
    display: none;
  }
  .course-detials-certifiecate-course-heading-one,
  .course-detials-certifiecate-course-heading-two {
    font: 400 30px "Kaisei HarunoUmi";
  }
  .course-detials-certifiecate-caption {
    margin-bottom: 50px;
  }
  .course-detials-certifiecate-course-paragraph {
    margin-bottom: 24px;
  }
  .course-detials-certifiecate-course-heading-wrapper {
    margin: auto 14px;
  }
  /* card section */
  .course-detials-certifiecate-circle {
    width: 130px;
    height: 130px;
    margin-top: 37px;
    margin-bottom: 20px;
  }
  .course-detials-certifiecate-card-logo {
    width: 67px;
    height: 62px;
  }
  .course-detials-batch-detials {
    font: 800 18px "Mulish";
    margin-bottom: 30px;
  }
  .course-detials-card-fees {
    font: 400 14px "Mulish";
    margin-left: 10px;
  }
  
  .course-detials-card-content-section {
    margin: 0% 15px;
    text-align: left;
  }
  .course-detials-card-info {
    margin-left: 6px;
  }
  .course-detials-card-criteria-certification {
    margin-top: 2px;
  }
  .course-detials-card-certification {
    line-height: 1.7;
    margin-bottom: 30px;
  }
  .course-detials-for-new-admission {
    font: 700 13px "Mulish";
    margin-top: 27px;
  }
  .btn.card-course-enroll {
    width: 203px;
    height: 59px;
    font: 800 14px "Mulish";
    padding: 0;
    margin-top: 0%;
    margin-bottom: 21px;
  }
  .course-detials-certifiecate-course-text-bottom {
    font: italic 12px "Mulish";
    margin-bottom: 34px;
  }
  .course-detials-certifiecate-course-text-bottom-wrapper {
    margin-top: 43px;
  }
  .course-detials-card-content-section {
    margin-left: auto;
    margin-right: auto;
  }
}

/* for extra small image */
@media (max-width: 576px) {
  .course-detials-card-content-section {
    margin-left: auto;
    margin-right: auto;
  }
  .course-detials-certificate-logo {
    margin-top: 50px;
  }
  
.cousredetails-section h1 {
    font: 700 30px "Kaisei HarunoUmi";
    max-width: 35rem;
}
  .course-detials-certificate-image {
    width: 70px;
    height: auto;
  }
  .course-detials-certifiecate-caption {
    font: 400 8px "Mulish";
  }
  .course-detials-certifiecate-course-heading-one,
  .course-detials-certifiecate-course-heading-two {
    display: inline;
  }
  .course-detials-card-criteria-duration {
    margin-right: 23px;
  }
  .course-detials-card-criteria {
    font-size: 14px;
  }
  .course-detials-card-data {
    font: 400 12px "Mulish";
  }
  .course-detials-card-data {
    font-size: 14px;
    max-width: 165px;
    margin-bottom: 12px;
  }
  .course-detials-card-content-wrapper {
    width: 250px;
  }
}

/***********************************/
/* courses detials page */
/*  course info full */
/***********************************/

#card-detials-course-info {
  background-color: #ffffff;
}

.course-detials-course-info-wrapper {
  /* width: 100%; */
  max-width: 532px;
}
.course-detials-course-info-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.course-detials-course-info-image-section {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
}
.course-detials-course-info-image-two-section {
  display: flex;
  justify-content: space-between;
}
.course-detials-course-info-wrapper h1{
  color: #6d1d8e;
  font-size:35px ;
  margin-top: 20px;
}
.course-detials-course-info-row-two-wrapper h2{
  color: #6d1d8e;
  font-size:35px ;
  margin-top: 60px;
  font: 700 35px "Mulish";
}
.course-detials-course-info-wrapper h3 ,.course-detials-course-content-wrapper h3{
  color: #515151;
  font: 700 20px "Mulish";
}

.course-detials-course-info p,
.course-detials-course-content-wrapper p {
  color: #515151;
  font: 400 15px "Mulish";
}

.course-detials-course-info-paragraph-two {
  margin-top: 20px;
}

.course-detials-course-content-wrapper {
  padding: 60px 0px;
  padding-bottom: 0%;
}

.course-detials-course-info-paragraph-subject {
  padding-top: 20px;
  display: block;
  font: 400 15px "Mulish";
}
.course-detials-course-info-paragraph-subject.thick {
  font-weight: 500;
}
/* .course-detials-course-info-paragraph-subject-image-one{
 height:696px ; 
  height: auto;
  width: 464px; 
}
.course-detials-course-info-paragraph-subject-image-two{
  /* height: 703px; 
  height: auto;
  width: 100%;
}*/

/*
  courses detials page  media queries
******************************************/
@media (max-width: 768px) {
  .course-detials-course-info-heading {
    font: 700 20px "Mulish";
  }
  .course-detials-course-info-subheading {
    font: 700 13px "Mulish";
    margin-top: 1rem;
  }
  .course-detials-course-info-paragraph,
  .course-detials-course-info-paragraph-two {
    font: 400 12px "Mulish";
  }
  .course-detials-course-info-row-two-wrapper {
    display: flex;
  }
  .course-detials-course-info-paragraph-subject {
    font: 400 15px "Mulish";
    padding-top: 0%;
    margin-bottom: 20px;
  }
  .course-detials-course-info-paragraph-subject.thick {
    font: 500 15px "Mulish";
  }
  .course-detials-course-content-wrapper {
    width: 90%;
    padding-top: 20px;
    margin-top: -23rem;
  }
}

/********************************************/
/* courses detials page */
/* course Admission Process */
/********************************************/

#course-detials-admission-section {
  background-color: #fbfbfb;
}
.course-detials-admission-heading {
  color: #6d1d8e;
  font: 500 50px ;
  margin-top: 10px;
}

.course-detials-admission-subheading {
  color: #515151;
  font: 600 20px "Mulish";
}

/* cards */
.course-detials-admission-card-section {
  margin-top: 47px;
  margin-bottom: 70px;
  padding-right: 10rem;
  padding-left: 10rem;

}

.card.course-detials-admission {
  border: 1px solid #6d1d8e;
  border-radius: 8px;
  height: 267px;}

.card-title.course-detials-admission {
  color: #6d1d8e;
  font: 700 50px "Kaisei HarunoUmi";
  padding: 12px 0px;
  margin: 0px;
}


.card-subtitle.course-detials-admission-title-one,
.card-subtitle.course-detials-admission-title-two,
.card-subtitle.course-detials-admission-title-three {
  color: #515151;
  font: 800 20px "Mulish";
  padding-bottom: 12px;
}
.card-subtitle.course-detials-admission-title-three {
  width: 100%;
  max-width:164px;
}

.card-text.course-detials-admission-detials-one,
.card-text.course-detials-admission-detials-two,
.card-text.course-detials-admission-detials-three {
  color: #515151;
  font: 400 12px "Mulish";
  max-width: 304px;
  margin-top: 33px;
}

.card-subtitle.course-detials-admission-title-one {
  display: block;
  width: 100%;
  max-width: 220px;
  font-size: 2rem;
}

@media (max-width: 767px) {
  .card.course-detials-admission {
    margin: 7px;
    width: 242px;
  }
  .course-detials-admission-heading,
  .course-detials-admission-subheading {
    text-align: left;
  }
  .course-detials-admission-heading {
    margin-top: 40px;
    font: 700 30px "Kaisei HarunoUmi";
  }
  .course-detials-admission-subheading {
    font: 700 20px "Mulish";
  }
  .course-detials-admission-card-section {
    margin: 28px auto;
    overflow-y: scroll;
    display: none;
  }
  .card-title.course-detials-admission {
    font: 700 30px "Kaisei HarunoUmi";
  }
  .card-subtitle.course-detials-admission-title-one,
  .card-subtitle.course-detials-admission-title-two,
  .card-subtitle.course-detials-admission-title-three {
    font: 800 20px "Mulish";
  }
  .card-text.course-detials-admission-detials-one,
  .card-text.course-detials-admission-detials-two,
  .card-text.course-detials-admission-detials-three {
    font: 400 12px "Mulish";
  }
}

/*********************************************/
/* courses detials page */
/* course-detials-why-you-prefer-us */
/*********************************************/

#course-detials-prefer-us {
  background-image: linear-gradient(#46135bd4, #46135bd4),
    url(../Assets/images/courses-detials/pexels-fauxels-3182768.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  overflow: hidden;
}

.course-detials-prefer-courses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.course-detials-prefer-us {
  font: 400 50px;
  color: #ffffff;
  margin-top: 43px;
  margin-bottom: 0px;
  text-align: center;
}

.course-detials-prefer-name {
  color: #fff;
}

.course-detials-prefer-name {
  font: 400 15px "Mulish";
}
.course-detials-prefer-count-one,
.course-detials-prefer-count-two,
.course-detials-prefer-count-three,
.course-detials-prefer-count-four {
  margin-top: 12px;
  color: #fff;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 40px;
  font-family: 'Mulish';
}
.course-detials-prefer-count-three {
  margin-top: 1rem;
}
.course-detials-prefer-count-two {
  margin-top: 2rem;
}

.course-detials-prefer-count-one {
  margin-top: 1rem;
  -webkit-user-drag: none;
}
.course-detials-prefer-us-images {
  max-width: 100%;
  height: auto;
  width: 30%;
  -webkit-user-drag: none;
  padding-bottom: 1rem;
}
.course-detials-prefer-us-image-one {
  max-width: 100%;
  height: auto;
  width: 30%;
  margin-top: 1rem;
  -webkit-user-drag: none;
}
.course-detials-prefer-us-images-three {
  max-width: 100%;
  height: auto;
  width: 25%;
  -webkit-user-drag: none;
}

@media (max-width: 540px) {
  .course-detials-prefer-us {
    font: 400 30px "Kaisei HarunoUmi";
    width: fit-content;
    text-align: left;
    margin-left: 15px;
  }
  #course-detials-prefer-us {
    background-image: none;
    background-color: #46135bd4;
    margin-top: 1rem;
    
  }

  .course-detials-prefer-us,
  .course-detials-prefer-us-row {
    margin-top: 15px;
    margin-bottom: -41px;
    margin-right: auto;
    margin-left: auto;
    
  }
  .course-detials-prefer-us-row {
    margin-left: auto;
    margin-right: auto;
  }
  .course-detials-prefer-courses {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin: 7px;
  }
  .course-detials-prefer-courses-card-section {
    padding: 0%;
  }
  .course-detials-prefer-us-images.one {
    width: 70px;
    height: 58px;
    margin-top: 32px;
  }
  .course-detials-prefer-us-image-one{
    width: 40px;
    height: 40px;
    margin-top: 19px;
  }
  .course-detials-prefer-us-images.two {
    width: 70px;
    height: 47px;
    margin-top: 19px;
  }
  .course-detials-prefer-us-images.three {
    width: 58px;
    height: 80px;
    margin-top: 23px;
  }
  .course-detials-prefer-us-images.four {
    width: 78px;
    height: 57px;
    margin-top: 12px;
  }
  .course-detials-prefer-count-one {
    margin-top: 18px;
    font: 800 39px "Mulish";
  }
  .course-detials-prefer-count-two {
    margin-top: 12px;
    font: 800 37px "Mulish";
  }
  .course-detials-prefer-count-three {
    margin-top: 8px;
    font: 800 37px "Mulish";
  }
  .course-detials-prefer-count-four {
    margin-top: 7px;
    font: 800 36px "Mulish";
  }
  .course-detials-prefer-name {
    font: 400 13px "Mulish";
  }
}

/************************************************/
/* courses detials page */
/* placements section */
/************************************************/
#course-detials-placement {
  padding-bottom: 83px;
}
.course-detials-placement-container-small-device {
  display: none;
}
.course-detials-placement-container-large-device {
  display: revert;
  flex-direction: column;
}

.course-detials-placement-heading {
  color: #6d1d8e;
  font: 700 55px "Kaisei HarunoUmi";
  margin-top: 45px;
  text-align: center !important;
}

.course-detials-placement-subheading {
  color: #515151;
  font: 700 25px "Mulish";
}
.course-detials-placements-profile-name {
  color: #515151;
  font: 700 15px "Mulish";
  margin-top: 5px;
  margin-bottom: 3rem;
  min-width: 15rem;
}
.course-detials-placements-profile-qualification {
  font-weight: 400;
  display: block;
}

.course-detials-placement-profile {
  margin-top: 67px;
  margin-bottom: 7rem;
}


.course-detials-placement-carousel-column{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 154px;
  height: 154px;
  margin-top: 4rem;
}

@media (max-width: 769px) {
  .course-detials-placement-heading {
    font: 700 30px "Kaisei HarunoUmi";
  }
  .course-detials-placement-subheading {
    font: 700 13px "Mulish";
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .course-detials-placement-container-large-device {
    display: none;
  }
  .course-detials-placement-container-small-device {
    display: block;
  }
  .course-detials-placements-profile-name {
    text-align: center;
    font: 800 14px "Mulish";
  }
  .course-detials-placements-profile-qualification {
    font: 400 13px "Mulish";
    padding-bottom: 1rem;
  }
  .carousel-indicators.course-detials-placement-container {
    bottom: -50px;
    left: 0px;
  }
  .carousel-indicators.course-detials-placement-container li {
    height: 3px;
    background-color: #6e6e6e;
    width: 10px;
  }
  .carousel-indicators.course-detials-placement-container li.active {
    background-color: #6d1d8e;
    width: 35px;
  }
  .course-detials-placement-carousel-image {
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    object-position: top;
  }
}

/****************************************/
/* courses detials page */
/* course detials join now section */
/****************************************/

.course-detials-join-now-heading {
  margin-top: 134px;
  font: 400 40px "Kaisei HarunoUmi";
  color: #f5f5f5;
}

/****************************************/
/* courses detials page */
/* footer */
/****************************************/
/* courses */

.course-detials-course {
  font: 700 18px "Kaisei HarunoUmi";
  color: #6d1d8e;
}

.course-detials.about-our-services {
  font: 700 18px "Kaisei HarunoUmi";
}

.course-detials.about-our-services a {
  margin-bottom: 20px;
}

/****************************************/
/* placement page */
/* header */
/****************************************/

#placement-header {
  margin-top: 5px;
  background-color: #f9f9f9;
  height: 57rem;
}
.placement-body {
  margin-left: auto;
  margin-right: auto;
}

/* color-section  color:#6D1D8E ; */
.placement-header-heading,
.placement-header-subheading,
.placement-for-students-heading,
.placement-for-students-our-student-working,
.placement-for-student-student-name {
  color: #6d1d8e;
}
.placement-header-image-wrapper-small {
  display: none;
}

/* color-section  color:#515151 ; */
.placement-header-paragraph,
.placement-for-students-paragraph,
.placement-for-student-student-company-name {
  color: #515151;
}

/*  font: 400 15px 'Mulish'; */
.placement-header-paragraph,
.placement-for-students-paragraph {
  display: block;
  font: 400 15px "Mulish";

}

/* display block align center */
.placement-for-students-subheading,
.placement-for-students-placement-rate-heading,
.placement-for-students-placement-rate-button,
.placement-for-students-our-student-working-paragraph {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* same font 700 55px 'Kaisei HarunoUmi'; */
.placement-for-students-heading,
.placement-for-students-our-student-working {
  font: 700 55px "Kaisei HarunoUmi";
}
/* font: 700 20px 'Mulish'; */
.placement-for-students-subheading,
.placement-for-students-our-student-working-paragraph {
  font: 700 20px "Mulish";
}
.placement-header-heading {
  margin-top: 155px;
  font: 700 48px "Kaisei HarunoUmi";
}
.placement-header-subheading {
  font: 700 25px "Mulish";
  margin-top: -2rem;

}
.placement-header-paragraph {
  margin-top: 45px;
  max-width: 455px;
}

.placement-for-students-subheading {
  max-width: 582px;
  margin-top: 13px;
}
.placement-for-students-paragraph {
  margin: 0;
  margin-top: 60px;
}
#placement-for-students-card-section {
  padding-top: 0%;
}
.placement-for-students-placement-rate-heading {
  font: 800 20px "Mulish";
  margin-bottom: 60px;
}
.placement-for-students-placement-rate-button {
  display: inline-block;
  width: 157px;
  height: 40px;
  border-radius: 50px;
  background-color: #fff;
  color: #6d1d8e;
  border: 3px solid #6d1d8e;
  margin: 85px auto;
  font-size: 21px;
  font-family: 'Mulish';
  font-weight: bold;
}
.placement-for-students-our-student-working-paragraph {
  max-width: 582px;
  margin-top: 13px;
}

/*****placement page*****/
/*********header and body*********/
/* media queries */
@media (max-width: 768px) {
  /* #placement-for-students{ */
  /* margin: 0% 2px; */
  /* } */
  .placement-header-image {
    max-width: 100%;
    height: auto;
  }
  .placement-header-heading {
    margin-top: 15px;
    font: 700 30px 'Kaisei HarunoUmi' ;
    margin-bottom: 4px;
  }
  .placement-header-subheading {
    font: 700 13px "Mulish";
    margin-top: 4px;
  }
  .placement-header-image {
    display: block;
    margin-top: -11rem;
  }
  .placement-header-image-wrapper-small {
    display: flex;
  }

  .placement-header-paragraph {
    font: 400 13px "Mulish";
    max-width: 330px;
    position: relative;
    top: 36rem;

  }
  .placement-for-students-heading {

    font: 700 30px "Kaisei HarunoUmi";
  }
  .placement-for-students-subheading {
    margin: 16px 12px;
    margin-bottom: 0%;
    font: 700 13px "Mulish";
    max-width: 38rem;
  }
  .placement-for-students-paragraph {
    margin: 0% 22px;
    margin-top: 21px;
    font: 400 12px "Mulish";
  }
  .placement-for-students-placement-rate-heading {
    font: 800 13px "Mulish";
    margin: 0 22px;
    margin-bottom: 16px;
  }
}

/* placement-for-students */
/*******card section********/

.placement-for-student-card-text-section {
  flex: 1;
  background: rgba(238, 238, 238, 0.1);
  height: 16rem;
}
.placement-for-student-card-section {
  padding: 0%;
  margin: 0%;
  

  /* margin: 21px; */
}
.placement-for-student-card-image-section{
  width: 32%;
  height: 160px;
}
.placement-for-student-card {
  border: none;
  height: 160px;
  margin: 20px;
}
.placement-for-student-card-image {
  width: 100%;
  height: 100%;
}

.placement-for-student-card:hover {
  background-color: #6d1d8e;
  border:none;
  margin: 20px;
  transition: 1s;
}
.placement-for-student-card:hover .placement-for-student-student-name {
  color: #ffffff;
}
.placement-for-student-card:hover .placement-for-student-student-job-field {
  color: #ffffff;
}
.placement-for-student-card:hover .placement-for-student-student-company-name {
  color: #ffffff;
}

.placement-for-student-student-name {
  font: 700 20px "Mulish";
}
.placement-for-student-student-job-field {
  font: 400 15px "Mulish";
  color: #727272;
}
.placement-for-student-student-company-name {
  font: 400 18px "Mulish";
}
.placement-for-student-card-text-wrapper {
  margin: 20px 0;
  margin-left: 47px;
}
.placement-for-student-student-job-field {
  margin-top: 8px;
}
.placement-for-student-student-company-name {
  margin-top: 30px;
}

/*****placement page*****/
/*********card section*********/
/* media queries */
@media (max-width: 769px) {
  .placement-for-student-card {
    width: 100wh;
    height: auto;
  }
  .placement-for-student-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .placement-for-student-student-name,
  .placement-for-student-student-job-field,
  .placement-for-student-student-company-name {
    margin: 0;
  }
  .placement-for-student-student-name {
    font: 700 15px "Mulish";
    line-height: 1.5;
  }
  .placement-for-student-student-job-field,
  .placement-for-student-student-company-name {
    font: 400 12px "Mulish";
  }

  .placement-for-student-student-company-name {
    margin-top: 16px;
  }
  .placement-for-students-placement-rate-button {
    width: 156px;
    height: 46px;
    margin-top: 50px;
    margin-bottom: 0%;
    font-size: 15px;
  }
  .placement-for-students-our-student-working {
    font: 700 30px "Kaisei HarunoUmi";
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
  }
  .placement-for-students-our-student-working-paragraph {
    font: 700 13px "Mulish";
  }
  .placement-for-student-card-text-wrapper {
    margin: 0%;
    margin-left: 26px;
    margin-top: 14px;
  }
  /* .placement-for-student-card-text-section{ */
  /* display: flex; */
  /* } */

  .placement-for-student-card-text-section {
    flex: 1 1;
    background: rgba(238, 238, 238, 0.1);
    
    height: 10rem;

  }
  .placement-for-student-card-image-section {
    height: 10rem;
}
}

/* placement-for-students-our-student-working-on */

.placement-for-students-our-student-working-on {
  margin-top: 0px;
}
.placement-student-working-on {
  margin: 96px auto;
}

@media (max-width: 769px) {
  .placement-for-students-our-student-working-on {
    margin-top: 63px;
  }
  .placement-for-students-our-student-working-paragraph {
    margin-top: 21px;
    margin-bottom: 35px;
    max-width: 274px;
  }
  .placement-student-working-on {
    width: 100%;
    height: auto;
    margin-top: 40px;
    margin-bottom: auto;
  }
}
/********************************************/
/*placement for student  modal section*/
/********************************************/
/* modal text need to be edit */

.modal {
  background-color: rgb(109 29 142 / 80%);
}
.modal-content {
  width: 480px;
  box-shadow: 0px 12px 35px #00000073;
  border-radius: 20px;
  height: 440px;
  margin-right: auto;
  margin-left: auto;
}
.form-control{
  padding: 1rem 0.75rem !important;
}
#modalenroll {
  width: 431px;
  box-shadow: 0px 12px 35px #00000073;
  border-radius: 20px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  
}
#modaldemo{
  width: 431px;
  box-shadow: 0px 12px 35px #00000073;
  border-radius: 20px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  background: white;

}


/* nodal media quries */
@media (max-width: 576px) {
  .video{
    width: 415px;
    height: 290px;
  }
  .courses-course-name {
    font: 700 4rem "Kaisei HarunoUmi";
    color: #2b2b2b;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
  .course-detials-header-text h3{
    font: 700 1.3rem "Mulish";
    margin-top: -1.5rem !important;
    margin-bottom: 0rem;
    position: relative;
    top: -3rem;
}
.home-carousel-two-body-heading {
  font: 700 3rem 'Kaisei HarunoUmi';
  color: #6d1d8e;
  margin-top: 2.8rem;
  margin-bottom: 4.1rem;
}
.course-detials-header-text h1 {
  font: 700 3rem "Kaisei HarunoUmi";
  position: relative;
}

  #home-technology-stack {
    padding-bottom: 4rem;
    margin-top: -9rem;
  }
  .course-video-section h4 {
    font: 600 40px "Kaisei HarunoUmi" !important;
    color: #2b2b2b;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}
  .courses-content-right {
    padding-left: 3rem;
    margin-top: 2rem;
  }
  .contact-us-button-section {
    display: flex;
    margin-top: 2rem;
    margin-right: -2rem !important
  }


.view-course {
    font: 800 1.2rem "mulish";
    padding: 1.1rem 3.8rem;
    width: 16rem;
}
  .modal-content {
    margin: auto 16px;
  }
  .modal-body {
    padding: auto 0%;
  }
  .modal-brand-image-logo {
    width: 116px;
    height: 28px;
  }
  .modal-greeting-heading {
    font-size: 29px;
  }
  .modal-student-name {
    font: 800 15px "Mulish";
  }
  .modal-profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .modal-profile-image-section {
    width: 170px;
    height: 170px;
    margin-right: auto;
    margin-left: auto;
}
  .modal-placed-paragraph {
    font: 800 12px "Mulish";
    color: #515151;
  }
  .modal-placed-in {
    font: 700 15px "Mulish";
    color: #6d1d8e;
  }
  .modal-placed-as {
    font: 400 12px "Mulish";
    color: #9c9c9c;
  }
  .modal-placement-student-detials {
    margin: 0 33px;
    margin-top: 11px;
  }
  .modal-student-criteria {
    padding-left: 21px;
  }
  .modal-student-criteria.one {
    padding-top: 20px;
  }
  .modal-student-data.last {
    padding-bottom: 27px;
  }
  .modal-student-criteria {
    font: 800 12px "Mulish";
  }
  .modal-student-data {
    color: #717171;
    font: 400 12px "Mulish";
  }
  .modal-student-footer {
    font: 800 12px "Mulish";
  }
}
/* .overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 170px;
  right: 0;
  height: 99%;
  width: 85%;
  opacity: 0.5;
  background-color: #6d1d8e;
  z-index: 9999;
}
.overlay:hover{
  background-color: transparent;
  z-index: -1;
} */



/* ipad start*/
@media screen and (max-width: 1190px) and (min-width: 780px){
 .ipad-email{
  width:9rem;
  
 }
 .contact-us-paragraph {
  font-size: 12px !important  ;
 }
}
@media all and (device-width: 768px) and (device-height: 1024px) {
.accordion.large-screen{
  display: none;
}
.footer-web-useful-links {
  display: block;
}
.footer-web-contact-address {
  display: block;
}
.about-our-services a{
 font: 400 1.1rem "Mulish" !important;  
}
.footer-courses a{
  font: 400 1.1rem "Mulish" !important;  

}
.footer-hr, .course, .footer-courses a {
  display: block;
}
.ipad-email{
    width: 9rem;
}
.contact-us-heading-contact-form {
  font-size: 49px;
}
  .contact-us-submit-button {
    width: 156px;
    height: 46px;
    font: 800 15px 'Mulish';
    margin-top: 2rem !important;
    margin-bottom: 4rem !important;
    margin-right: 3rem !important;
}
  .training-program-card-circle {
    height: 8rem;
    width: 9rem;
    margin-left: 1rem;
    margin-top: 3.3rem;

}

  .home-carousel-heading {
    text-align: left;
    font: 700 45px 'Mulish' !important;
    margin-bottom: 4rem;
 }
  .home-carousel-subheading {
  font: 700 14px 'Mulish' !important;
  margin-top: -29px;
 }
 .about-card.home-luminar-lab-assurance-card {
  width: 100% !important;
  padding: 0.6rem !important;
 }
 .courses-python-parent {
  position: relative;
  margin-left: 0rem !important;
}
.course-description {
  font-size: 1.5rem;
  color: #464545;
  width: 100%;
  max-width: 60.5rem;
  font-weight: 400;
  font-family: "Mulish", sans-serif;
}
.our-team {
  padding: 0px 0 38px !important;
  background-color: white;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
}
#courses {
  margin-top: 0.4rem;
  padding-top: 0%;
  padding-bottom: 4.5rem;
  height: 37rem;
  max-width: 100%;
  background: #deccff;
}
.courses-paragraph-button-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: center;
  width: 126%;
  margin: 0rem;
  margin-top: 6rem;
}
.search-course {
  line-height: 1;
  width: 100%;
  font: 100 2rem "Mulish";
  margin-left: -11px;
  margin-bottom: 4.6rem;
}
.search-icon {
  width: 2.2rem;
  height: auto;
  right: 7.5rem;
  top: 12rem;
}
.placement-header-image {
  display: block;
  margin-top: 1rem !important;
  width: 100%;
  height: 100%;
}
.placement-header-image-section {
  width: 586px;
  height: 330px;
}
#placement-header {
  margin-top: 4px;
  background-color: #f9f9f9;
  height: 37rem;
}
.course-detials-placement-carousel-image {
  width: 26%;
  height: 108%;
  object-fit: cover;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  object-position: top;
}
.placement-header-paragraph {
  font: 400 13px "Mulish";
  max-width: 330px;
  position: relative;
  top: 0rem;
}
.course-detials-course-info-paragraph-subject-image-two{
  margin-top: 20rem;
}
.placement-for-students-subheading {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0%;
  font: 700 13px "Mulish";
  max-width: 48rem;
}
.placement-header-heading {
  margin-top: 83px;
  font: 700 30px 'Mulish';
  margin-bottom: 4px;
}
.placement-for-students-our-student-working {
  font: 700 30px "Mulish";
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.logoipusum-image {
  max-width: 100%;
  height: auto;
  margin-top: 0px;
}
.placement-for-students-our-student-working-paragraph {
  margin-top: 21px;
  margin-bottom: 35px;
  max-width: 300px;
}
.about-our-services a {
  height:  4rem;
  white-space: nowrap;
  margin-top: 0;
}
.why-luminar-text-section {
  padding-left: 0px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.why-luminar-image-left.img-fluid {
  height: 85vh !important;
  object-fit: cover;
}
#aboutus {
  margin-top: 7.4rem;
  padding-top: 0%;
  padding-bottom: 4.5rem;
  height: 34rem !important;
  max-width: 100%;
  background: #deccff;
}
.footer-certificate-logo-image-two {
  margin-left: 2rem;
  margin-right: 4rem;
  display: block;
}
  .footer-certificate-logo-image-one {
    margin-left: -2rem;
  }
  .footer-certificate-logo{
    margin-top: 0;
  }
  .social-media-icons-wrapper {
    margin-top: -20px;
}
  .job-orianted-section-column {
    padding: 0 !important; 
    display: block;
    flex-direction: column;
    justify-content: center;
}
.job-orianted-section-column.large-device {
  display: block; 
}
button.view-all-button-white {
  font: 800 1.2rem "Mulish";
  height: 3.8rem;
  width: 15.6rem;
  margin-left: 3rem;
  margin-top: -5rem
}
.course-detials-course-info-row-two-wrapper {
  display: block;
  margin-top: 17rem;
}
.contact-us-container {
  display: -webkit-box !important;
  flex-direction: column  !important;
}
.recaptcha-input {
  margin-top: -72px;
  margin-left: 5rem;
}
.contact-recaptcha {
  margin-left: -11rem;
}
/* .contact-us-submit-button {
  width: 156px;
  height: 46px;
  font: 800 15px 'Mulish';
  margin-top: 4rem;
  position: relative;
  top: 3rem;
  right: 23rem !important;
  margin-bottom: 6rem !important;
} */
.home-carousel-two-image-section {
  display: block;
}
.home-carousel-two-white-box {
  position: absolute;
  background-color: #ffffff;
  width: 17.8rem;
  height: 17.8rem;
  border-radius: 1rem;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0rem 0.3rem 3.5rem #0000001a;
}
.home-carousel-two-image-section-two {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 13rem;
  margin-top: -3rem;
  width: 17rem;
}
.home-carousel-two-image-section-one {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
  margin-left: -3rem;
    margin-top: -1rem;
  width: 17rem;
}
.home-technology-stack-carousel-indicators-two.carousel-indicators {
  position: absolute;
  left: 175px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 0;
  display: flex;
  justify-content: center;
  bottom: 0;
}
.home-carousel-two-image-one, .home-carousel-two-image-two {
  width: 100%;
  height: 100%;
  z-index: 3;
  object-fit: cover;
}
.home-carousel-two-image-section {
  position: relative;
  margin-top: 25px;
  margin-right: -6rem;
}


/* course details page  */
.course-detials-heading {
  font-size: 4.5rem !important;
}
.course-detials-subheading {
  font-size: 2rem !important;
  font-family: "Mulish";
  font-weight: 700;
  color: #6d1d8e;
  margin-top: -3rem !important;
}
.course-detials-image-two-section, .course-detials-image-one-section {
  width: 56rem;
  height: 30rem;
}
.course-detials-course-certificate-image {
  width: 111rem;
  object-fit: cover;
  height: 47.4rem;
}
.placement-indicator{
  
    bottom: -4rem !important;
    left: 0rem !important;
    display: flex;

}
.course-detials-placement-carousel-column {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 154px;
  height: 158px;
  margin-top: 5rem;
}
.course-detials-content h2 {
  font: 700 20px 'Mulish';
  color: #010100;
  max-width: 72rem;
  padding-left: 6rem;
}
.cousredetails-section h1 {
  margin-top: 17px;
  color: #6D1D8E;
  font: 700 23px 'Mulish';
}
/* .course-module-lessons-content {
  margin-top: 10px;
  margin-right: 15px;
  overflow-y: scroll;
} */
.cousredetails-section p {
  margin-top: 0rem;
}
.job-oriented-card:hover {
  background-color: #fff;
  box-shadow: 0rem 0.3rem 3rem #170120a1;
  opacity: 1;
  transition: 0.5s;
}
.course-badge {
  height: 8rem !important;
}
.course-detials-course-certificate-detials {
  margin-top: 40px !important;
    margin-bottom: 0;
}
.course-detials-course-certificate-heading {
  font: 700 20px 'Mulish';
}
.carousel-indicators {
  bottom: 8rem;
  left: -59.5rem;
  display: flex;
}
.home-registration-content-section {
  background-color: #f9f9f9;
  margin-top: -8rem;
}
.home-registration-contents {
  margin-bottom: 13rem;
  height: 5rem;
  width: 692px;
  border-radius: 41px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.courses-paragraph-button {
  background-color: #6d1d8e;
  color: #ffffff;
  width: 12rem;
  height: 4rem;
  margin-right: 16px;
  border-radius: 3rem;
  font: 800 1.5rem "Mulish";
}
.course-module-heading {
  font: 800 13px "Mulish";
  color: #6d1d8e;
  height: auto;
}
.view-course {
  background-color: #6d1d8e;
  color: #fff;
  font: 800 1.5rem "Mulish";
  border-radius: 3rem;
  width: 15rem;
  margin: 3.9rem 0rem;
  padding: 1.2rem 1rem;
}
.home-corousel-content h1 {
  text-align: left;
  color: #6d1d8e;
  margin-top: 11.2rem;
  font: 700 4.5rem 'Kaisei HarunoUmi';
}
.home-corousel-content h3 {
  text-align: left;
  font: 700 1.5rem "Mulish";
  margin-top: -3.5rem;
  color: #6d1d8e;
}

.training-program-card-one-heading {
  min-height: 11rem;
}
.job-oriented-card {
  max-width: 36.6rem;
  margin: 1rem;
  padding: 2.4rem;
}
.register-with {
  position: relative;
  right: 27.5rem;
  top: 2.8rem;
  white-space: nowrap;
  font-size: 1rem;
  color: white;
  opacity: 0.7;
}
.register-cnu {
  position: relative;
  right: 20rem;
  top: 2.8rem;
  font-size: 0.9rem;
  color: white;
  opacity: 0.7;
  white-space: nowrap;
}
.course-detials-header-button {
  background-color: #6d1d8e;
  color: #fff;
  font-family: "Mulish";
  font-weight: 800;
  font-size: 1.5rem;
  padding: 0 !important;
  border-radius: 5rem;
  margin-right: 1.4rem;
  margin-top: 0rem;
  height: 4rem;
  width: 13rem;
}
#courses-detials {
  height: 40rem !important;
}
.job-oriented-card-title {
  font: 700 1.5rem "Mulish";
  color: #6d1d8e;
  margin-bottom: 2.4rem;
  min-height: 5rem;
}
.course-detials-header-text h1 {
  color: #6d1d8e;
  font-size: 3rem;
  font-weight: 700;
  font-family: "Kaisei HarunoUmi";
}
.course-detials-header-text h2 {
  font-size: 1.5rem;
  font-family: "Mulish";
  font-weight: 700;
  color: #6d1d8e;
  margin-top: -1rem;
  text-align: start !important;
}
.course-detials-header-button-wrapper {
  display: flex;
  margin-top: 3rem;
}
.home-corousel-indicators{
 
    bottom: 6rem;
    left: -53.5rem;
    display: flex;
}
.home-our-mission-section-highlight-para {
  color: #6d1d8e;
  font-size: 40px;
  line-height: 1.1;
  font-family: "Mulish";
  font-weight: bold;
}
.image-margin {
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 8rem !important;
}

.mission-image-overlay-parent {
  position: relative;
  z-index: 2;
  display: flex;
  height: 16rem;
  width: 16rem;
  margin-top: 2rem !important;
}
.home-our-mission-section-content {
  margin-top: 7rem;
}
.mission-image-overlay {
  left: -1rem;
  top: 8rem;
}
p.mission-image-name {
  display: block;
  font: 700 2rem "Mulish" !important;
}
p.mission-image-name {
  display: block;
  font: 700 2.5rem "Mulish";
  color: #fff;
  margin-top: -10rem;
}
.margin-image-role, .mission-education-detials, .mission-placement {
  display: block;
  font: 400 1rem "Mulish";
  color: #fff;
  opacity: 0.8;
  margin-bottom: 0rem;
}
.contact-us-heading{
  font: 700 30px 'Mulish';
  margin: 0%;
  margin-top: 0px;
  margin-bottom: 38px;
}
.img-fluid.circle-icon{
  width: 64%;
  height: 64%;
  object-fit: cover;
}
.job-oriented-card:hover.img-fluid.circle-icon {
  filter: brightness(20);
}
.image-dot {
  position: absolute;
  right: -9.5rem;
  top: -1rem;
  height: 14rem;
  width: 30rem;
}
.why-luminar-heading {
  font-size: 33px !important;
  font-family: "Barlow";
  font-weight: 700;
  color: #6d1d8e;
}
.social-media-icons {
  width: 4.3rem !important;
  height: 4.3rem;
  flex: 1 1;
}
.social-media-iconss {
  width: 5rem;
  height: 5rem;
  flex: 1 1;
  margin-top: -1px;
  margin-right: 8px;
}
.courses-python-parent {
  margin-left: 14rem;
  width: 72rem;
  height: 40rem;
}
.join-now-callus {
  text-align: center;
  font: 700 1.3rem "Mulish";
  width: 65.5rem;
  padding-top: 2.1rem;
  margin: 0%;
}
.aboutus-card {
  margin-top: -4rem !important;
  margin-bottom: 2rem;
}
.courses-sub-heading {
  color: #6d1d8e;
  margin-top: -2rem;
}
}

#courses-detials {
  background: #fafafa;
  width: 100%;
  height: auto;
  margin-top: 0.4rem;
  position: relative;
  padding: 0%;
}

@media (max-width:768px) {
  .largedevice{
    display: none;
  }
  .image-circle-profile{
    border-radius: 50%;
  }
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
  outline: none !important;
}


.btn-modal-color {
  background-color: #6d1d8e;
  color: white;
}
.btn.btn-secondary{
  background-color: #6d1d8e;
}

/* 
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #6d1d8e;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  border: 50%;
  margin-top: -50px;
  margin-left: -100px;
  background-color: white;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */
.loadersection{
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.loader {
  width: 4vmax;
  height: 4vmax;
  border-right: 4px solid #6d1d8e;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  border: 50%;
  /* margin-top: -50px;
  margin-left: -100px; */
}
.loader:before,.loader:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #6d1d8e;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }
  
  .loader:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #6d1d8e;
    animation: none;
  }


@keyframes spinLeft {
  from {transform:rotate(0deg);}
  to {transform:rotate(720deg);}
}

@keyframes spinRight {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}
.course-video-section h4{
  font: 600 40px  "Kaisei HarunoUmi";
  color: #2b2b2b;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.course-video-section p{
  font: 400 1.5rem "Mulish";
max-width: 41rem;
}
.course-video-section h2{
  font: 700 1.4rem "Mulish";
max-width: 40rem;
color: #6d1d8e;

}
.course-video-section h3{
  font: 400 1.4rem "Mulish";
max-width: 40rem;
color: #6d1d8e;
}



@media all and (device-width: 360px) and (device-height: 740px) {

  .home-corousel-content h1 {
    font: 700 3rem "Kaisei HarunoUmi";
    margin-top: -27.3rem;
}
.home-corousel-content p {
  position: relative;
  top: 34rem;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  min-height: 10rem;
}
.course-detials-course-certificate-detials {
  position: relative;
  top: -35rem !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px !important;
  padding: 0;
}
.course-detials-content h2 {
  position: relative;
  top: -36rem;
  font: 700 16px "Kaisei HarunoUmi";
  color: #010100;
  max-width: 38rem;
  padding-left: 0rem;
  text-align: center;
}
}
button:focus {outline:0 !important;}
button:hover {outline:0 !important;}