.refund{
    margin-top: 5rem;
}
.refund h4{
    color: #6d1d8e;
    font-size: 18px;
}
.refund p{
    font: 500 15px 'Mulish';
    text-align: justify;
    padding-top: 1rem;
}
.refund a{
    color:#6d1d8e !important;
    font: 700 12px 'Mulish';
}