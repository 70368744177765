.terms{
  margin: 1.5rem;
}
.terms h4{
  color: #6d1d8e;
  font-size: 18px;
}
.terms p{
  font: 500 15px 'Mulish';
  text-align: justify;
}
.terms a{
  color:#6d1d8e ;
}
