.gallery-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
  }
  /* .gallery-images:hover{
   transition: 1s;
   transform: scale(1.5);
   z-index: 1;
  }
  .gallery-image:hover .gallery-images{
    z-index: 999;
  } */
.gallery-images{
    height: 30rem;
    width: 50rem !important;
    object-fit: cover;

}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
    color: #5e068a;
    font-size: 25px;
}
@media (max-width:576px) {
  .gallery-images{
    height: 11rem;
    width: 50rem !important;
    object-fit: cover;
  
}
.gallery-section{
  min-height: 10rem !important;
  margin-top: 5rem;
}
.gallery-header{
  margin-top: -16rem !important;
}
.gallery-image {
  height: 100%;
  width: 142%;
}
.modals-content {

  width: 39rem !important;

}
}




.gallery-section{
  min-height: 38rem;
  margin-top: 5rem;
}
.gallerymodal{ /* Hidden by default */
  display: none;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background-color:white;  */
  background-color: rgba(0, 0, 0, 0.8); 
}
.modals-content {
  margin: auto;
  width: 70rem;
  height: 50rem;
  max-width: 700px;
  display: block;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  text-align: center;
  color: #ccc;
  font: 500 15px 'Mulish';
}

/* Add Animation */
.modals-content, #caption {  
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.close-gallery {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close-gallery:hover,
.close-gallery:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}



