.trainingprogramsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.trainingprogramsContainer img {
    width: 100%;
    height: 100%;

}