
#mainMenu {
  position: relative;
}

.navbar-brand {
  width: 36rem;
}
.navbar-toggler-icon {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .navbar.navbar-expand-lg {
    padding: 0%;
    background-color: #fff;
  }
  .navbar-brand {
    width: 20rem;
    padding: 1rem 0;
    padding-left: 1.6rem;
  }
}

.logos-navbar{
  width: 26.9rem;
  object-fit: cover;
  height: 6.4rem;
  margin-left: -4rem;
}

div#navbarNav {
  justify-content: end;
}

.nav-item {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  text-align: center;
}

.navbar-nav li a {
  color: #313131;
  font: 400 2rem "Segoe UI";
  text-align: center;
}

.navbar-nav li a:hover {
  color: #6d1d8e;
}

.nav-item.active a {
  color: #6d1d8e;
  font-weight: bold;
  letter-spacing: 0;
  
}

.nav-item.active a::after {
  transform: scaleX(1);
}

/* bottom color */


#mainMenu {
  position: relative;
}

.navbar-brand {
  width: 36rem;
}
@media (max-width: 576px) {
  .navbar.navbar-expand-lg {
    padding: 0%;
    background-color: #fff;
  }
  .navbar-brand {
    width: 20rem;
    padding: 1rem 0;
    padding-left: 1.6rem;
  }
  .logos-navbar {
    width: 15.6rem;
    object-fit: cover;
    height: 3.7rem;
    margin-left: -1rem;
}
}



div#navbarNav {
  justify-content: end;
}

.nav-item {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  text-align: center;
}

.navbar-nav li a {
  color: #313131;
  font: 400 1.8rem "Segoe UI";
  text-align: center;
  font-weight: 600;
}

.navbar-nav li a:hover {
  color: #6d1d8e;
}

.nav-item.active a {
  color: #6d1d8e;
  font-weight: bold;
  letter-spacing: 0;
}

.nav-item.active a::after {
  transform: scaleX(1);
}

/* bottom color */

li > a:after {
  padding-bottom: 0.2rem;
  content: "";
  display: block;
  height: 0.15rem;
  background: #6d1d8e;
  transform: scaleX(0);
  transition: transform 0.3s;
}

li > a:hover:after {
  transform: scaleX(1);
  transition: transform 0.3s;
}

@media (max-width: 768px) {
  .navbar-nav {
    display: inline-flex;
    align-items: flex-start;
    margin-left: 1.3rem;
    margin-bottom: 1.5rem;
  }
}

