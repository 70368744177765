

/*******************************/
    /* contact us page*/
/*******************************/


  @media (max-width:768px){
    #contact-us{
      background-image: none;
      background-color:#F9F9F9 ;
    }
  }
  
  /************************************/
          /* contact-us container */
          /* contact-us-heading */
  /************************************/
  .contact-us-container{
    display: flex;
  }
  .contact-us-heading{
    font-family: "Kaisei HarunoUmi";
    font-size: 55px;
    color: #5F078A;
    font-weight: 600;
    margin-bottom: 47px;
    padding-left: 10px;
  }
  
  .contact-us-circle {
    background: #F3F3F3;
    height: 83px;
    width: 83px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    margin-bottom: 28px;
  }
  
  .contact-us-content{
    cursor: pointer;
    width: 44rem;
  }
  
  .contact-us-subheading{
    color: #6D1D8E;
    font-family: 'Mulish';
    font-weight: 700;
    font-size: 15px;
    padding-top: 3px;
  }
  
  .contact-us-paragraph{
    color: #505050;
     font-family: 'Mulish';
     font-weight: 400;
     font-size: 15px;
  }
  
  .contact-us-text{
    flex: 1 1;
  }
  
  
  .contact-us-social-media{
    display: block;
    padding-left: 0%;
    position: relative;
    margin-top: 63px;
  }
  
  .social-icons{
   margin-left: -5px;
  
  }
  .social-media-icon{
    width: 47px;
  }
  
  .contact-us-connect{
    color:#363636 ;
    font-size: 25px;
    font-family: "Mulish";
    font-weight: 800;
  }
  
  .contact-us-submit-button{
    background-color: #fff;
    color:#5F078A;
    padding: 4px 28px;
    display: inline-block;
    border: 2px solid #5F078A;
    border-radius: 30px;
    font-family: 'Mulish';
    font-weight: 800;
    font-size: 20px;
    height: 46px;
    width: 145px;
    margin-left: -10rem;

  }
  .contact-us-checkbox{
    background-color: #fff;
    color:#515151;
    padding: 12px 50px;
    display: flex;
    align-items: center;
    width: 193px;
    border: 2px solid #5F078A;
    margin-right: 20px;
  }
  .form-check-label{
    padding-left: 14px;
  }
  /* contact us right  */
  .contact-us-column-right-heading{
    color: #363636;
    font-family: "Kaisei HarunoUmi";
    font-weight: 600;
    font-size: 30px;
    margin: 0%;
    margin-bottom: 13px;
  }
  .contact-us-column-right-paragraph{
    color: #515151;
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 15px;
    max-width: 520px;
  }
  
  .form-control.payment-input-field{
    height: 50px;
    width:500px
  }
  
  /*******************************************/
  /* contact contact-us-container media query section */
  
  
  @media (max-width:768px){
    #contact-us{
      padding-top:60px ;
      height: 100%;
    }
    .contact-us-container{
      display: flex;
      flex-direction: column-reverse;
    }
    .contact-us-column-right-heading{
      font: 700 20px 'Kaisei HarunoUmi'; 
      padding: 0%; 
      margin-top: 0px; 
    }
    .contact-us-column-right-paragraph{
      font: 400 12px 'Mulish';
      color: #515151;
    }
    .form-control.contact-us-input-field{
      height: 47px;
      border:1px solid #BBBBBB;
      border-radius: 7px;
      padding: 16px 18px;
    }
    textarea.form-control{
      height: 229px;
    }
    .contact-us-heading{
      font: 700 30px 'Kaisei HarunoUmi';
      margin: 0%;
      margin-top: 35px;
      margin-bottom: 38px;
    }
    
    .contact-us-circle{
      width: 69px;
      height: 69px;
    }
    .contact-us-subheading{
      font: 700 15px 'Mulish';
    }
    .contact-us-paragraph{
      font: 400 11px "Mulish";
    }
    .contact-us-social-media{
      display: none;
    }
    .form-check-label{
      flex: 1;
      
    }
    .contact-us-checkbox{
      width: 156px;
      height: 46px;
      font-size: 10px;

      
    }
    .contact-us-submit-button{
      width: 156px;
      height: 46px;
      font: 800 15px 'Mulish';
      margin-top: 2rem;
      margin-bottom: 4rem;
      margin-right: 0rem;
      margin-left: 0;
    
     }
  
   
  }
  .rzp-button1{
    width: 156px;
    height: 46px;
    font: 800 15px 'Mulish';
    display: flex;
  }

  
.submit-button-mobile{
  display: none !important;
}

/*********************************************/
/* courses-why-luminar technolad
------------------------------------- */

#courses-why-luminar {
  background-color: #f9f9f9;
  padding-bottom: 0%;
  padding-top: 0%;
}

.courses-why-luminar {
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 951px; */
}

.why-luminar-image-left.img-fluid {
  height: 900px;
  /* height: 95vh; */
   object-fit: cover;
  /* width: 590px; */

}

.why-luminar-text-section {
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.why-luminar-heading {
  font-size: 46px;
    font-family: "Kaisei HarunoUmi";
    font-weight: 700;
    color: #6d1d8e;
}

.why-luminar-paragraph {
  font-size: 14px;
  color: #515151;
  text-align: left;
  width: 100%;
  max-width: 479px;
  padding-top: 0px;
  padding-bottom: 22px;
}

.why-luminar-list {
  list-style-type: none;
  font-size: 16px;
  color: #515151;
  font-family: "Mulish";
  font-weight: 700;
  text-align: left;
  line-height: 2;
}

/* ul icons */
ul.why-luminar-list li {
  /* background-image: url(../images/courses.html/Group45.png); */
  background-repeat: no-repeat;
  margin-top: 5px;
  padding-left: 38px;
}

@media (max-width:576px){
.courses-why-luminar-image-section{
 display: none;
 padding: 0%;
}
.why-luminar-text-section{
  padding: 0% 15px;
}
.submit-button-mobile{
  display: block !important;
}
.submit-button{
  display: none;
}
.why-luminar-heading{
  font: 700 30px 'Kaisei HarunoUmi';
  text-align: left;
  width: 210px;
  padding: 0%;
}
.why-luminar-paragraph{
  font: 400 12px 'Mulish';  
}

ul.why-luminar-list li{ 
  font: 700 12px 'Mulish';
  line-height: 2.7;
  margin-top: 0%;
}
}


.btn-pay{
  background-color: #6d1d8e;
  color: white;
  font: 700 18px 'Mulish';
  border-radius: 5px;
}

.form-control.payment-input-field.pay {
  height: 50px;
  width: 500px;
  border-radius: 5px;
}
.card-pay{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 5rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 12px 35px #00000073
}
.btn-div{
  padding-top: 4rem;
}
@media (max-width:576px) {
  .card-pay{
    width: 29rem;
    height: auto;
 
  }
  .form-control.payment-input-field.pay {
    height: 46px;
    width: 90%;
    border-radius: 5px;
}
.btn-div {
  padding-top: 1rem;
}
}