/****************************************/
          /* placement page */
          /* header */
/****************************************/

#placement-header{
    margin-top: 74px;
    background-color: #F9F9F9;
  }
  .placement-body{
    margin-left: auto;
      margin-right: auto;
  }
  
  /* color-section  color:#6D1D8E ; */
  .placement-header-heading,
  .placement-header-subheading,
  .placement-for-students-heading,
  .placement-for-students-our-student-working,
  .placement-for-student-student-name{
    color:#6D1D8E ;
  }
  
  /* color-section  color:#515151 ; */
  .placement-header-paragraph,
  .placement-for-students-paragraph,
  .placement-for-student-student-company-name
  {
    color: #515151;
  }
  
  /*  font: 400 15px 'Mulish'; */
  .placement-header-paragraph,
  .placement-for-students-paragraph{
    display: block;
    font: 400 15px 'Mulish';
  }
  
  /* display block align center */
  .placement-for-students-subheading,
  .placement-for-students-placement-rate-heading,
  .placement-for-students-placement-rate-button,
  .placement-for-students-our-student-working-paragraph{
    display: block;  
    margin-left: auto;
    margin-right: auto;
  }
  
  /* same font 700 55px 'Kaisei HarunoUmi'; */
  .placement-for-students-heading,
  .placement-for-students-our-student-working{
    font: 700 55px 'Kaisei HarunoUmi';
  }
   /* font: 700 20px 'Mulish'; */
  .placement-for-students-subheading,
  .placement-for-students-our-student-working-paragraph{
    font: 700 20px 'Mulish';
  }
  .placement-header-heading{
    margin-top: 155px;
    font: 700 65px 'Kaisei HarunoUmi';
  }
  .placement-header-subheading{
    font: 700 25px 'Mulish';
  }
  .placement-header-paragraph{  
    margin-top: 45px;
    max-width: 455px;  
  }
  
  .placement-header-image{
    width:100%;
    height: 100%;
    object-fit: cover;
  }
  .placement-header-image-section{
    width: 600px;
    height: 550px;
  }
  .placement-for-students-subheading{
    max-width: 582px;
    margin-top: 13px;
  }
  .placement-for-students-paragraph{
    margin: 0;
    margin-top: 60px;
  }
  #placement-for-students-card-section{
    padding-top: 0%;
  }
  .placement-for-students-placement-rate-heading{
    font: 800 20px 'Mulish';
    margin-bottom: 60px;
  }
  .placement-for-students-placement-rate-button{
    display: inline-block;
    width: 194px;
    height: 58px;
    border-radius: 50px;
    background-color: #fff;
    color: #6D1D8E;
    border: 2px solid #6D1D8E;
    margin:85px auto ;
  }
  .placement-for-students-our-student-working-paragraph{
    max-width: 582px;
    margin-top: 13px;
  }
  
  /*****placement page*****/
  /*********header and body*********/
  /* media queries */
  @media(max-width:768px){
  /* #placement-for-students{ */
    /* margin: 0% 2px; */
    /* } */
  .placement-header-image{
    width: 100%;
    height: auto;
  }
  .placement-header-heading{
    margin-top: 46px;
    font: 700 30px 'Kaisei HarunoUmi' ;
  }
  .placement-header-subheading{
    font:700 13px 'Mulish' ;
    margin-top: 4px;
  }
  .placement-header-paragraph{
    font: 400 12px 'Mulish';
    margin-top:25px;
    max-width: 300px;
  }
  .placement-for-students-heading{
    margin-top: 13px;
    font: 700 30px 'Kaisei HarunoUmi';
  }
  .placement-for-students-subheading{
    margin: 16px 11px;
    margin-bottom: 0%;
    font: 700 13px 'Mulish';
  }
  .placement-for-students-paragraph{
    margin: 0% 18px;
    margin-top: 21px;
    font: 400 12px 'Mulish';
  }
  .placement-for-students-placement-rate-heading{
    font: 800 13px 'Mulish';
    line-height: 2;
  }
  }
  
  
  /* placement-for-students */
  /*******card section********/
  
  .placement-for-student-card-text-section{
    flex: 1;
  }
  .placement-for-student-card-section{
    padding: 0%;
    margin: 0%;
    /* margin: 21px; */
  }
  .placement-for-student-card{
    background-color:#EEEEEE ;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 182px;
    margin: 20px;
    
  }
  .placement-for-student-card-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  
  .placement-for-student-card:hover {
    background-color:#6D1D8E ;  
    border: none;
  }
  .placement-for-student-card:hover
  .placement-for-student-student-name{
    color: #FFFFFF;
  }
  .placement-for-student-card:hover
  .placement-for-student-student-job-field{
    color: #FFFFFF
  }
  .placement-for-student-card:hover
  .placement-for-student-student-company-name {
    color: #FFFFFF
  }
  
  .placement-for-student-student-name{
    font: 700 23px 'Barlow';
  }
  .placement-for-student-student-job-field{
    font: 400 15px "Mulish";
    color:#727272 ;
  }
  .placement-for-student-student-company-name{
    font: 400 19px 'Mulish';
  }
  .placement-for-student-card-text-wrapper{
    margin: 20px 0;
    margin-left: 47px;
  }
  .placement-for-student-student-job-field{
    margin-top: 8px;
  }
  .placement-for-student-student-company-name{
    margin-top: 37px;
  }
  
  
  
  /*****placement page*****/
  /*********card section*********/
  /* media queries */
  @media(max-width:769px){
    .placement-for-student-card{
      width: 100wh;
      height: auto;
    }
    .placement-for-student-card-image{
      width: 104px;
      height: 100%;
    }
    .placement-for-student-student-name,
    .placement-for-student-student-job-field,
    .placement-for-student-student-company-name{
      margin: 0;
    }
    .placement-for-student-student-name{
      font: 700 15px 'Barlow';
      line-height: 1.5;
    }
    .placement-for-student-student-job-field,
    .placement-for-student-student-company-name{
      font: 400 12px 'Mulish';
    }
    
    .placement-for-student-student-company-name{
      margin-top: 16px;
    }
    .placement-for-students-placement-rate-button{
      width: 159px;
      height: 46px;
      margin-top: 50px;
      margin-bottom: 0%;
    }
    .placement-for-students-our-student-working{
      font: 700 30px 'Kaisei HarunoUmi';
      max-width: 190px;
      margin-left: auto;
      margin-right: auto;
    }
    .placement-for-students-our-student-working-paragraph{
      font: 700 13px 'Mulish';
    }
    .placement-for-student-card-text-wrapper{
      margin: 0%;
      margin-left: 26px;
      margin-top: 14px;
    }
    /* .placement-for-student-card-text-section{ */
      /* display: flex; */
    /* } */
  }
  
  /* placement-for-students-our-student-working-on */
  
  .placement-for-students-our-student-working-on{
    margin-top: 66px;
   
  }
  .placement-student-working-on{
    margin: 96px auto;
  }
  
  @media(max-width:769px){
    .placement-for-students-our-student-working-on{
      margin-top: 63px;
    }
    .placement-for-students-our-student-working-paragraph{
      margin-top: 21px;
      margin-bottom: 35px;
      max-width: 270px;
    }
    .placement-student-working-on{
      width: 100%;
      height: auto;
      margin-top: 40px;
      margin-bottom: auto;
    }
  }
  /********************************************/
      /*placement for student  modal section*/
  /********************************************/
  /* modal text need to be edit */
  
  .modal{
    background-color:rgb(109 29 142 / 80%) ;
    
  }
  .modal-content.placement-model-content{
    width:700px !important;
    box-shadow: 0px 12px 35px #00000073;
    border-radius: 20px;
    height: auto;
  }
  @media (max-width:576px) {
    .modal-content.placement-model-content{
    width: 328px !important;
    margin: auto !important;
    }
  }
  .placement-modal-body{
    width: 100% !important;
  }
  .modal-brand-image-logo{
    width: 269px;
    height: 64px;
    margin-top: 3rem;
  }
  .modal-greeting-heading{
    font-size: 60px;
    color: #6D1D8E;
  }
  .modal-student-name{
    font: 800 30px 'Mulish';
    color: #505050;
  }
  .modal-profile-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .modal-profile-image-section{
    width: 250px; 
    height: 250px;
    margin-right: auto;
    margin-left: auto;

  }
  .modal-brand-image-logo,
  .modal-profile-image{
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-greeting-heading
  {
    text-align: center;
    font-family: 'Pacifico', cursive;
  }
  .modal-placed-paragraph{
    color: black;
    font: 600 14px "Mulish";
  }
  .modal-placed-in{
    color: #6D1D8E;
    font: 600 2.5rem 'Mulish';
  }
  .modal-placed-as{
    color:#9C9C9C ;
    font-size: 1.5rem;
  }
  
  .modal-placement-student-detials{
   background-color: #F9F9F9;
   margin-left: 62px; 
   margin-right:62px;
   padding-top: 3rem;
  }
  .modal-student-data{
    display: block;
    font: 400 1.2rem 'Mulish';
    padding-top: 1.5rem;
  }
  .modal-student-criteria{
    padding-left: 35px;
    font: 600 1.5rem 'Mulish';
  }
  /* for adding padding to top */
  .modal-student-criteria.one{
   padding-top: 35px;
   font: 600 1.5rem 'Mulish';
  }
  .modal-student-data.last{
    padding-bottom: 40px;
  }
  .modal-student-footer{
    color:#5F078A;
    font: 800 20px 'Mulish';
  }
  @media (min-width: 576px)
  {
    .modal-dialog {
      max-width: 762px;
      }
  }
  
  /* nodal media quries */
  @media (max-width:576px){
    .modal-content{
      margin: auto 16px;
    }
    .modal-body{
      padding: auto 0%;
    }
    .modal-brand-image-logo{
      width: 116px;
      height: 28px;
    }
    .modal-greeting-heading{
      font-size: 29px;
    }
    .modal-student-name{
      font: 800 15px 'Mulish';
    }
    .modal-profile-image  {
      width: 155px;
      height: auto;
    }
    .modal-placed-paragraph{
      font: 800 12px 'Mulish';
      color: #515151;
    }
    .modal-placed-in{
      font: 700 15px 'Mulish';
      color: #6D1D8E;
    }
    .modal-placed-as{
      font: 400 12px 'Mulish';
      color: #9C9C9C;
    }
    .modal-placement-student-detials{
      margin: 0 33px;
      margin-top: 11px;
    }
    .modal-student-criteria{
      padding-left: 21px;
    }
    .modal-student-criteria.one{
      padding-top: 20px;
    }
    .modal-student-data.last{
      padding-bottom: 27px;
    }
    .modal-student-criteria{
      font: 800 12px 'Mulish';
    }
    .modal-student-data{
      color: #717171;
      font: 400 12px 'Mulish';
    }
    .modal-student-footer{
      font: 800 12px "Mulish";
    }
  }

  .insta{
    display:flex;
    align-items: center;
    justify-content:space-between;
    flex-wrap: wrap;
    overflow: scroll;
    height: 64rem;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .insta::-webkit-scrollbar-track
  {
     
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  .insta::-webkit-scrollbar
  {
    width: 8px;
    background-color: #F5F5F5;
  }
  .insta a img{
    height:300px;
    width: 300px;
    padding-bottom: 15px;
    /* margin-top: -37px; */
    object-fit: cover;
    
  }
  @media (max-width:768px){
    .insta{
     justify-content: center;
    }
    .home-card-logos-placement{
     margin: 0;
    }  
    }

.home-card-logos-placement{
  margin-top: 4rem;
  margin-bottom: 4rem;
}    