
  .thank{
    border-radius:200px; 
    height:200px; width:200px;
    background: #F8FAF5; 
    margin:0 auto;
  }
    .card-thank h1 {
      color: #6d1d8e;
      font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
      font-weight: 900;
      font-size: 40px;
      margin-bottom: 10px;
    }
    .card-thank p {
      color: #6d1d8e;
      font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
      font-size:15px;
      margin: 0;
    }
    .card-thank i {
    color: #6d1d8e;
    font-size: 100px;
    line-height: 200px;
    margin-left:50px;
  }
  .card-thank {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    display: inline-block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50rem;
  }
  @media (max-width:576px) {
    .card-thank {
      width: 28rem;
      margin-left: -5rem;
      height: 53rem;
      padding: 53px 26px;
  }
      .card-thank p{
        font-size:14px;
      }
      .card-thank h1 {
        font-size: 30px;
      }
      .thank{
        height: 150px;
        width: 150px;
      }
      .card-thank i {
        color: #6d1d8e;
        font-size: 74px;
        line-height: 155px;
        margin-left: 40px;
    }
  }
  .modal-backdrop {
display: none;
  }