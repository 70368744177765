#aboutus{
  margin-top: 0.4rem;
  padding-top: 0%;
  padding-bottom: 4.5rem;
  height: auto;
  max-width: 100%;
  background: #deccff;
}


.about-ar{
  padding-bottom: 0rem !important;
}
.about-container{
    height: 500px;
    overflow: scroll;
    overflow-x: hidden;
}
.about-container::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.about-container::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}
.about-area p {
    line-height: 28px;
    margin-bottom: 12px;
    font-size: 15px;
    max-width: 565px;
}
.about-area h3 {
    font-size: 32px;
    margin-bottom: 20px;
    padding-top: 36px;
    color: #5e068a;
    font-weight: 700;
    max-width: 530px;
    line-height: 1.2;
    text-transform: capitalize;
}

.about-area h4{
    font-size: 18px;
}
.about-area li{
    font-family: 'Mulish';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}
.why-luminar-list.aboutus{
    padding-top: 2rem;
    line-height: 31px;
}
.aboutus-card{
    margin-top: -3rem;
    margin-bottom: 2rem;
}


.aboutus-card{
    font-family: tahoma;
    background-position: center;
    display: flex;
    align-items: center;
    overflow: scroll;
    overflow-x: hidden;
  }

  .aboutus-card::-webkit-scrollbar-track
{
   
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.aboutus-card::-webkit-scrollbar
{
    display: none;
	width: 10px;
	background-color: #F5F5F5;
}
  .our-team {
    padding: 60px 0 70px;
    background-color: white;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 0rem;
    width: auto;
    height: 37rem;
  }


  .modals-content {
    background-color: #5e068a;
    width: 39rem !important;
  
  }

  .our-team:hover {
    padding: 60px 0 70px;
    box-shadow: 0 3px 35px #0000001A ;
    transition: 0.5s
  }
  .our-team .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #5e068a;
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.7s linear 0s;
  }
  
  .our-team:hover .picture::before {
    height: 100%;
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #5e068a;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 125%;
    height: 100%;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.7s ease 0s;
    object-fit: cover;
  }
  
  .our-team:hover .picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.7);
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: #4e5052;
    text-transform: capitalize;
    font-family: 'Mulish';
  }
  .our-team .name{
      font: 700 18px 'Mulish';
      text-align: center !important;
  }
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.7s ease 0s;
  }
  
  .our-team:hover .social {
    bottom: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color: #5e068a;
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  
  .profilemodal{ /* Hidden by default */
    display: none;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background-color:white;  */
    background-color: rgba(0, 0, 0, 0.8); 
    align-items: center;
  }

.about-card-header{
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 6rem 3rem;
    font: 600 3.5rem 'Kaisei HarunoUmi';
    color: #5e068a;
}
.about-icon{
    height: 35px;
    margin-top: 3px;
    margin-right: -5px;
    padding: 0rem 1rem;
}



@media (max-width:576px) {
  .aboutus-card{
    padding-top: 89rem;
    height: 50rem;
  }
  .about-area h3 {
    margin-top: 0rem !important;
  }
  .our-team {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
  }
  .trainer-profile {
    width: 88% !important;
    margin-left: auto;
    margin-right: auto;
  
  }
 
  .about-prefer-us {
    margin-top: 15px;
    margin-bottom: -64px;
    padding-top: 6rem;
    position: relative;
    margin-right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-200px, -50px); 
  }
  #aboutus {
    height: 44rem;
    max-width: 100%;
}
}

.team-content{
  padding-top: 1rem;
}


.trainer-profile-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
   width: 456px;
}


.trainer-profile {
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: absolute;
  width: 30%;
  height: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 85px;
  border-radius: 25px;
}

.trainer-profile .profile-picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.trainer-profile .profile-picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #5e068a;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.trainer-profile:hover .profile-picture::before {
  height: 100%;
}

.trainer-profile .profile-picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #5e068a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.trainer-profile .profile-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
  object-fit: cover;
}

.trainer-profile:hover .profile-picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.trainer-profile .profile-title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.trainer-profile .desc {
  padding: 0.5rem 1rem;
  font-size: 12px;
}
.trainer-profile .profile-social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #5e068a;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.trainer-profile:hover .profile-social {
  bottom: 0;
}

.trainer-profile .profile-social li {
  display: inline-block;
}

.trainer-profile .profile-social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.trainer-profile .profile-social li a:hover {
  color: #5e068a;
  background-color: #f7f5ec;
}

.profile-trainer-data{
  width: 30%;
}
