/*******************************/
/* contact us page*/
/*******************************/

#form-group {
  padding-right: 10rem;
}

#contact-us {
  background-image: url(../../Shared/components/Image/images/contact/EyS5YpWXAAAYSWf.png);
  height: 1230px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 95px;
  background-size: cover;
  font-family: "Mulish";
}
@media (max-width: 768px) {
  #contact-us {
    background-image: none;
    background-color: #f9f9f9;
  }
}
input::-webkit-input-placeholder {
  font-size: 13px;
  line-height: 3;
  font-family: "Mulish";
}
textarea::-webkit-input-placeholder {
  font-size: 13px;
  line-height: 3;
  font-family: "Mulish";
}
/************************************/
/* contact-us container */
/* contact-us-heading */
/************************************/
.contact-us-container {
  display: flex;
}
.contact-us-heading {
  font-family: "Kaisei HarunoUmi";
  font-size: 55px;
  color: #5f078a;
  font-weight: 700;
  margin-bottom: 47px;
  padding-left: 10px;
}

.contact-us-circle {
  background: #f3f3f3;
  height: 83px;
  width: 83px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  margin-bottom: 28px;
}

.contact-us-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.contact-us-subheading {
  color: #6d1d8e;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 15px;
  padding-top: 3px;
}

.contact-us-paragraph {
  color: #505050;
  font-family: "Mulish";
  font-weight: 400;
  font-size: 15px;
}

.contact-us-text {
  flex: 1 1;
}

.contact-us-heading-contact-form {
  font-family: "Kaisei HarunoUmi";
  font-size: 55px;
  color: #5f078a;
  font-weight: 700;
  margin-bottom: 47px;
  /* padding-left: 10px; */
}
.contact-us-social-media {
  display: block;
  padding-left: 0%;
  position: relative;
  margin-top: 28px;
}

.social-icons {
  margin-left: -5px;
}
.social-media-icon {
  width: 47px;
}

.contact-us-connect {
  color: #363636;
  font-size: 25px;
  font-family: "Mulish";
  font-weight: 800;
}

.contact-us-submit-button {
  background-color: #fff;
  color: #5f078a;
  padding: 12px 46px;
  display: inline-block;
  border: 2px solid #5f078a;
  border-radius: 30px;
  font-family: "Mulish";
  font-weight: 800;
  font-size: 20px;
}
.contact-us-checkbox {
  background-color: #fff;
  color: #515151;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  width: 193px;
  border: 2px solid #5f078a;
  margin-right: 20px;
}

/* contact us right  */
.contact-us-column-right-heading {
  color: #363636;
  font-family: "Kaisei HarunoUmi";
  font-weight: 700;
  font-size: 30px;
  margin: 0%;
  margin-bottom: 13px;
}
.contact-us-column-right-paragraph {
  color: #515151;
  font-family: "Mulish";
  font-weight: 400;
  font-size: 15px;
  max-width: 520px;
}

.form-control.contact-us-input-field {
  height: 60px;
}

/*******************************************/
/* contact contact-us-container media query section */

@media (max-width: 768px) {
  #contact-us {
    padding-top: 38px;
    height: 100%;
  }
  .contact-us-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact-us-column-right-heading {
    font: 700 20px "Kaisei HarunoUmi";
    padding: 0%;
    margin-bottom: 16px;
  }
  .contact-us-column-right-paragraph {
    font: 400 12px "Mulish";
    color: #515151;
  }
  .form-control.contact-us-input-field {
    height: 47px;
    border: 1px solid #bbbbbb;
    border-radius: 7px;
    padding: 16px 18px;
  }
  textarea.form-control {
    height: 229px;
  }
  .contact-us-heading {
    font: 700 30px "Kaisei HarunoUmi";
    margin: 0%;
    margin-top: 43px;
    margin-bottom: 38px;
  }

  .contact-us-circle {
    width: 69px;
    height: 69px;
  }
  .contact-us-subheading {
    font: 700 15px "Mulish";
  }
  .contact-us-paragraph {
    font: 400 12px "Mulish";
  }
  .contact-us-social-media {
    display: none;
  }
  .form-check-label {
    flex: 1;
  }
  .contact-us-checkbox {
    width: 156px;
    height: 46px;
    font-size: 10px;
  }
  .contact-us-submit-button {
    width: 156px;
    height: 46px;
    font: 800 15px "Mulish";
  }
  .contact-us-button-section {
    display: flex;
  }
}

.contact-us-checkbox-wrapper {
  margin-top: 35px;
  margin-right: 0px;
}
.contact-us-submit-button {
  margin-right: 58px;
}

/*********************************************/
/* courses-why-luminar technolad
------------------------------------- */

#courses-why-luminar {
  background-color: #f9f9f9;
  padding-bottom: 0%;
  padding-top: 0%;
}

.courses-why-luminar {
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 951px; */
}

.why-luminar-image-left.img-fluid {
  height: 900px;
  /* height: 95vh; */
  object-fit: cover;
  /* width: 590px; */
}

.why-luminar-text-section {
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.why-luminar-heading {
  font-size: 55px;
  font-family: "Barlow";
  font-weight: 700;
  color: #6d1d8e;
}

.why-luminar-paragraph {
  font-size: 15px;
  color: #515151;
  text-align: left;
  width: 100%;
  max-width: 550px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.why-luminar-list {
  list-style-type: none;
  font-size: 16px;
  color: #515151;
  font-family: "Mulish";
  font-weight: 700;
  text-align: left;
  line-height: 2;
}
.contact-us-content a {
  display: flex;
  justify-content: start;
  align-items: center;
}
.contact-us-content .modal-content {
  width: auto;
  height: auto;
}
/* ul icons */
ul.why-luminar-list li {
  /* background-image: url(../images/courses.html/Group45.png); */
  background-repeat: no-repeat;
  margin-top: 5px;
  padding-left: 40px;
}
@media (max-width: 576px) {
  .contact-us-heading-contact-form {
    font-size: 30px;
  }
  .small-device-contact-header {
    margin-left: 1rem;
  }
  .col-container-two {
    padding-left: 0;
  }
  .courses-why-luminar-image-section {
    display: none;
    padding: 0%;
  }
  .why-luminar-text-section {
    padding: 0% 15px;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 37rem !important;
    height: 358px !important;
    position: relative;
    top: 50%;
    border-radius: 8px;
  }
  .mapouter {
    position: relative;
    text-align: right;
    width: 0 !important;
    height: 37rem !important;
  }
  .why-luminar-heading {
    font: 700 30px "Kaisei HarunoUmi";
    text-align: left;
    width: 210px;
    padding: 0%;
  }
  .why-luminar-paragraph {
    font: 400 12px "Mulish";
  }

  ul.why-luminar-list li {
    font: 700 12px "Mulish";
    line-height: 2.7;
    margin-top: 0%;
  }

  .contact-us-submit-button {
    width: 110px;
    height: 33px;
    font: 800 15px "Mulish";
  }
  .form-submit {
    padding-right: 0rem !important;
    margin-left: 4rem;
  }
}

.message {
  width: 90%;
  border-radius: 7px;
  height: 175px !important;
}

/* .contact-us-button-section{
  display: flex;
  margin-top: 3rem;
  margin-left: 13rem;
} */
.contact-us-button-section {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 1rem;
  margin-top: 2rem;
}
#user_captcha_input {
  width: 15rem;
  height: 4.5rem;
  outline: none;
  border: 2px solid #ced4da;
  padding: 1.2rem;
  border-radius: 1rem;
  font-size: 14px;
}

.contact-recaptcha {
  margin-left: -6rem;
}
.recaptcha-input {
  margin-top: -69px;
  margin-left: 9rem;
}
#user_captcha_input::placeholder {
  color: #495057;
}
#reload_href {
  padding-left: 6rem;
  position: relative;
  top: 0rem;
  font-size: 12px;
  font-weight: 600;
  user-select: none;
  color: #6d1d8e !important;
}
.error-messge {
  font-size: 14px;
  color: red;
}

.text-danger {
  font-weight: 600 !important;
  color: #dc3545 !important;
  font-size: 12px;
  padding-top: 1rem;
}

.row-container {
  padding-top: 10rem;
  display: flex;
  justify-content: space-between;
}
.col-container-two {
  padding-left: 54rem;
  z-index: 1;
}




.checkbox-group{
  margin-top: 20px;
}

.checkbox-input {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: 5px;
}


@media (max-width: 768px) {
  .checkbox-input {
    width: 40px;
    height: 40px;
  }
  .size-adjust-container{
  margin-top: 0rem!important;
}
}


.checkbox-label {
  font-size: 12px;
  display: inline-block;
  color: #333;
}


.size-adjust-container{
  margin-top: 15rem;
} 